import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  IconButton,
  CircularProgress,
  Alert,
  DialogActions,
  DialogContentText,
  Rating,
  Snackbar
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  InsertDriveFile as FileIcon,
  Comment as CommentIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';
import {
  createAssignment,
  listAssignments,
  deleteAssignment,
  addAssignmentFeedback
} from '../api';

// Styled Dialog component for consistent theming
const StyledDialog = ({ open, onClose, children }) => (
  <Dialog 
    open={open} 
    onClose={onClose} 
    fullWidth 
    maxWidth="lg"
    PaperProps={{
      style: {
        background: 'rgba(40, 40, 40, 1.9)',
        color: 'white',
        minHeight: '80vh'
      }
    }}
  >
    {children}
  </Dialog>
);

// Gradient Button component for consistent styling
const GradientButton = ({ children, ...props }) => (
  <Button
    {...props}
    sx={{
      background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
      color: 'white',
      '&:hover': {
        background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
      },
      '&:disabled': {
        background: 'rgba(255, 255, 255, 0.12)',
        color: 'rgba(255, 255, 255, 0.3)',
      },
      ...props.sx
    }}
  >
    {children}
  </Button>
);

// Feedback Dialog Component
const FeedbackDialog = ({ open, onClose, response, onFeedbackSubmitted }) => {
  const [feedback, setFeedback] = useState('');
  const [score, setScore] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    if (!feedback.trim() && !score) {
      setError('Please provide either feedback or a score');
      return;
    }

    setSubmitting(true);
    setError('');

    try {
      await addAssignmentFeedback(response.id, {
        feedback,
        score: score ? score * 20 : null // Convert 5-star rating to 100-point scale
      });

      onFeedbackSubmitted();
      onClose();
    } catch (err) {
      setError('Failed to submit feedback. Please try again.');
      console.error('Feedback submission error:', err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          background: 'rgba(40, 40, 40, 0.9)',
          color: 'white',
        }
      }}
    >
      <DialogTitle>Provide Feedback</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3, mt: 1 }}>
          <Typography variant="subtitle1" gutterBottom>
            Assignment: {response?.assignment?.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Student: {response?.student?.full_name}
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography component="legend" sx={{ mb: 1 }}>
            Score (Optional)
          </Typography>
          <Rating
            value={score}
            onChange={(event, newValue) => {
              setScore(newValue);
            }}
            size="large"
            precision={0.5}
            sx={{
              '& .MuiRating-iconFilled': {
                color: '#FF930F',
              },
              '& .MuiRating-iconHover': {
                color: '#FF1B6B',
              }
            }}
          />
          {score && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Equivalent to {score * 20}/100 points
            </Typography>
          )}
        </Box>

        <TextField
          fullWidth
          multiline
          rows={4}
          label="Feedback"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          error={!!error}
          helperText={error}
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} sx={{ color: 'white' }}>
          Cancel
        </Button>
        <GradientButton
          onClick={handleSubmit}
          disabled={submitting || (!feedback.trim() && !score)}
        >
          Submit Feedback
        </GradientButton>
      </DialogActions>
    </Dialog>
  );
};

// Assignment Response Component
const AssignmentResponse = ({ response, assignment, onFeedbackSubmitted }) => {
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  return (
    <Box sx={{ mb: 2, p: 2, bgcolor: 'rgba(255,255,255,0.1)', borderRadius: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <div>
          <Typography variant="subtitle1">
            Response to: {assignment.title}
          </Typography>
          <Typography variant="body2" color="rgba(255,255,255,0.7)">
            By: {response.student.full_name}
          </Typography>
          <Typography variant="body2" color="rgba(255,255,255,0.7)">
            Submitted: {dayjs(response.submitted_at).format('MMM D, YYYY HH:mm')}
          </Typography>
        </div>
        <IconButton 
          onClick={() => setFeedbackDialogOpen(true)}
          sx={{ 
            color: response.feedback ? '#4CAF50' : 'white',
            '&:hover': {
              background: 'rgba(255,255,255,0.1)'
            }
          }}
        >
          <CommentIcon />
        </IconButton>
      </Box>

      <Typography variant="body1" sx={{ mt: 1 }}>
        {response.content}
      </Typography>

      {response.files?.map((file, index) => (
        <Chip
          key={index}
          label={`View Response File ${index + 1}`}
          onClick={() => window.open(file.file, '_blank')}
          sx={{ mr: 1, mt: 1 }}
        />
      ))}

      {response.feedback && (
        <Box sx={{ mt: 2, p: 1, bgcolor: 'rgba(76, 175, 80, 0.1)', borderRadius: 1 }}>
          <Typography variant="subtitle2" color="#4CAF50">
            Feedback
          </Typography>
          {response.score && (
            <Typography variant="body2" color="#4CAF50">
              Score: {response.score}/100
            </Typography>
          )}
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            {response.feedback}
          </Typography>
          {response.feedback_by_name && (
            <Typography variant="caption" color="rgba(255,255,255,0.7)">
              By: {response.feedback_by_name}
            </Typography>
          )}
        </Box>
      )}

      <FeedbackDialog 
        response={response}
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        onFeedbackSubmitted={onFeedbackSubmitted}
      />
    </Box>
  );
};

// Main AssignmentManagement Component
const AssignmentManagement = ({ studentCourse, onClose }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [newAssignment, setNewAssignment] = useState({
    title: '',
    description: '',
    files: [],
    expires_in_days: 7
  });
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAssignments = async () => {
    setLoading(true);
    try {
      const params = studentCourse.course.is_group_class 
        ? { course: studentCourse.course.id }
        : { student_course: studentCourse.id };
      
      const response = await listAssignments(params);
      setAssignments(response.data);
    } catch (err) {
      setError('Failed to fetch assignments');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignments();
  }, [studentCourse]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setNewAssignment(prev => ({
      ...prev,
      files: [...prev.files, ...files]
    }));
  };

  const removeFile = (index) => {
    setNewAssignment(prev => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('title', newAssignment.title);
      formData.append('description', newAssignment.description);
      
      const expiryDate = dayjs().add(newAssignment.expires_in_days, 'day').format('YYYY-MM-DD HH:mm:ss');
      formData.append('expires_at', expiryDate);

      if (studentCourse.course.is_group_class) {
        formData.append('course', studentCourse.course.id);
      } else {
        formData.append('student_course', studentCourse.id);
      }

      newAssignment.files.forEach((file, index) => {
        formData.append('uploaded_files', file);
        formData.append('file_types', file.type.split('/')[1]);
      });

      await createAssignment(formData);
      
      setNewAssignment({
        title: '',
        description: '',
        files: [],
        expires_in_days: 7
      });
      
      await fetchAssignments();
      setSuccessMessage('Assignment created successfully!');
      setActiveTab(1); // Switch to View Assignments tab after successful creation
      
    } catch (err) {
      setError('Failed to create assignment');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAssignment = async () => {
    try {
      setLoading(true);
      await deleteAssignment(assignmentToDelete.id);
      await fetchAssignments();
      setDeleteConfirmOpen(false);
      setAssignmentToDelete(null);
    } catch (err) {
      setError('Failed to delete assignment');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={true} onClose={onClose}>
      <DialogTitle>
        Assignment Management - {studentCourse.course.name}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs 
            value={activeTab} 
            onChange={(_, newValue) => setActiveTab(newValue)}
            textColor="inherit"
            sx={{ '& .MuiTab-root': { color: 'white' } }}
          >
            <Tab label="Create Assignment" />
            <Tab label="View Assignments & Responses" />
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                value={newAssignment.title}
                onChange={(e) => setNewAssignment(prev => ({ ...prev, title: e.target.value }))}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                value={newAssignment.description}
                onChange={(e) => setNewAssignment(prev => ({ ...prev, description: e.target.value }))}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="number"
                label="Expires in (days)"
                value={newAssignment.expires_in_days}
                onChange={(e) => setNewAssignment(prev => ({ ...prev, expires_in_days: e.target.value }))}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                multiple
                id="assignment-files"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <label htmlFor="assignment-files">
                <GradientButton
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Files
                </GradientButton>
              </label>
              <List>
                {newAssignment.files.map((file, index) => (
                  <ListItem key={index}>
                    <FileIcon sx={{ mr: 1 }} />
                    <ListItemText primary={file.name} />
                    <IconButton onClick={() => removeFile(index)} sx={{ color: 'white' }}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12}>
              <GradientButton
                onClick={handleSubmit}
                disabled={loading || !newAssignment.title || !newAssignment.description}
              >
                {loading ? <CircularProgress size={24} /> : 'Create Assignment'}
              </GradientButton>
            </Grid>
          </Grid>
          )}

          {activeTab === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>Assignments</Typography>
                {loading ? (
                  <CircularProgress />
                ) : assignments.length > 0 ? (
                  <List>
                    {assignments.map((assignment) => (
                      <ListItem key={assignment.id}>
                        <ListItemText
                          primary={assignment.title}
                          secondary={
                            <>
                              <Typography variant="body2" color="rgba(255,255,255,0.7)">
                                Due: {dayjs(assignment.expires_at).format('MMM D, YYYY')}
                              </Typography>
                              {assignment.files?.map((file, index) => (
                                <Chip
                                  key={index}
                                  label={`File ${index + 1}`}
                                  onClick={() => window.open(file.file, '_blank')}
                                  size="small"
                                  sx={{ mr: 1, mt: 1 }}
                                />
                              ))}
                            </>
                          }
                        />
                        <Chip 
                          label={assignment.status} 
                          color={assignment.status === 'ACTIVE' ? 'success' : 'error'}
                          sx={{ mr: 1 }}
                        />
                        <IconButton 
                          onClick={() => {
                            setAssignmentToDelete(assignment);
                            setDeleteConfirmOpen(true);
                          }}
                          sx={{ color: '#ff4444' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography>No assignments found</Typography>
                )}
  
                {/* Delete Confirmation Dialog */}
                <Dialog
                  open={deleteConfirmOpen}
                  onClose={() => setDeleteConfirmOpen(false)}
                  PaperProps={{
                    style: {
                      background: 'rgba(255, 255, 255, 0.1)',
                      backdropFilter: 'blur(10px)',
                      color: 'white',
                    }
                  }}
                >
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <DialogContentText sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Are you sure you want to delete the assignment "{assignmentToDelete?.title}"? 
                      This action cannot be undone.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button 
                      onClick={() => setDeleteConfirmOpen(false)}
                      sx={{ color: 'white' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleDeleteAssignment}
                      sx={{ 
                        color: '#ff4444',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 68, 68, 0.1)'
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
  
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>Responses</Typography>
                {assignments.map(assignment => (
                  assignment.responses?.map(response => (
                    <AssignmentResponse
                      key={response.id}
                      response={response}
                      assignment={assignment}
                      onFeedbackSubmitted={async () => {
                        await fetchAssignments();
                      }}
                    />
                  ))
                ))}
                {!assignments.some(a => a.responses?.length > 0) && (
                  <Typography>No responses yet</Typography>
                )}
              </Grid>
            </Grid>
          )}
  
          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mt: 2,
                bgcolor: 'rgba(211, 47, 47, 0.1)',
                color: '#ff4444',
                '& .MuiAlert-icon': {
                  color: '#ff4444'
                }
              }}
              onClose={() => setError(null)}
            >
              {error}
            </Alert>
          )}
        </DialogContent>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSuccessMessage('')}
          severity="success"
          sx={{ 
            bgcolor: 'rgba(76, 175, 80, 0.1)',
            color: '#4CAF50',
            '& .MuiAlert-icon': {
              color: '#4CAF50'
            }
          }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mt: 2,
            bgcolor: 'rgba(211, 47, 47, 0.1)',
            color: '#ff4444',
            '& .MuiAlert-icon': {
              color: '#ff4444'
            }
          }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}
    </StyledDialog>
  );
};

export default AssignmentManagement;