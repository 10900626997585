import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Alert, Chip, Paper } from '@mui/material';

const LoadingMessages = ({ error, status, vocabularyUsed }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  
  const messages = [
    "Analyzing your story...",
    "Getting your learnt vocabulary...",
    "Analyzing Plot Suggestions...",
    "Preparing recommendations...",
    "Finalizing...."
  ];

  useEffect(() => {
    if (!error && status !== 'FAILED') {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prev) => 
          prev < messages.length - 1 ? prev + 1 : prev
        );
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [error, status]);

  const renderVocabularyUsed = () => {
    if (!vocabularyUsed?.length) return null;

    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" sx={{ mb: 2, color: '#ffffff' }}>
          Vocabulary Used in Your Story
        </Typography>
        <Paper 
          sx={{ 
            p: 2, 
            backgroundColor: '#2d2d2d',
            border: '1px solid #404040'
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {vocabularyUsed.map((word, index) => (
              <Chip
                key={index}
                label={word}
                sx={{
                  bgcolor: '#0ea5e9',
                  color: '#ffffff',
                  '&:hover': {
                    bgcolor: '#0284c7',
                  }
                }}
              />
            ))}
          </Box>
        </Paper>
      </Box>
    );
  };

  if (status === 'FAILED' || error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert 
          severity="info" 
          sx={{ 
            backgroundColor: 'rgba(14, 165, 233, 0.1)',
            color: '#ffffff',
            '& .MuiAlert-icon': {
              color: '#0ea5e9'
            },
            border: '1px solid rgba(14, 165, 233, 0.2)'
          }}
        >
          The server seems to be busy, try again. Don't worry nothing is wrong with your story, we are just experiencing high demand.
        </Alert>
        {renderVocabularyUsed()}
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        p: 3,
        gap: 3
      }}
    >
      <CircularProgress sx={{ color: '#0ea5e9' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
        {messages.map((message, index) => (
          <Typography
            key={message}
            sx={{
              transition: 'all 0.5s ease',
              opacity: index <= currentMessageIndex ? 1 : 0,
              transform: index <= currentMessageIndex ? 'translateY(0)' : 'translateY(8px)',
              color: index === currentMessageIndex ? '#0ea5e9' : '#999999',
              fontWeight: index === currentMessageIndex ? 500 : 400,
            }}
          >
            {message}
          </Typography>
        ))}
      </Box>
      {renderVocabularyUsed()}
    </Box>
  );
};

export default LoadingMessages;