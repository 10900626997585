import React from 'react';
import { styled, keyframes } from '@mui/material/styles';
import { BottomNavigationAction, Box } from '@mui/material';
import { People as PeopleIcon } from '@mui/icons-material';

// Define keyframes animations
const slideLabel = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

const glow = keyframes`
  0%, 100% {
    filter: drop-shadow(0 0 5px #ff3366) drop-shadow(0 0 8px #ff3366);
  }
  50% {
    filter: drop-shadow(0 0 10px #ff3366) drop-shadow(0 0 15px #ff3366);
  }
`;

// Create styled components
const AnimatedLabel = styled('span')`
  position: absolute;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(45deg, #ff3366, #ff6b6b);
  padding: 4px 12px;
  border-radius: 12px;
  right: 0;
  animation: ${slideLabel} 0.5s ease-out forwards;
  white-space: nowrap;
  box-shadow: 0 2px 8px rgba(255, 51, 102, 0.5);
  z-index: 1;
`;

const IconWrapper = styled(Box)`
  animation: ${bounce} 2s ease-in-out infinite, ${glow} 2s ease-in-out infinite;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  position: relative;
  min-width: 80px;
  padding: 0;
  overflow: visible !important;

  .MuiBottomNavigationAction-label {
    opacity: 0;
  }

  &.Mui-selected {
    ${IconWrapper} {
      transform: scale(1.2);
    }
  }

  &:hover ${IconWrapper} {
    transform: scale(1.1);
  }

  svg {
    font-size: 28px;
    color: #ff3366;
    transition: transform 0.3s ease;
  }
`;

const CustomLabel = ({ show, children }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setIsVisible(true), 100);
      return () => clearTimeout(timer);
    }
    setIsVisible(false);
  }, [show]);

  if (!isVisible) return null;

  return <AnimatedLabel>{children}</AnimatedLabel>;
};

const EnhancedSocialNavigation = ({ value, onChange }) => {
  const [showLabel, setShowLabel] = React.useState(false);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onMouseEnter={() => setShowLabel(true)}
      onMouseLeave={() => setShowLabel(false)}
    >
      <StyledBottomNavigationAction
        value={4}
        selected={value === 4}
        onClick={(event) => onChange(event, 4)}
        icon={
          <IconWrapper>
            <PeopleIcon />
          </IconWrapper>
        }
      />
      <CustomLabel show={showLabel || value === 4}>Social</CustomLabel>
    </Box>
  );
};

export default EnhancedSocialNavigation;