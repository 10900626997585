import React, { useState, useEffect, useRef } from 'react';
import { Card, Typography, Box, IconButton, Button, TextField, 
  Dialog, DialogContent, FormControl, InputLabel, Select, 
  MenuItem, CircularProgress, Chip, LinearProgress, Alert,
  DialogTitle, DialogActions, Fab } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VocabReferenceDrawer from '../components/VocabReferenceDrawer';
import ShareStoryDialog from '../components/ShareStoryDialog';
import TutorialOverlay from '../components/MobileSocialPageTutorial';
import ChunkedUploader from '../components/ChunkedUploader';
import {
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Star as StarIcon,
  Comment as CommentIcon,
  Reply as ReplyIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import MobileStoryCard from '../components/MobileStoryCard';
import EnhancedShareStoryDialog from '../components/EnhancedShareStoryDialog';
import MobileStorySuggestions from '../components/MobileStorySuggestions';
import CollapsibleTabs from '../components/CollapsibleTabs';

import {
  getCurrentUser,
  listDebateTopics,
  createDebateTopic,
  updateDebateTopic,
  deleteDebateTopic,
  listVideoResponses,
  createVideoResponse,
  updateVideoResponse,
  deleteVideoResponse,
  listStories,
  createStory,
  updateStory,
  deleteStory,
  addReaction,
  createComment,
  updateComment1,
  deleteComment1,
  replyToComment,
  removeReaction,
} from '../api';

dayjs.extend(relativeTime);

const MobileSocialPage = () => {
    // State Management
    const [debates, setDebates] = useState([]);
    const [videos, setVideos] = useState([]);
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [stance, setStance] = useState('');
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [processingVideo, setProcessingVideo] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [videoPreview, setVideoPreview] = useState(null);
    const [newStoryContent, setNewStoryContent] = useState('');
    const [storyTitle, setStoryTitle] = useState('');
    const [error, setError] = useState(null);
    const [selectedItemToDelete, setSelectedItemToDelete] = useState(null);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const fileInputRef = useRef(null);
    const [activeTab, setActiveTab] = useState('debates');
    const [isVocabDrawerOpen, setIsVocabDrawerOpen] = useState(false);
    const [storyEditorOpen, setStoryEditorOpen] = useState(false);
  const [editingStory, setEditingStory] = useState(null);
  const [showTutorial, setShowTutorial] = useState(true);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
const [selectedDebateForUpload, setSelectedDebateForUpload] = useState(null);
  



  // Permission checks
  const canCreateDebate = currentUser && ['ADMIN', 'MANAGER', 'TRAINER'].includes(currentUser.role);
  const canCreateVideo = currentUser && debates.some(d => {
    const now = new Date();
    const startDate = new Date(d.start_date);
    const endDate = new Date(d.end_date);
    return d.is_active && startDate <= now && now <= endDate;
  });
  const canCreateStory = currentUser;

  // Initial Load
  useEffect(() => {
    const init = async () => {
      try {
        const userResponse = await getCurrentUser();
        setCurrentUser(userResponse.data);
        await loadContent();
      } catch (error) {
        console.error('Error initializing:', error);
        setError('Error initializing page');
      }
    };
    init();
  }, []);

  // Content Loading
  const loadContent = async () => {
    setLoading(true);
    try {
      const [debatesRes, videosRes, storiesRes] = await Promise.all([
        listDebateTopics({ ordering: '-created_at' }),
        listVideoResponses({ ordering: '-created_at' }),
        listStories({ ordering: '-created_at' })
      ]);
      
      setDebates(debatesRes.data);
      setVideos(videosRes.data);
      setStories(storiesRes.data);
    } catch (error) {
      console.error('Error loading content:', error);
      setError('Error loading content');
    } finally {
      setLoading(false);
    }
  };

  // Get active debate
  const getActiveDebate = () => {
    const now = new Date();
    return debates.find(debate => 
      debate.is_active && 
      new Date(debate.start_date) <= now && 
      now <= new Date(debate.end_date)
    );
  };

  // Video handlers
  const handleVideoSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedVideo(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const handleOpenUploadDialog = () => {
    const activeDebate = getActiveDebate();
    if (activeDebate) {
      setSelectedDebateForUpload(activeDebate);
      setUploadDialogOpen(true);
    } else {
      alert('No active debate found');
    }
  };

  // In handleVideoUpload
  const handleVideoUpload = async () => {
    if (!selectedVideo || !stance) {
      alert('Please select a video and choose your stance');
      return;
    }
  
    const activeDebate = getActiveDebate();
    if (!activeDebate) {
      alert('No active debate found');
      return;
    }
  
    setProcessingVideo(true);
    setUploadProgress(0);
  
    try {
      const uploader = new ChunkedUploader(selectedVideo);
      
      uploader.onProgress((progress) => {
        const formattedProgress = Math.min(parseFloat(progress.toFixed(2)), 99.99);
        setUploadProgress(formattedProgress);
      });
  
      // Start upload with metadata
      const response = await uploader.start({
        debate_topic: activeDebate.id,
        stance: stance,
        title: `${currentUser.first_name} ${currentUser.last_name}`
      });
  
      // Show processing state
      setUploadProgress(100);
      setProcessingVideo(true);
  
      // Reload the video list
      try {
        const videosRes = await listVideoResponses({ ordering: '-created_at' });
        setVideos(videosRes.data); // Update with fresh data
      } catch (error) {
        console.error('Error refreshing video list:', error);
      }
  
      // Reset form
      setSelectedVideo(null);
      setVideoPreview(null);
      setStance('');
      
      // Close dialog after delay
      setTimeout(() => {
        setProcessingVideo(false);
        setUploadProgress(0);
        setUploadDialogOpen(false);
      }, 1500);
  
    } catch (error) {
      console.error('Upload error:', error);
      alert(error.message || 'Error uploading video');
      setProcessingVideo(false);
      setUploadProgress(0);
    }
  };


  const handleUpdateDebate = async (debate) => {
    try {
      const response = await updateDebateTopic(debate.id, debate);
      setDebates(prevDebates => 
        prevDebates.map(d => d.id === debate.id ? response.data : d)
      );
      alert('Debate updated successfully');
    } catch (error) {
      alert('Error updating debate');
      console.error(error);
    }
  };

  const handleDeleteDebate = async (id) => {
    try {
      await deleteDebateTopic(id);
      setDebates(prevDebates => prevDebates.filter(d => d.id !== id));
      alert('Debate deleted successfully');
    } catch (error) {
      alert('Error deleting debate');
      console.error(error);
    }
  };

  const handleDeleteVideo = async (id) => {
    try {
      await deleteVideoResponse(id);
      setVideos(prevVideos => prevVideos.filter(v => v.id !== id));
      alert('Video deleted successfully');
    } catch (error) {
      alert('Error deleting video');
      console.error(error);
    }
  };

  const handleUpdateComment = async (commentId, content) => {
    try {
      const response = await updateComment1(commentId, { content });
      setVideos(prevVideos => prevVideos.map(video => ({
        ...video,
        comments: updateCommentsInItem(video.comments, commentId, response.data)
      })));
      setStories(prevStories => prevStories.map(story => ({
        ...story,
        comments: updateCommentsInItem(story.comments, commentId, response.data)
      })));
      return response.data;
    } catch (error) {
      alert('Error updating comment');
      throw error;
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteComment1(commentId);
      setVideos(prevVideos => prevVideos.map(video => ({
        ...video,
        comments: removeCommentFromItem(video.comments, commentId)
      })));
      setStories(prevStories => prevStories.map(story => ({
        ...story,
        comments: removeCommentFromItem(story.comments, commentId)
      })));
    } catch (error) {
      alert('Error deleting comment');
      throw error;
    }
  };

  const handleReplyToComment = async (commentId, content) => {
    try {
      const response = await replyToComment(commentId, content);
      setVideos(prevVideos => prevVideos.map(video => ({
        ...video,
        comments: addReplyToComment(video.comments, commentId, response.data)
      })));
      setStories(prevStories => prevStories.map(story => ({
        ...story,
        comments: addReplyToComment(story.comments, commentId, response.data)
      })));
      return response.data;
    } catch (error) {
      alert('Error adding reply');
      throw error;
    }
  };

  const handleCreateStory = async (storyData) => {
    try {
      const response = await createStory({
        content: storyData.content,
        title: storyData.title,
        status: storyData.status,
        deadline: dayjs().add(7, 'days').toISOString()
      });

      setStories(prevStories => [response.data, ...prevStories]);
      setStoryEditorOpen(false);
    } catch (error) {
      console.error('Error creating story:', error);
      throw error;
    }
  };

  const handleUpdateStory = async (storyData) => {
    try {
      const response = await updateStory(editingStory.id, {
        ...editingStory,
        content: storyData.content,
        title: storyData.title,
        status: storyData.status
      });

      setStories(prevStories =>
        prevStories.map(s => s.id === editingStory.id ? response.data : s)
      );
      
      setEditingStory(null);
      setStoryEditorOpen(false);
    } catch (error) {
      console.error('Error updating story:', error);
      throw error;
    }
  };

  const handleDeleteStory = async (id) => {
    if (window.confirm('Are you sure you want to delete this story?')) {
      try {
        await deleteStory(id);
        setStories(prevStories => prevStories.filter(s => s.id !== id));
      } catch (error) {
        console.error('Error deleting story:', error);
      }
    }
  };

  const handleCompleteStory = async (story) => {
    try {
      const response = await updateStory(story.id, {
        ...story,
        status: 'COMPLETE'
      });
      setStories(prevStories =>
        prevStories.map(s => s.id === story.id ? response.data : s)
      );
    } catch (error) {
      console.error('Error completing story:', error);
    }
  };

  // Helper functions for comment operations
  const updateCommentsInItem = (comments, commentId, updatedComment) => {
    return comments?.map(comment => {
      if (comment.id === commentId) {
        return { ...comment, ...updatedComment };
      }
      if (comment.replies) {
        return {
          ...comment,
          replies: comment.replies.map(reply =>
            reply.id === commentId ? { ...reply, ...updatedComment } : reply
          )
        };
      }
      return comment;
    });
  };

  const removeCommentFromItem = (comments, commentId) => {
    return comments?.filter(comment => {
      if (comment.id === commentId) return false;
      if (comment.replies) {
        comment.replies = comment.replies.filter(reply => reply.id !== commentId);
      }
      return true;
    });
  };

  const addReplyToComment = (comments, commentId, newReply) => {
    return comments?.map(comment => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [...(comment.replies || []), newReply]
        };
      }
      return comment;
    });
  };

  // Reaction handlers
  const handleAddReaction = async (type, itemId, reactionType) => {
    try {
      await addReaction(type, itemId, reactionType);
      if (type === 'video') {
        setVideos(prevVideos => prevVideos.map(video => 
          video.id === itemId 
            ? {
                ...video,
                user_reaction: reactionType,
                reactions_count: {
                  ...video.reactions_count,
                  [`${reactionType.toLowerCase()}s`]: (video.reactions_count?.[`${reactionType.toLowerCase()}s`] || 0) + 1
                }
              }
            : video
        ));
      } else if (type === 'story') {
        setStories(prevStories => prevStories.map(story =>
          story.id === itemId
            ? {
                ...story,
                user_reaction: reactionType,
                reactions_count: {
                  ...story.reactions_count,
                  [`${reactionType.toLowerCase()}s`]: (story.reactions_count?.[`${reactionType.toLowerCase()}s`] || 0) + 1
                }
              }
            : story
        ));
      }
    } catch (error) {
      alert('Error adding reaction');
    }
  };

  const handleRemoveReaction = async (itemId) => {
    try {
      await removeReaction(itemId);
      setVideos(prevVideos => prevVideos.map(video =>
        video.id === itemId
          ? {
              ...video,
              user_reaction: null,
              reactions_count: {
                ...video.reactions_count,
                [`${video.user_reaction?.toLowerCase()}s`]: Math.max(
                  0,
                  (video.reactions_count?.[`${video.user_reaction?.toLowerCase()}s`] || 1) - 1
                )
              }
            }
          : video
      ));
      setStories(prevStories => prevStories.map(story =>
        story.id === itemId
          ? {
              ...story,
              user_reaction: null,
              reactions_count: {
                ...story.reactions_count,
                [`${story.user_reaction?.toLowerCase()}s`]: Math.max(
                  0,
                  (story.reactions_count?.[`${story.user_reaction?.toLowerCase()}s`] || 1) - 1
                )
              }
            }
          : story
      ));
    } catch (error) {
      alert('Error removing reaction');
    }
  };

  // Comment handlers
  const handleCreateComment = async (type, itemId, content) => {
    try {
      const response = await createComment(type, itemId, content);
      const newComment = response.data;
      
      if (type === 'video') {
        setVideos(prevVideos => prevVideos.map(video =>
          video.id === itemId
            ? {
                ...video,
                comments: [...(video.comments || []), newComment]
              }
            : video
        ));
      } else if (type === 'story') {
        setStories(prevStories => prevStories.map(story =>
          story.id === itemId
            ? {
                ...story,
                comments: [...(story.comments || []), newComment]
              }
            : story
        ));
      }
      return newComment;
    } catch (error) {
      alert('Error creating comment');
      throw error;
    }
  };

  // UI Components with enhanced mobile styling
  const CommentItem = ({ comment, onDelete, onUpdate, onReply, currentUser, level = 0 }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState(comment.content);
    const [showReplyField, setShowReplyField] = useState(false);
    const [replyContent, setReplyContent] = useState('');
    const [showReplies, setShowReplies] = useState(false);
  
    const handleUpdate = async () => {
      try {
        await onUpdate(comment.id, editedContent);
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating comment:', error);
      }
    };
  
    const handleReply = async () => {
      if (replyContent.trim()) {
        try {
          await onReply(comment.id, replyContent);
          setReplyContent('');
          setShowReplyField(false);
        } catch (error) {
          console.error('Error replying to comment:', error);
        }
      }
    };
  
    const canModify = currentUser?.role === 'ADMIN' || currentUser?.id === comment.user;
  
    return (
      <Box sx={{ pl: level * 2, mb: 2, borderLeft: level > 0 ? '2px solid rgba(255, 255, 255, 0.1)' : 'none' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
            {comment.user_name}
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: '0.75rem' }}>
            {dayjs(comment.created_at).fromNow()}
            {comment.is_edited && ' (edited)'}
          </Typography>
        </Box>
  
        {isEditing ? (
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              size="small"
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              multiline
              rows={2}
              sx={{
                mb: 1,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                  },
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.1)',
                  }
                },
                '& .MuiOutlinedInput-input': {
                  color: 'white',
                }
              }}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button size="small" onClick={handleUpdate} variant="contained">Save</Button>
              <Button size="small" onClick={() => setIsEditing(false)}>Cancel</Button>
            </Box>
          </Box>
        ) : (
          <Typography variant="body2" sx={{ mb: 1.5, fontSize: '0.875rem', lineHeight: 1.5, color: 'rgba(255, 255, 255, 0.9)' }}>
            {comment.content}
          </Typography>
        )}
  
        <Box sx={{ display: 'flex', gap: 2, mb: 1.5, flexWrap: 'wrap' }}>
          <Button
            size="small"
            startIcon={<ReplyIcon />}
            onClick={() => setShowReplyField(!showReplyField)}
            sx={{
              color: 'white',
              fontSize: '0.75rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            Reply
          </Button>
          {canModify && (
            <>
              <Button
                size="small"
                startIcon={<EditIcon />}
                onClick={() => setIsEditing(true)}
                sx={{
                  color: 'white',
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                Edit
              </Button>
              <Button
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => onDelete(comment.id)}
                sx={{
                  color: '#ef5350',
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(239, 83, 80, 0.1)'
                  }
                }}
              >
                Delete
              </Button>
            </>
          )}
        </Box>
  
        {showReplyField && (
          <Box sx={{ ml: 2, mb: 2, p: 2, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Write a reply..."
              value={replyContent}
              onChange={(e) => setReplyContent(e.target.value)}
              multiline
              rows={2}
              sx={{
                mb: 1,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                  },
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.1)',
                  }
                },
                '& .MuiOutlinedInput-input': {
                  color: 'white',
                }
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button size="small" onClick={handleReply} variant="contained">
                Submit Reply
              </Button>
            </Box>
          </Box>
        )}
  
        {comment.replies?.length > 0 && (
          <>
            <Button
              size="small"
              onClick={() => setShowReplies(!showReplies)}
              startIcon={showReplies ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              sx={{
                color: 'white',
                fontSize: '0.75rem',
                textTransform: 'none',
                mb: 1,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              {showReplies ? 'Hide' : 'Show'} {comment.replies.length} {comment.replies.length === 1 ? 'Reply' : 'Replies'}
            </Button>
  
            {showReplies && comment.replies.map((reply) => (
              <CommentItem
                key={reply.id}
                comment={reply}
                onDelete={onDelete}
                onUpdate={onUpdate}
                onReply={onReply}
                currentUser={currentUser}
                level={level + 1}
              />
            ))}
          </>
        )}
      </Box>
    );
  };

  const SocialInteractions = ({
    type,
    item,
    showComments,
    onToggleComments,
    onReact,
    onRemoveReaction,
    onComment,
    onUpdateComment,
    onDeleteComment,
    onReply,
    currentUser,
    visibleComments,
    showAllComments,
    setShowAllComments,
    hasMoreComments
  }) => {
    const [commentText, setCommentText] = useState('');
    
    const handleSubmitComment = async (e) => {
      e.preventDefault();
      if (commentText.trim()) {
        try {
          await onComment(type, item.id, { content: commentText });
          setCommentText('');
        } catch (error) {
          console.error('Error submitting comment:', error);
        }
      }
    };
  
    return (
      <Box>
        {/* Reactions */}
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="small"
              onClick={() => item.user_reaction === 'UPVOTE' ? 
                onRemoveReaction(item.id) : 
                onReact(type, item.id, 'UPVOTE')}
              sx={{
                color: item.user_reaction === 'UPVOTE' ? '#4caf50' : 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              <ThumbUpIcon fontSize="small" />
            </IconButton>
            <Typography sx={{ ml: 1, fontSize: '0.875rem', color: 'white' }}>
              {item.reactions_count?.upvotes || 0}
            </Typography>
          </Box>
  
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="small"
              onClick={() => item.user_reaction === 'DOWNVOTE' ? 
                onRemoveReaction(item.id) : 
                onReact(type, item.id, 'DOWNVOTE')}
              sx={{
                color: item.user_reaction === 'DOWNVOTE' ? '#ef5350' : 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              <ThumbDownIcon fontSize="small" />
            </IconButton>
            <Typography sx={{ ml: 1, fontSize: '0.875rem', color: 'white' }}>
              {item.reactions_count?.downvotes || 0}
            </Typography>
          </Box>
  
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="small"
              onClick={() => item.user_reaction === 'SUPERLIKE' ? 
                onRemoveReaction(item.id) : 
                onReact(type, item.id, 'SUPERLIKE')}
              sx={{
                color: item.user_reaction === 'SUPERLIKE' ? '#ffd700' : 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              <StarIcon fontSize="small" />
            </IconButton>
            <Typography sx={{ ml: 1, fontSize: '0.875rem', color: 'white' }}>
              {item.reactions_count?.superlikes || 0}
            </Typography>
          </Box>
  
          <Button
            startIcon={<CommentIcon />}
            onClick={onToggleComments}
            sx={{
              color: 'white',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            {item.comments?.length || 0} Comments
          </Button>
        </Box>
  
        {/* Comments Section */}
        {showComments && (
          <Box>
            <TextField
              fullWidth
              size="small"
              placeholder="Add a comment..."
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSubmitComment(e);
                }
              }}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                  },
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.1)',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  color: 'white',
                }
              }}
            />
  
            {visibleComments?.map((comment) => (
              <CommentItem
                key={comment.id}
                comment={comment}
                onDelete={onDeleteComment}
                onUpdate={onUpdateComment}
                onReply={onReply}
                currentUser={currentUser}
              />
            ))}
  
            {hasMoreComments && (
              <Button
                fullWidth
                onClick={() => setShowAllComments(!showAllComments)}
                sx={{
                  mt: 2,
                  color: 'white',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                {showAllComments ? 'Show Less' : 'Show More Comments'}
              </Button>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const DebateCard = ({ debate }) => {
    const now = new Date();
    const isActive = debate.is_active && 
                    new Date(debate.start_date) <= now && 
                    now <= new Date(debate.end_date);

    return (
      <Card sx={{
        mb: 2,
        borderRadius: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        overflow: 'visible'
      }}>
        <Box sx={{ p: 2 }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 2
          }}>
            <Typography variant="h6" sx={{
              fontSize: '1.125rem',
              fontWeight: 600,
              color: 'white'
            }}>
              {debate.title}
            </Typography>
            {canCreateDebate && (
              <Box sx={{ display: 'flex', gap: 0.5 }}>
                <IconButton 
                  size="small" 
                  onClick={() => handleUpdateDebate(debate)}
                  sx={{
                    color: 'white',
                    p: 0.5,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={() => handleDeleteDebate(debate.id)}
                  sx={{
                    color: '#ef5350',
                    p: 0.5,
                    '&:hover': {
                      backgroundColor: 'rgba(239, 83, 80, 0.1)'
                    }
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
          
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 1,
            mb: 2
          }}>
            <Typography variant="caption" sx={{
              fontSize: '0.75rem',
              color: 'rgba(255, 255, 255, 0.7)'
            }}>
              By {debate.created_by_name}
            </Typography>
            <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.3)' }}>•</Typography>
            <Typography variant="caption" sx={{
              fontSize: '0.75rem',
              color: 'rgba(255, 255, 255, 0.7)'
            }}>
              {dayjs(debate.created_at).fromNow()}
            </Typography>
            <Chip 
              label={isActive ? "Active" : "Ended"}
              size="small"
              color={isActive ? "success" : "error"}
              sx={{ ml: 'auto' }}
            />
          </Box>

          <Typography 
            variant="body2"
            sx={{
              mb: 2,
              fontSize: '0.875rem',
              lineHeight: 1.6,
              color: 'rgba(255, 255, 255, 0.9)'
            }}
            dangerouslySetInnerHTML={{ __html: debate.description }}
          />

          <Typography variant="caption" sx={{
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: '0.75rem'
          }}>
            Duration: {dayjs(debate.start_date).format('MMM D')} - {dayjs(debate.end_date).format('MMM D, YYYY')}
          </Typography>
        </Box>
      </Card>
    );
  };

  const VideoResponseCard = ({ video }) => {
    const [showAllComments, setShowAllComments] = useState(false);
    const [commentText, setCommentText] = useState('');
    const videoUrl = video.original_video_url || `https://dj.nilconnect.in${video.video_url}`;
    
    // Always show first 2 comments, show all when expanded
    const visibleComments = showAllComments ? video.comments : video.comments?.slice(0, 2);
    const hasMoreComments = (video.comments?.length || 0) > 2;
  
    const handleSubmitComment = async () => {
      if (commentText.trim()) {
        try {
          await handleCreateComment('video', video.id, { content: commentText });
          setCommentText('');
        } catch (error) {
          console.error('Error creating comment:', error);
        }
      }
    };
  
    return (
      <Card sx={{
        mb: 2,
        borderRadius: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        overflow: 'visible'
      }}>
        <Box sx={{ p: 2 }}>
          {/* Header Content */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 2
          }}>
            <Typography variant="h6" sx={{
              fontSize: '1.125rem',
              fontWeight: 600,
              color: 'white'
            }}>
              {video.title}
            </Typography>
            {(currentUser?.role === 'ADMIN' || currentUser?.id === video.user) && (
              <IconButton 
                size="small" 
                onClick={() => handleDeleteVideo(video.id)}
                sx={{ color: '#ef5350' }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
  
          {/* User Info and Status */}
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 1,
            mb: 2
          }}>
            <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              By {video.user_name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Chip 
                label={video.stance}
                size="small"
                color={video.stance === 'FOR' ? "success" : "error"}
                sx={{ height: '24px' }}
              />
              <Chip 
                label={video.processing_status}
                size="small"
                color={video.processing_status === 'COMPLETED' ? "success" : "warning"}
                sx={{ height: '24px' }}
              />
            </Box>
          </Box>
  
          {/* Video Player */}
          <Box sx={{
            width: '106%',
            maxHeight: '70vh',
            marginLeft: '-3%',
            
            mb: 2,
            borderRadius: 1,
            overflow: 'hidden',
          }}>
            <video
              controls
              src={videoUrl}
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '80vh',
                backgroundColor: '#000'
              }}
              playsInline
            />
          </Box>
  
          {/* Like/Dislike Buttons */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 2,
            mb: 2,
            pl: 1
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                size="small"
                onClick={() => video.user_reaction === 'UPVOTE' ? 
                  handleRemoveReaction(video.id) : 
                  handleAddReaction('video', video.id, 'UPVOTE')}
                sx={{
                  color: video.user_reaction === 'UPVOTE' ? '#4caf50' : 'white',
                }}
              >
                <ThumbUpIcon fontSize="small" />
              </IconButton>
              <Typography sx={{ ml: 0.5, color: 'white' }}>
                {video.reactions_count?.upvotes || 0}
              </Typography>
            </Box>
  
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                size="small"
                onClick={() => video.user_reaction === 'DOWNVOTE' ? 
                  handleRemoveReaction(video.id) : 
                  handleAddReaction('video', video.id, 'DOWNVOTE')}
                sx={{
                  color: video.user_reaction === 'DOWNVOTE' ? '#ef5350' : 'white',
                }}
              >
                <ThumbDownIcon fontSize="small" />
              </IconButton>
              <Typography sx={{ ml: 0.5, color: 'white' }}>
                {video.reactions_count?.downvotes || 0}
              </Typography>
            </Box>
          </Box>
  
          {/* Comments Section */}
          <Box sx={{ mt: 2, borderTop: '1px solid rgba(255, 255, 255, 0.1)', pt: 2 }}>
            {/* Comment Count */}
            <Typography variant="subtitle2" sx={{ color: 'white', mb: 2 }}>
              {video.comments?.length || 0} Comments
            </Typography>
  
            {/* Comment Box */}
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                multiline
                rows={2}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                placeholder="Write a comment..."
                sx={{
                  mb: 1,
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    },
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.1)',
                    }
                  },
                  '& .MuiOutlinedInput-input': {
                    color: 'white',
                  }
                }}
              />
              <Button
                variant="contained"
                onClick={handleSubmitComment}
                disabled={!commentText.trim()}
                sx={{
                  backgroundColor: '#4A90E2',
                  '&:hover': {
                    backgroundColor: '#357abd'
                  }
                }}
              >
                Comment
              </Button>
            </Box>
  
            {/* Comments List - Always showing first 2 */}
            <Box sx={{ mt: 2 }}>
              {visibleComments?.map((comment) => (
                <CommentItem
                  key={comment.id}
                  comment={comment}
                  onDelete={handleDeleteComment}
                  onUpdate={handleUpdateComment}
                  onReply={handleReplyToComment}
                  currentUser={currentUser}
                />
              ))}
  
              {/* Show More Comments Button */}
              {hasMoreComments && (
                <Button
                  fullWidth
                  onClick={() => setShowAllComments(!showAllComments)}
                  startIcon={showAllComments ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  sx={{
                    mt: 2,
                    color: 'white',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  {showAllComments ? 'Show Less' : `Show ${video.comments.length - 2} More Comments`}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    );
  };

const StoryCard = ({ story }) => {
  const [showComments, setShowComments] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(story.content);
  const [editTitle, setEditTitle] = useState(story.title);

  const handleSaveEdit = async () => {
    try {
      await handleUpdateStory({
        ...story,
        content: editContent,
        title: editTitle
      });
      setIsEditing(false);
    } catch (error) {
      alert('Error updating story');
    }
  };

  return (
    <Card sx={{
      mb: 2,
      borderRadius: 2,
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      overflow: 'visible'
    }}>
      <Box sx={{ p: 2 }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 2
        }}>
          {isEditing ? (
            <TextField
              fullWidth
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              size="small"
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  color: 'white',
                  fontSize: '1.125rem',
                  fontWeight: 600
                }
              }}
            />
          ) : (
            <Typography variant="h6" sx={{
              fontSize: '1.125rem',
              fontWeight: 600,
              color: 'white'
            }}>
              {story.title}
            </Typography>
          )}
          
          {(currentUser?.role === 'ADMIN' || currentUser?.id === story.user) && (
            <Box sx={{
              display: 'flex',
              gap: 0.5
            }}>
              {story.status === 'INCOMPLETE' && (
                <IconButton 
                  size="small" 
                  onClick={() => handleCompleteStory(story)}
                  sx={{
                    color: '#4caf50',
                    p: 0.5,
                    '&:hover': {
                      backgroundColor: 'rgba(76, 175, 80, 0.1)'
                    }
                  }}
                >
                  <CheckCircleIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton 
                size="small" 
                onClick={() => setIsEditing(!isEditing)}
                sx={{
                  color: 'white',
                  p: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton 
                size="small" 
                onClick={() => handleDeleteStory(story.id)}
                sx={{
                  color: '#ef5350',
                  p: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(239, 83, 80, 0.1)'
                  }
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>

        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 1,
          mb: 2
        }}>
          <Typography variant="caption" sx={{
            fontSize: '0.75rem',
            color: 'rgba(255, 255, 255, 0.7)'
          }}>
            By {story.user_name}
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.3)' }}>•</Typography>
          <Typography variant="caption" sx={{
            fontSize: '0.75rem',
            color: 'rgba(255, 255, 255, 0.7)'
          }}>
            {dayjs(story.created_at).fromNow()}
          </Typography>
          <Chip 
            label={story.status}
            size="small"
            color={story.status === 'COMPLETE' ? "success" : "warning"}
            sx={{ ml: 'auto', height: '24px' }}
          />
        </Box>

        {isEditing ? (
          <Box sx={{ mb: 2 }}>
            <ReactQuill
              theme="snow"
              value={editContent}
              onChange={setEditContent}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline'],
                  [{'list': 'ordered'}, {'list': 'bullet'}],
                  ['link', 'clean']
                ]
              }}
              sx={{
                '& .ql-editor': {
                  minHeight: '150px',
                  color: 'white',
                  fontSize: '0.875rem',
                  lineHeight: 1.6
                },
                '& .ql-toolbar': {
                  borderColor: 'rgba(255, 255, 255, 0.1)'
                },
                '& .ql-container': {
                  borderColor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            />
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
              mt: 2
            }}>
              <Button 
                onClick={() => setIsEditing(false)}
                sx={{
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={handleSaveEdit}
                sx={{
                  textTransform: 'none',
                  fontSize: '0.875rem'
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Typography 
              variant="body2"
              sx={{
                mb: 2,
                fontSize: '0.875rem',
                lineHeight: 1.6,
                color: 'rgba(255, 255, 255, 0.9)'
              }}
              dangerouslySetInnerHTML={{ __html: story.content }}
            />

            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 4,
              mb: 2
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <IconButton
                  size="small"
                  onClick={() => story.user_reaction === 'UPVOTE' ? 
                    handleRemoveReaction(story.id) : 
                    handleAddReaction('story', story.id, 'UPVOTE')}
                  sx={{
                    color: story.user_reaction === 'UPVOTE' ? '#4caf50' : 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  <ThumbUpIcon fontSize="small" />
                </IconButton>
                <Typography sx={{
                  ml: 1,
                  fontSize: '0.875rem',
                  color: 'white'
                }}>
                  {story.reactions_count?.upvotes || 0}
                </Typography>
              </Box>

              <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <IconButton
                  size="small"
                  onClick={() => story.user_reaction === 'DOWNVOTE' ? 
                    handleRemoveReaction(story.id) : 
                    handleAddReaction('story', story.id, 'DOWNVOTE')}
                  sx={{
                    color: story.user_reaction === 'DOWNVOTE' ? '#ef5350' : 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  <ThumbDownIcon fontSize="small" />
                </IconButton>
                <Typography sx={{
                  ml: 1,
                  fontSize: '0.875rem',
                  color: 'white'
                }}>
                  {story.reactions_count?.downvotes || 0}
                </Typography>
              </Box>

              <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <IconButton
                  size="small"
                  onClick={() => story.user_reaction === 'SUPERLIKE' ? 
                    handleRemoveReaction(story.id) : 
                    handleAddReaction('story', story.id, 'SUPERLIKE')}
                  sx={{
                    color: story.user_reaction === 'SUPERLIKE' ? '#ffd700' : 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
                <Typography sx={{
                  ml: 1,
                  fontSize: '0.875rem',
                  color: 'white'
                }}>
                  {story.reactions_count?.superlikes || 0}
                </Typography>
              </Box>
            </Box>

            <Button
              fullWidth
              startIcon={showComments ? <ExpandLessIcon /> : <CommentIcon />}
              onClick={() => setShowComments(!showComments)}
              sx={{
                color: 'white',
                textTransform: 'none',
                py: 1,
                borderRadius: 1,
                fontSize: '0.875rem',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              {story.comments?.length || 0} Comments
            </Button>

            {showComments && (
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Add a comment..."
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && e.target.value.trim()) {
                      handleCreateComment('story', story.id, { content: e.target.value.trim() });
                      e.target.value = '';
                    }
                  }}
                  sx={{
                    mb: 2,
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      color: 'white',
                    }
                  }}
                  multiline
                  rows={2}
                />

                {story.comments?.map((comment) => (
                  <CommentItem
                    key={comment.id}
                    comment={comment}
                    onDelete={handleDeleteComment}
                    onUpdate={handleUpdateComment}
                    onReply={handleReplyToComment}
                    currentUser={currentUser}
                  />
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </Card>
  );
};

// Main Render
if (loading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#0A0F24'
      }}>
        <CircularProgress sx={{ color: '#4A90E2' }} />
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box sx={{ p: 2, backgroundColor: '#0A0F24' }}>
        <Alert severity="error" sx={{
          borderRadius: 2,
          backgroundColor: 'rgba(239, 83, 80, 0.1)',
          border: '1px solid rgba(239, 83, 80, 0.3)',
          '& .MuiAlert-icon': {
            color: '#ef5350'
          }
        }}>
          {error}
        </Alert>
      </Box>
    );
  }
  
  return (
    <Box sx={{
      backgroundColor: '#0A0F24',
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* Tabs */}
      
      

      <CollapsibleTabs
        activeTab={activeTab}
        onTabChange={(newTab) => {
          console.log('Tab changed to:', newTab); // Debug log
          setActiveTab(newTab);
        }}
        onNewStory={() => {
          setEditingStory(null);
          setStoryEditorOpen(true);
        }}
        currentUser={currentUser}
      />
  

      <Box sx={{ p: 2 }}>
        {activeTab === 'debates' && (
          <>
            {/* Active Debates */}
            {debates.filter(d => {
              const now = new Date();
              const startDate = new Date(d.start_date);
              const endDate = new Date(d.end_date);
              return d.is_active && startDate <= now && now <= endDate;
            }).map((debate) => (
              <React.Fragment key={debate.id}>
                <DebateCard debate={debate} />
                
                {/* Video Upload Section */}
                {canCreateVideo && (
                  <Card sx={{
                    mb: 2,
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    overflow: 'visible'
                  }}>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6" sx={{
                        mb: 2,
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        color: 'white'
                      }}>
                        Upload Your Response
                      </Typography>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          Your Stance
                        </InputLabel>
                        <Select
                          value={stance}
                          onChange={(e) => setStance(e.target.value)}
                          required
                          sx={{
                            borderRadius: 1,
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'rgba(255, 255, 255, 0.1)'
                            },
                            '& .MuiSelect-select': {
                              color: 'white'
                            }
                          }}
                        >
                          <MenuItem value="FOR">For</MenuItem>
                          <MenuItem value="AGAINST">Against</MenuItem>
                        </Select>
                      </FormControl>
  
                      <Button
                        variant="outlined"
                        component="label"
                        fullWidth
                        sx={{
                          mb: 2,
                          textTransform: 'none',
                          py: 1.5,
                          borderRadius: 1,
                          borderColor: 'rgba(255, 255, 255, 0.1)',
                          color: 'white',
                          '&:hover': {
                            borderColor: 'rgba(255, 255, 255, 0.3)',
                            backgroundColor: 'rgba(255, 255, 255, 0.05)'
                          }
                        }}
                      >
                        {selectedVideo ? 'Change Video' : 'Upload Video'}
                        <input
                          type="file"
                          ref={fileInputRef}
                          accept="video/*"
                          onChange={handleVideoSelect}
                          style={{ display: 'none' }}
                        />
                      </Button>
  
                      {videoPreview && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="subtitle2" sx={{
                            mb: 1,
                            fontSize: '0.875rem',
                            color: 'white'
                          }}>
                            Preview:
                          </Typography>
                          <Box sx={{
                            position: 'relative',
                            width: '100%',
                            maxHeight: '80vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 1,
                            overflow: 'hidden',
                            backgroundColor: 'transparent'
                          }}>
                            <video
                              src={videoPreview}
                              controls
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '80vh',
                                backgroundColor: '#000'
                              }}
                              playsInline
                            />
                          </Box>
                        </Box>
                      )}
  
                      {processingVideo && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="subtitle2" sx={{
                            mb: 1,
                            fontSize: '0.875rem',
                            color: 'white'
                          }}>
                            Upload Progress: {uploadProgress}%
                          </Typography>
                          <LinearProgress 
                            variant="determinate" 
                            value={uploadProgress}
                            sx={{
                              borderRadius: 1,
                              backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#4A90E2'
                              }
                            }}
                          />
                        </Box>
                      )}
  
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={handleVideoUpload}
                        disabled={!selectedVideo || !stance || processingVideo}
                        sx={{
                          textTransform: 'none',
                          py: 1.5,
                          borderRadius: 1,
                          fontSize: '0.875rem',
                          backgroundColor: '#4A90E2',
                          '&:hover': {
                            backgroundColor: '#357abd'
                          },
                          '&.Mui-disabled': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            color: 'rgba(255, 255, 255, 0.3)'
                          }
                        }}
                      >
                        Submit Video Response
                      </Button>
                    </Box>
                  </Card>
                )}
  
                {/* Video Responses */}
                <Typography variant="h6" sx={{
                  mb: 2,
                  fontSize: '1.125rem',
                  fontWeight: 600,
                  color: 'white',
                  px: 1
                }}>
                  Video Responses
                </Typography>
                {videos
                  .filter(video => video.debate_topic === debate.id)
                  .map((video) => (
                    <VideoResponseCard key={video.id} video={video} />
                  ))}
              </React.Fragment>
            ))}
          </>
        )}
  
  {activeTab === 'stories' && (
          <Box>
            {stories.map((story) => (
              <MobileStoryCard
                key={story.id}
                story={story}
                currentUser={currentUser}
                onEdit={() => {
                  setEditingStory(story);
                  setStoryEditorOpen(true);
                }}
                onDelete={() => handleDeleteStory(story.id)}
                onMarkComplete={() => handleCompleteStory(story)}
                SocialInteractions={SocialInteractions}
                showComments={story.showComments}
                setShowComments={(show) => {
                  setStories(prevStories =>
                    prevStories.map(s =>
                      s.id === story.id ? { ...s, showComments: show } : s
                    )
                  );
                }}
                handleAddReaction={handleAddReaction}
                handleRemoveReaction={handleRemoveReaction}
                handleCreateComment={handleCreateComment}
                handleUpdateComment={handleUpdateComment}
                handleDeleteComment={handleDeleteComment}
                handleReplyToComment={handleReplyToComment}
                visibleComments={story.comments?.slice(0, story.showAllComments ? undefined : 2)}
                showAllComments={story.showAllComments}
                setShowAllComments={(show) => {
                  setStories(prevStories =>
                    prevStories.map(s =>
                      s.id === story.id ? { ...s, showAllComments: show } : s
                    )
                  );
                }}
                hasMoreComments={story.comments?.length > 2}
              />
            ))}
          </Box>
        )}
      </Box>

      {/* Enhanced Story Editor Dialog */}
      {storyEditorOpen && (
        <EnhancedShareStoryDialog
          open={true}
          onClose={() => {
            setStoryEditorOpen(false);
            setEditingStory(null);
          }}
          onCreateStory={editingStory ? handleUpdateStory : handleCreateStory}
          onOpenVocabDrawer={() => setIsVocabDrawerOpen(true)}
          initialContent={editingStory?.content || ''}
          initialTitle={editingStory?.title || ''}
          storyId={editingStory?.id || null}
          isEdit={!!editingStory}
        />
      )}

      {/* Vocabulary Reference Drawer */}
      <VocabReferenceDrawer
        isOpen={isVocabDrawerOpen}
        onClose={() => setIsVocabDrawerOpen(false)}
      />
  
      

<ShareStoryDialog 
  open={shareDialogOpen}
  onClose={() => setShareDialogOpen(false)}
  onCreateStory={handleCreateStory}
  onOpenVocabDrawer={() => setIsVocabDrawerOpen(true)} // ✅ Ensures it opens from the button
/>


  
      {/* Processing Video Dialog */}
      <Dialog
        open={processingVideo}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(13, 17, 38, 0.95)',
            backdropFilter: 'blur(10px)',
            borderRadius: 2,
            border: '1px solid rgba(255, 255, 255, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{
          color: 'white',
          fontSize: '1.125rem',
          fontWeight: 600
        }}>
          Processing Video
        </DialogTitle>
        <DialogContent>
          <Box sx={{
            textAlign: 'center',
            py: 2
          }}><CircularProgress sx={{
            mb: 2,
            color: '#4A90E2'
          }} />
          <Typography sx={{
            color: 'white',
            mb: 2
          }}>
            Upload Progress: {uploadProgress}%
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={uploadProgress}
            sx={{
              borderRadius: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#4A90E2'
              }
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>

    {/* Delete Confirmation Dialog */}
    <Dialog
      open={Boolean(selectedItemToDelete)}
      onClose={() => setSelectedItemToDelete(null)}
      PaperProps={{
        sx: {
          backgroundColor: 'rgba(13, 17, 38, 0.95)',
          backdropFilter: 'blur(10px)',
          borderRadius: 2,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          maxWidth: '90%',
          width: '400px'
        }
      }}
    >
      <DialogTitle sx={{
        color: 'white',
        fontSize: '1.125rem',
        fontWeight: 600
      }}>
        Confirm Delete
      </DialogTitle>
      <DialogContent>
        <Typography sx={{
          color: 'white',
          mb: 2
        }}>
          Are you sure you want to delete this item? This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button 
          onClick={() => setSelectedItemToDelete(null)}
          sx={{
            color: 'white',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            if (selectedItemToDelete?.type === 'video') {
              handleDeleteVideo(selectedItemToDelete.id);
            } else if (selectedItemToDelete?.type === 'story') {
              handleDeleteStory(selectedItemToDelete.id);
            }
            setSelectedItemToDelete(null);
          }}
          sx={{
            textTransform: 'none',
            backgroundColor: '#ef5350',
            '&:hover': {
              backgroundColor: '#d32f2f'
            }
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    {showTutorial && (
        <TutorialOverlay onClose={() => setShowTutorial(false)} />
      )}

      {/* Floating Action Button */}
      
{canCreateVideo && activeTab === 'debates' && (
  <Fab
    variant="extended"
    size="small"
    color="primary"
    onClick={handleOpenUploadDialog}
    sx={{
      position: 'fixed',
      bottom: 75,
      left: 16,
      backgroundColor: '#4A90E2',
      height: 36,
      minHeight: 'unset',
      '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
      },
      '& .MuiFab-label': {
        fontSize: '0.8rem',
      },
      '&:hover': {
        backgroundColor: '#357abd'
      }
    }}
  >
    <AddIcon sx={{ mr: 0.5 }} />
    Upload Response
  </Fab>
)}

      {/* Upload Dialog */}
      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(13, 17, 38, 0.95)',
            backdropFilter: 'blur(10px)',
            borderRadius: 2,
            border: '1px solid rgba(255, 255, 255, 0.1)',
            maxWidth: '90%',
            width: '400px'
          }
        }}
      >
        <DialogTitle sx={{
          color: 'white',
          fontSize: '1.125rem',
          fontWeight: 600,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          Upload Video Response
          <IconButton
            size="small"
            onClick={() => setUploadDialogOpen(false)}
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedDebateForUpload && (
            <>
              <Typography sx={{
                color: 'white',
                mb: 2,
                fontSize: '1rem',
                fontWeight: 500
              }}>
                {selectedDebateForUpload.title}
              </Typography>
              <Typography 
                sx={{
                  color: 'rgba(255, 255, 255, 0.7)',
                  mb: 3,
                  fontSize: '0.875rem'
                }}
                dangerouslySetInnerHTML={{ __html: selectedDebateForUpload.description }}
              />
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  Your Stance
                </InputLabel>
                <Select
                  value={stance}
                  onChange={(e) => setStance(e.target.value)}
                  required
                  sx={{
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  <MenuItem value="FOR">For</MenuItem>
                  <MenuItem value="AGAINST">Against</MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{
                  mb: 3,
                  color: 'white',
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                  '&:hover': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                    backgroundColor: 'rgba(255, 255, 255, 0.05)'
                  }
                }}
              >
                {selectedVideo ? 'Change Video' : 'Select Video'}
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleVideoSelect}
                  style={{ display: 'none' }}
                />
              </Button>

              {videoPreview && (
                <Box sx={{ mb: 3 }}>
                  <Box sx={{
                    position: 'relative',
                    width: '100%',
                    maxHeight: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 1,
                    overflow: 'hidden',
                    backgroundColor: 'transparent'
                  }}>
                    <video
                      src={videoPreview}
                      controls
                      style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '80vh',
                        backgroundColor: '#000'
                      }}
                      playsInline
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setUploadDialogOpen(false)}
            sx={{
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleVideoUpload();
              setUploadDialogOpen(false);
            }}
            disabled={!selectedVideo || !stance}
            sx={{
              backgroundColor: '#4A90E2',
              '&:hover': {
                backgroundColor: '#357abd'
              },
              '&.Mui-disabled': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                color: 'rgba(255, 255, 255, 0.3)'
              }
            }}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
  </Box>
);
};

export default MobileSocialPage;