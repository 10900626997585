import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Box,
  Drawer,
  Tabs,
  Tab,
  Fab,
  Tooltip,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material';
import {
  Close as CloseIcon,
  Psychology as PsychologyIcon,
  Book as BookIcon,
  Save as SaveIcon,
  Send as SendIcon
} from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MobileStorySuggestions from './MobileStorySuggestions';

const TabPanel = ({ children, value, index, ...other }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
    sx={{ height: '100%', overflow: 'auto' }}
  >
    {value === index && children}
  </Box>
);

const EnhancedShareStoryDialog = ({
  open,
  onClose,
  onCreateStory,
  onOpenVocabDrawer,
  initialContent = '',
  initialTitle = '',
  storyId = null,
  isEdit = false
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [content, setContent] = useState(initialContent);
  const [currentTab, setCurrentTab] = useState(0);
  const [showAIDrawer, setShowAIDrawer] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleCreateStory = async (status) => {
    setSaving(true);
    try {
      await onCreateStory({
        content,
        title,
        status
      });
      setTitle('');
      setContent('');
      onClose();
    } catch (error) {
      console.error('Error saving story:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleInsertSuggestion = useCallback((text) => {
    setContent((prevContent) => {
      if (!prevContent) return text;
      return prevContent + '\n' + text;
    });
    setCurrentTab(0); // Switch back to editor tab
  }, []);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean']
    ]
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      disableEnforceFocus
      disableRestoreFocus
      PaperProps={{
        sx: {
          backgroundColor: '#0A0F24',
          backgroundImage: 'none',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      {/* Header */}
      <DialogTitle
        sx={{
          backgroundColor: '#0B1537',
          color: 'white',
          position: 'sticky',
          top: 0,
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          p: 2
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          sx={{ color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ flex: 1 }}>
          {isEdit ? 'Edit Story' : 'Share Story'}
        </Box>
        <Tooltip title="Open Vocabulary Reference">
          <IconButton
            onClick={onOpenVocabDrawer}
            sx={{ color: 'white' }}
          >
            <BookIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="AI Writing Assistant">
          <IconButton
            onClick={() => setShowAIDrawer(true)}
            sx={{ color: 'white' }}
          >
            <PsychologyIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      {/* Tabs */}
      <Tabs
        value={currentTab}
        onChange={(e, newValue) => setCurrentTab(newValue)}
        sx={{
          backgroundColor: '#0B1537',
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTab-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            '&.Mui-selected': {
              color: '#4A90E2'
            }
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#4A90E2'
          }
        }}
      >
        <Tab label="Editor" />
        <Tab label="Preview" />
      </Tabs>

      {/* Content */}
      <DialogContent sx={{ p: 0, flex: 1, display: 'flex', flexDirection: 'column' }}>
        <TabPanel value={currentTab} index={0}>
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              label="Story Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{
                mb: 2,
                '& label': { color: '#4A90E2' },
                '& input': { color: 'white' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' },
                  '&:hover fieldset': { borderColor: 'rgba(74,144,226,0.5)' },
                  '&.Mui-focused fieldset': { borderColor: '#4A90E2' }
                }
              }}
            />

            <Box sx={{ 
              '.ql-container': {
                height: 'calc(100vh - 300px)',
                fontSize: '1rem',
                color: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.05)'
              },
              '.ql-toolbar': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderColor: 'rgba(255, 255, 255, 0.2)',
                '& button': { color: 'white' },
                '& .ql-stroke': { stroke: 'white' },
                '& .ql-fill': { fill: 'white' },
                '& .ql-picker': { color: 'white' }
              },
              '.ql-editor': {
                minHeight: '200px',
                color: 'white',
                '&.ql-blank::before': {
                  color: 'rgba(255, 255, 255, 0.4)'
                }
              }
            }}>
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                modules={modules}
                placeholder="Write your story here..."
              />
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <Box sx={{ p: 2 }}>
            <Box sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: 1,
              p: 3
            }}>
              <h1 style={{ color: 'white', marginBottom: '1rem' }}>{title}</h1>
              <div
                style={{ color: 'white' }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Box>
          </Box>
        </TabPanel>

        {/* Enhanced Action Buttons */}
        <Box sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          display: 'flex',
          gap: 2,
          zIndex: 1200
        }}>
          <Stack direction="row" spacing={2} alignItems="center">
            {/* Save as Draft with enhanced tooltip */}
            <Tooltip
              title={
                <Box sx={{ p: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    Save as Draft
                  </Typography>
                  <Typography variant="body2">
                    Save your progress and continue editing later. Only you can see drafts.
                  </Typography>
                </Box>
              }
              arrow
              placement="top"
            >
              <span>
                <Fab
                  color="secondary"
                  onClick={() => handleCreateStory('INCOMPLETE')}
                  disabled={!content.trim() || !title.trim() || saving}
                  sx={{
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.2)'
                    },
                    '&:disabled': {
                      bgcolor: 'rgba(255, 255, 255, 0.05)'
                    }
                  }}
                >
                  {saving ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, px: 2 }}>
                      <SaveIcon />
                      <Typography variant="button" sx={{ display: { xs: 'none', sm: 'block' } }}>
                        Draft
                      </Typography>
                    </Box>
                  )}
                </Fab>
              </span>
            </Tooltip>

            {/* Publish with enhanced tooltip */}
            <Tooltip
              title={
                <Box sx={{ p: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    Publish Story
                  </Typography>
                  <Typography variant="body2">
                    Share your story with the community. This will be visible to everyone.
                  </Typography>
                </Box>
              }
              arrow
              placement="top"
            >
              <span>
                <Fab
                  color="primary"
                  variant="extended"
                  onClick={() => handleCreateStory('COMPLETE')}
                  disabled={!content.trim() || !title.trim() || saving}
                  sx={{
                    bgcolor: '#4A90E2',
                    '&:hover': {
                      bgcolor: '#357abd'
                    },
                    '&:disabled': {
                      bgcolor: 'rgba(74, 144, 226, 0.3)'
                    },
                    px: 3
                  }}
                >
                  {saving ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <SendIcon />
                      <Typography variant="button">
                        Publish
                      </Typography>
                    </Box>
                  )}
                </Fab>
              </span>
            </Tooltip>
          </Stack>
        </Box>
      </DialogContent>

      {/* AI Assistant Drawer */}
      <Drawer
        anchor="right"
        open={showAIDrawer}
        onClose={() => setShowAIDrawer(false)}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '400px',
            backgroundColor: '#0A0F24',
            backgroundImage: 'none',
            zIndex: 1300,
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 0 20px rgba(0,0,0,0.5)'
          }
        }}
        sx={{
          zIndex: 1400,
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <Box sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Box sx={{
            p: 2,
            backgroundColor: '#0B1537',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
          }}>
            <IconButton
              onClick={() => setShowAIDrawer(false)}
              sx={{ color: 'white' }}
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ color: 'white', fontWeight: 500 }}>AI Writing Assistant</Box>
          </Box>
          
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <MobileStorySuggestions
              storyId={storyId}
              onInsertSuggestion={handleInsertSuggestion}
            />
          </Box>
        </Box>
      </Drawer>
    </Dialog>
  );
};

export default EnhancedShareStoryDialog;