import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
  Button,
  styled
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  Assignment as AssignmentIcon,
  ArrowBack as ArrowBackIcon,
  InsertDriveFile as FileIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { listAssignments, createAssignmentResponse } from '../api';

// Styled Components
const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  padding: '8px 16px',
  borderRadius: '20px',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
  }
}));

const AssignmentCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '15px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: '1px solid rgba(255, 255, 255, 0.1)',
  position: 'relative',
  transition: 'transform 0.2s ease',
  '&:active': {
    transform: 'scale(0.98)',
  }
}));

const MobileAssignmentManagement = ({ open, onClose, studentId }) => {
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('pending'); // 'pending' or 'submitted'
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [response, setResponse] = useState({
    content: '',
    files: []
  });

  useEffect(() => {
    if (open) {
      fetchAssignments();
    }
  }, [open, studentId]);

  const fetchAssignments = async () => {
    setLoading(true);
    try {
      const response = await listAssignments({ student: studentId });
      setAssignments(response.data);
    } catch (err) {
      console.error('Error fetching assignments:', err);
      setError('Failed to fetch assignments');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setResponse(prev => ({
      ...prev,
      files: [...prev.files, ...files]
    }));
  };

  const handleRemoveFile = (index) => {
    setResponse(prev => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index)
    }));
  };

  const handleSubmitResponse = async () => {
    try {
      setLoading(true);
      setError(null); // Clear any previous errors
      
      const formData = new FormData();
      formData.append('content', response.content);
      formData.append('assignment', selectedAssignment.id);
      
      response.files.forEach((file, index) => {
        formData.append('uploaded_files', file);
        formData.append('file_types', file.type.split('/')[1]);
      });

      const submitResponse = await createAssignmentResponse(formData);
      console.log('Submission response:', submitResponse); // For debugging
      
      // Refresh assignments list
      await fetchAssignments();
      
      // Clear form and close dialog
      setResponse({ content: '', files: [] });
      setSelectedAssignment(null);
      setError(null);
      
    } catch (err) {
      console.error('Submission error:', err);
      if (err.response?.data) {
        // Handle specific API error messages
        const errorMessage = err.response.data.non_field_errors?.[0] 
          || err.response.data.detail 
          || Object.values(err.response.data)[0]?.[0]
          || 'Failed to submit response';
        setError(errorMessage);
      } else {
        setError('Network error or server not responding');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderAssignmentList = () => {
    const filteredAssignments = assignments.filter(assignment => {
      const hasResponse = assignment.responses.some(r => r.student.id === studentId);
      return activeTab === 'pending' ? !hasResponse : hasResponse;
    });

    if (filteredAssignments.length === 0) {
      return (
        <Box sx={{ p: 3, textAlign: 'center', color: 'rgba(255,255,255,0.7)' }}>
          {activeTab === 'pending' ? 'No pending assignments' : 'No submitted assignments'}
        </Box>
      );
    }

    return filteredAssignments.map(assignment => (
      <AssignmentCard
        key={assignment.id}
        onClick={() => setSelectedAssignment(assignment)}
      >
        <Typography variant="subtitle1" sx={{ color: '#4A90E2', mb: 1 }}>
          {assignment.title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)', mb: 2 }}>
          Due: {dayjs(assignment.expires_at).format('MMM D, YYYY HH:mm')}
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'white',
            mb: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
          }}
        >
          {assignment.description}
        </Typography>
        
        {assignment.files?.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              Attachments: {assignment.files.length}
            </Typography>
          </Box>
        )}

        {activeTab === 'submitted' && assignment.responses[0] && (
          <Chip 
            label={`Score: ${assignment.responses[0].score || 'Pending'}`}
            color="primary"
            size="small"
            sx={{ mr: 1 }}
          />
        )}
        
        <Box sx={{ 
          position: 'absolute',
          right: 16,
          top: '50%',
          transform: 'translateY(-50%)'
        }}>
          <KeyboardArrowRightIcon sx={{ color: '#4A90E2' }} />
        </Box>
      </AssignmentCard>
    ));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        PaperProps={{
          sx: {
            backgroundColor: '#0A0F24',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle sx={{ 
          bgcolor: '#0B1537', 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          position: 'sticky',
          top: 0,
          zIndex: 1
        }}>
          <IconButton edge="start" color="inherit" onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ color: 'white' }}>
            Assignments
          </Typography>
        </DialogTitle>

        <Box sx={{ 
          display: 'flex', 
          borderBottom: '1px solid rgba(255,255,255,0.1)',
          mb: 2
        }}>
          <Button
            fullWidth
            onClick={() => setActiveTab('pending')}
            sx={{
              color: activeTab === 'pending' ? '#4A90E2' : 'rgba(255,255,255,0.5)',
              borderBottom: activeTab === 'pending' ? '2px solid #4A90E2' : 'none',
              borderRadius: 0
            }}
          >
            Pending
          </Button>
          <Button
            fullWidth
            onClick={() => setActiveTab('submitted')}
            sx={{
              color: activeTab === 'submitted' ? '#4A90E2' : 'rgba(255,255,255,0.5)',
              borderBottom: activeTab === 'submitted' ? '2px solid #4A90E2' : 'none',
              borderRadius: 0
            }}
          >
            Submitted
          </Button>
        </Box>

        <DialogContent sx={{ p: 2 }}>
          {loading ? (
            <Box display="flex" justifyContent="center" p={3}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            renderAssignmentList()
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={Boolean(selectedAssignment)}
        onClose={() => setSelectedAssignment(null)}
        fullScreen
        PaperProps={{
          sx: {
            backgroundColor: '#0A0F24',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle sx={{ 
          bgcolor: '#0B1537', 
          display: 'flex', 
          alignItems: 'center',
          gap: 2
        }}>
          <IconButton 
            edge="start" 
            color="inherit" 
            onClick={() => setSelectedAssignment(null)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ color: 'white' }}>
            {selectedAssignment?.title}
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ p: 2 }}>
          {selectedAssignment && (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {selectedAssignment.description}
              </Typography>

              <Typography variant="subtitle2" sx={{ mb: 1, color: '#4A90E2' }}>
                Attachments
              </Typography>
              {selectedAssignment.files?.map((file, index) => (
                <GradientButton
                  key={index}
                  fullWidth
                  startIcon={<FileIcon />}
                  onClick={() => window.open(file.file, '_blank')}
                  sx={{ mb: 1 }}
                >
                  View Attachment {index + 1}
                </GradientButton>
              ))}

              {activeTab === 'pending' && (
                <>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Your Response"
                    value={response.content}
                    onChange={(e) => setResponse(prev => ({ ...prev, content: e.target.value }))}
                    margin="normal"
                    sx={{
                      '& label': { color: '#4A90E2' },
                      '& textarea': { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                      }
                    }}
                  />

                  <input
                    type="file"
                    multiple
                    id="response-files"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <label htmlFor="response-files">
                    <GradientButton
                      component="span"
                      startIcon={<CloudUploadIcon />}
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Upload Files
                    </GradientButton>
                  </label>

                  <List>
                    {response.files.map((file, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <FileIcon sx={{ color: '#4A90E2' }} />
                        </ListItemIcon>
                        <ListItemText 
                          primary={file.name}
                          sx={{ color: 'white' }}
                        />
                        <IconButton onClick={() => handleRemoveFile(index)}>
                          <DeleteIcon sx={{ color: '#FF1B6B' }} />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>

                  <GradientButton
                    onClick={handleSubmitResponse}
                    disabled={loading || !response.content}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    Submit Response
                  </GradientButton>
                </>
              )}

{activeTab === 'submitted' && selectedAssignment.responses[0] && (
  <>
    <Typography variant="subtitle2" sx={{ mt: 3, mb: 1, color: '#4A90E2' }}>
      Your Submission
    </Typography>
    <Typography variant="body2" sx={{ mb: 2, color: 'white' }}>
      {selectedAssignment.responses[0].content}
    </Typography>
    
    {selectedAssignment.responses[0].files?.length > 0 && (
      <>
        <Typography variant="subtitle2" sx={{ mb: 1, color: '#4A90E2' }}>
          Your Attachments
        </Typography>
        {selectedAssignment.responses[0].files.map((file, index) => (
          <GradientButton
            key={index}
            fullWidth
            startIcon={<FileIcon />}
            onClick={() => window.open(file.file, '_blank')}
            sx={{ mb: 1 }}
          >
            View Your Attachment {index + 1}
          </GradientButton>
        ))}
      </>
    )}

    {selectedAssignment.responses[0].feedback && (
      <Box sx={{ 
        mt: 3,
        mb: 3,
        bgcolor: 'rgba(76, 175, 80, 0.1)',
        p: 2,
        borderRadius: 2,
        border: '1px solid rgba(76, 175, 80, 0.2)'
      }}>
        <Typography variant="subtitle2" sx={{ color: '#4CAF50', mb: 1 }}>
          Trainer Feedback
        </Typography>
        {selectedAssignment.responses[0].score && (
          <Typography variant="body2" sx={{ color: '#4CAF50', mb: 2 }}>
            Score: {selectedAssignment.responses[0].score}/100
          </Typography>
        )}
        <Typography variant="body2" sx={{ color: 'white', mb: 1 }}>
          {selectedAssignment.responses[0].feedback}
        </Typography>
        {selectedAssignment.responses[0].feedback_by_name && (
          <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
            By: {selectedAssignment.responses[0].feedback_by_name} | 
            {` ${dayjs(selectedAssignment.responses[0].feedback_at).format('MMM D, YYYY HH:mm')}`}
          </Typography>
        )}
      </Box>
    )}

    <Box sx={{ mt: 3 }}>
      <Chip 
        label={selectedAssignment.responses[0].score ? `Score: ${selectedAssignment.responses[0].score}/100` : 'Pending Review'}
        color={selectedAssignment.responses[0].score ? "success" : "primary"}
        sx={{ mr: 1 }}
      />
      <Chip 
        label={`Submitted: ${dayjs(selectedAssignment.responses[0].submitted_at).format('MMM D, YYYY HH:mm')}`}
        variant="outlined"
      />
    </Box>
  </>
)}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MobileAssignmentManagement;