import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, IconButton, Box } from '@mui/material';
import { Close as CloseIcon, Book } from '@mui/icons-material';
import ReactQuill from 'react-quill';

const ShareStoryDialog = ({ open, onClose, onCreateStory, onOpenVocabDrawer }) => {
  const [storyTitle, setStoryTitle] = useState('');
  const [newStoryContent, setNewStoryContent] = useState('');

  const handleCreateStory = (status) => {
    onCreateStory({
      content: newStoryContent,
      title: storyTitle,
      status: status
    });
    setStoryTitle('');
    setNewStoryContent('');
    onClose();
  };

  return (
    <Dialog
  open={open}
  onClose={onClose}
  fullScreen
  disableEnforceFocus // ✅ Fixes issue: allows interaction with VocabReferenceDrawer
  disableRestoreFocus // ✅ Prevents unwanted refocus on closing
  PaperProps={{
    sx: {
      backgroundColor: '#0A0F24',
      zIndex: 1500, // Lower than VocabReferenceDrawer
      overflow: 'visible' // Prevents clipping issues
    }
  }}
>

      {/* Dialog Header */}
      <DialogTitle
  sx={{
    backgroundColor: '#0B1537',
    color: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    pb: 2 // ✅ Adds space BELOW the title but keeps background filled
  }}
>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <IconButton edge="start" color="inherit" onClick={onClose} sx={{ color: 'white' }}>
      <CloseIcon />
    </IconButton>
    <span className="text-lg font-semibold">Share Story</span>
  </Box>
</DialogTitle>




      {/* Dialog Content */}
      <DialogContent sx={{ backgroundColor: '#0A0F24', p: 2 }}>
        <TextField
          fullWidth
          label="Story Title"
          value={storyTitle}
          onChange={(e) => setStoryTitle(e.target.value)}
          sx={{
            mb: 2,
            '& label': { color: '#4A90E2' },
            '& input': { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
            }
          }}
        />

        {/* Story Content Editor */}
        <Box sx={{ mb: 2 }}>
          <ReactQuill
            theme="snow"
            value={newStoryContent}
            onChange={setNewStoryContent}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'clean']
              ]
            }}
          />
        </Box>

        {/* Buttons */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Post Story */}
          <Button
            variant="contained"
            onClick={() => handleCreateStory('COMPLETE')}
            disabled={!newStoryContent.trim() || !storyTitle.trim()}
            fullWidth
            sx={{
              textTransform: 'none',
              py: 1.5,
              borderRadius: 1,
              fontSize: '0.875rem',
              backgroundColor: '#4A90E2',
              '&:hover': { backgroundColor: '#357abd' }
            }}
          >
            Post Story
          </Button>

          {/* Save as Draft */}
          <Button
            variant="outlined"
            onClick={() => handleCreateStory('INCOMPLETE')}
            disabled={!newStoryContent.trim() || !storyTitle.trim()}
            fullWidth
            sx={{
              textTransform: 'none',
              py: 1.5,
              borderRadius: 1,
              fontSize: '0.875rem',
              borderColor: 'rgba(74,144,226,0.5)',
              color: 'white',
              '&:hover': { borderColor: '#4A90E2', backgroundColor: 'rgba(74,144,226,0.1)' }
            }}
          >
            Save as Draft
          </Button>

          {/* Open VocabReferenceDrawer */}
          <Button
            variant="outlined"
            onClick={onOpenVocabDrawer} // Calls function from MobileSocialPage
            fullWidth
            startIcon={<Book />}
            sx={{
              textTransform: 'none',
              py: 1.5,
              borderRadius: 1,
              fontSize: '0.875rem',
              borderColor: 'rgba(74,144,226,0.5)',
              color: 'white',
              '&:hover': { borderColor: '#4A90E2', backgroundColor: 'rgba(74,144,226,0.1)' }
            }}
          >
            View Vocabulary Reference
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShareStoryDialog;
