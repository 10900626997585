import React, { useState, useEffect } from 'react';
import { Fab, Card, Typography, Box, Button, IconButton } from '@mui/material';
import {
  Close as CloseIcon,
  NavigateNext as NextIcon,
  NavigateBefore as PrevIcon,
  TouchApp as TouchIcon
} from '@mui/icons-material';

const TutorialOverlay = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [shouldShowTutorial, setShouldShowTutorial] = useState(false);

  useEffect(() => {
    // Check if we should show the tutorial
    const checkTutorialViews = () => {
      const tutorialViews = parseInt(localStorage.getItem('tutorialViews') || '0');
      const MAX_VIEWS = 2;
      
      if (tutorialViews < MAX_VIEWS) {
        setShouldShowTutorial(true);
        // Increment the view count
        localStorage.setItem('tutorialViews', (tutorialViews + 1).toString());
      }
    };

    checkTutorialViews();
  }, []);

  // Define tutorial steps
  const steps = [
    {
      title: "Welcome to Social Hub!",
      content: "Let's take a quick tour of all the features available to you.",
      position: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
    },
    {
      title: "Switch Between Tabs",
      content: "Toggle between Debates and Stories using these tabs. Each section offers unique ways to engage with the community.",
      position: { bottom: '120px', left: '50%', transform: 'translateX(-50%)' }
    },
    {
      title: "Video Responses",
      content: "Participate in debates by uploading video responses. Keep them under 3 minutes for optimal engagement. Choose your stance and share your perspective!",
      position: { top: '30%', left: '50%', transform: 'translateX(-50%)' }
    },
    {
      title: "Share Your Stories",
      content: "Click the + button to create new stories. Save drafts to get AI-powered suggestions for improvement!",
      position: { bottom: '120px', right: '20px' }
    },
    {
      title: "Interactive Features",
      content: "React to content with likes, comments, and superlikes. Engage with others through comments and replies.",
      position: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
    },
    {
      title: "AI Writing Assistant",
      content: "When you save a story as draft, our AI assistant analyzes your writing and provides helpful suggestions for vocabulary and plot development.",
      position: { top: '40%', right: '20px' }
    },
    {
      title: "Vocabulary Reference",
      content: "Access the vocabulary drawer anytime to look up words and their definitions while writing.",
      position: { top: '20px', right: '20px' }
    }
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setShouldShowTutorial(false);
      onClose();
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClose = () => {
    setShouldShowTutorial(false);
    onClose();
  };

  if (!shouldShowTutorial) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 2000,
        backdropFilter: 'blur(4px)'
      }}
    >
      <Card
        sx={{
          position: 'absolute',
          maxWidth: '85%',
          width: '300px',
          bgcolor: '#0B1537',
          color: 'white',
          borderRadius: 2,
          p: 2,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.24)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          ...steps[currentStep].position
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
            {steps[currentStep].title}
          </Typography>
          <IconButton 
            size="small" 
            onClick={handleClose}
            sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Typography sx={{ mb: 3, color: 'rgba(255, 255, 255, 0.9)', fontSize: '0.9rem' }}>
          {steps[currentStep].content}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            startIcon={<PrevIcon />}
            onClick={handlePrev}
            disabled={currentStep === 0}
            sx={{
              color: 'white',
              '&.Mui-disabled': {
                color: 'rgba(255, 255, 255, 0.3)'
              }
            }}
          >
            Back
          </Button>
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            {steps.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  bgcolor: index === currentStep ? '#4A90E2' : 'rgba(255, 255, 255, 0.3)'
                }}
              />
            ))}
          </Box>
          <Button
            endIcon={currentStep === steps.length - 1 ? <TouchIcon /> : <NextIcon />}
            onClick={handleNext}
            sx={{
              color: 'white',
              bgcolor: '#4A90E2',
              '&:hover': {
                bgcolor: '#357abd'
              }
            }}
          >
            {currentStep === steps.length - 1 ? 'Got it!' : 'Next'}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default TutorialOverlay;