import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Chip,
  IconButton,
  Button
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Psychology as PsychologyIcon,
} from '@mui/icons-material';
import { getStorySuggestions, requestNewSuggestions } from '../api';

const MobileStorySuggestions = ({ storyId, onInsertSuggestion }) => {
  const [suggestions, setSuggestions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const fetchSuggestions = async () => {
    if (!storyId) return;
    
    setLoading(true);
    try {
      const response = await getStorySuggestions(storyId);
      
      if (response.data.status === 'PENDING') {
        setTimeout(fetchSuggestions, 2000);
        setSuggestions(response.data);
      } else {
        setSuggestions(response.data);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshSuggestions = async () => {
    if (!storyId) return;
    
    setRefreshing(true);
    try {
      await requestNewSuggestions(storyId);
      await fetchSuggestions();
    } catch (error) {
      console.error('Error refreshing suggestions:', error);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, [storyId]);

  const renderMarkdownContent = (content) => {
    if (!content) return null;

    return content.split('\n\n').map((section, idx) => {
      if (section.startsWith('# ')) {
        // Main heading
        return (
          <Typography 
            key={idx} 
            variant="h6" 
            sx={{ 
              mt: 3, 
              mb: 2, 
              color: '#ffffff',
              fontWeight: 500 
            }}
          >
            {section.replace('# ', '')}
          </Typography>
        );
      } else if (section.startsWith('## ')) {
        // Subheading
        return (
          <Typography 
            key={idx} 
            sx={{ 
              mt: 2, 
              mb: 1, 
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '1rem'
            }}
          >
            {section.replace('## ', '')}
          </Typography>
        );
      } else if (section.startsWith('- ')) {
        // List items
        return (
          <List dense key={idx} sx={{ mt: 1, mb: 1 }}>
            {section.split('\n').map((item, i) => (
              <ListItem key={i}>
                <ListItemText
                  primary={
                    <Typography sx={{ color: '#ffffff' }}>
                      {item.replace('- ', '')}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        );
      } else {
        // Regular paragraph
        return (
          <Typography 
            key={idx} 
            sx={{ 
              mb: 2, 
              color: '#ffffff',
              fontSize: '0.875rem'
            }}
          >
            {section}
          </Typography>
        );
      }
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2,
        pb: 2,
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PsychologyIcon sx={{ color: '#0ea5e9' }} />
          <Typography sx={{ color: '#ffffff', fontWeight: 500 }}>
            AI Writing Assistant
          </Typography>
        </Box>
        <IconButton 
          onClick={handleRefreshSuggestions}
          disabled={refreshing || loading}
          sx={{ 
            color: '#ffffff',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
        >
          {(refreshing || loading) ? (
            <CircularProgress size={24} sx={{ color: '#0ea5e9' }} />
          ) : (
            <RefreshIcon />
          )}
        </IconButton>
      </Box>

      {loading && !suggestions ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress sx={{ color: '#0ea5e9' }} />
        </Box>
      ) : suggestions?.status === 'PENDING' ? (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <CircularProgress size={24} sx={{ mb: 2, color: '#0ea5e9' }} />
          <Typography sx={{ color: '#ffffff' }}>
            Analyzing your story...
          </Typography>
        </Box>
      ) : (
        <Box sx={{ bgcolor: 'rgba(255, 255, 255, 0.03)', borderRadius: 1, p: 2 }}>
          {renderMarkdownContent(suggestions?.content)}
          
          {suggestions?.vocabulary_used && (
            <Box sx={{ mt: 3 }}>
              <Typography sx={{ color: '#ffffff', fontWeight: 500, mb: 1 }}>
                Vocabulary Used
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {suggestions.vocabulary_used.map((word, index) => (
                  <Chip
                    key={index}
                    label={word}
                    size="small"
                    sx={{
                      bgcolor: '#0ea5e9',
                      color: '#ffffff',
                      '&:hover': {
                        bgcolor: '#0284c7'
                      }
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MobileStorySuggestions;