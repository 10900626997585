import React, { useState, useEffect } from 'react';
import { submitQuiz, getQuizAttempts } from '../api';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Divider,
  Tooltip,
  LinearProgress,
  IconButton,
  useTheme,
  useMediaQuery,
  Paper,
  AppBar,
  Toolbar,
  Slide,
  Grid
} from '@mui/material';
import {
  Article as ArticleIcon,
  OndemandVideo as VideoIcon,
  QuestionMark as QuizIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  PlayArrow as PlayIcon,
  NavigateNext as NextIcon,
  NavigateBefore as PrevIcon,
  Close as CloseIcon,
  Lock as LockIcon
} from '@mui/icons-material';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContentViewer = ({
  open,
  onClose,
  content,
  onComplete,
  currentCompletionStatus,
  previousContentCompleted = true
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  
  const [quizState, setQuizState] = useState({
    answers: {},
    submitted: false,
    results: null,
    attempts: [],
    loading: false,
    error: null,
    bestScore: 0
  });

  useEffect(() => {
    if (content?.content_type === 'QUIZ') {
      loadQuizAttempts();
    }
    return () => resetQuizState();
  }, [content]);

  // If previous content isn't completed, show locked state
  if (!previousContentCompleted) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            bgcolor: '#1a1a1a',
            color: '#ffffff'
          }
        }}
      >
        <AppBar sx={{ position: 'relative', bgcolor: '#2d2d2d' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ ml: 2 }}>
              Content Locked
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          minHeight: '300px'
        }}>
          <LockIcon sx={{ fontSize: 64, color: '#666', mb: 2 }} />
          <Typography variant="h6" gutterBottom>
            This content is currently locked
          </Typography>
          <Typography variant="body1" color="text.secondary" align="center">
            Please complete the previous content before accessing this section.
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  const resetQuizState = () => {
    setQuizState({
      answers: {},
      submitted: false,
      results: null,
      attempts: [],
      loading: false,
      error: null,
      bestScore: 0
    });
    setCurrentQuestionIndex(0);
  };

  const loadQuizAttempts = async () => {
    try {
      const response = await getQuizAttempts(
        content.flowId,
        content.content_id,
        content.itemId
      );
      const attempts = response.data;
      const bestScore = attempts.length > 0 
        ? Math.max(...attempts.map(a => a.score))
        : 0;
  
      setQuizState(prev => ({
        ...prev,
        attempts,
        bestScore
      }));
    } catch (err) {
      console.error('Error loading quiz attempts:', err);
      setQuizState(prev => ({
        ...prev,
        error: 'Failed to load previous attempts'
      }));
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < (content?.questions?.length || 0) - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  const handleQuizAnswer = (questionId, optionId) => {
    setQuizState(prev => ({
      ...prev,
      answers: {
        ...prev.answers,
        [questionId]: optionId
      }
    }));
  };

  const handleQuizSubmit = async () => {
    try {
      setQuizState(prev => ({ ...prev, loading: true, error: null }));
  
      const formattedAnswers = Object.entries(quizState.answers).map(([questionId, optionId]) => ({
        question_id: parseInt(questionId),
        option_id: parseInt(optionId)
      }));
  
      const response = await submitQuiz(content.flowId, {
        content_id: content.content_id,
        flow_item_id: content.itemId,
        answers: formattedAnswers
      });
  
      setQuizState(prev => ({
        ...prev,
        results: response.data,
        submitted: true,
        loading: false
      }));
  
      if (response.data.passed) {
        onComplete();
      }
    } catch (err) {
      console.error('Error submitting quiz:', err);
      setQuizState(prev => ({
        ...prev,
        loading: false,
        error: err.response?.data?.error || 'Failed to submit quiz'
      }));
    }
  };

  const handleRetryQuiz = () => {
    setQuizState(prev => ({
      ...prev,
      answers: {},
      submitted: false,
      results: null,
      error: null
    }));
    setCurrentQuestionIndex(0);
  };

  const renderTextContent = () => (
    <Box sx={{
      color: '#ffffff',
      p: 2,
      '& img': { maxWidth: '100%', height: 'auto' },
      '& table': { width: '100%', borderCollapse: 'collapse', my: 2 },
      '& th, & td': { border: '1px solid', borderColor: 'rgba(255, 255, 255, 0.1)', p: 2 },
      '& code': {
        bgcolor: 'rgba(255, 255, 255, 0.1)',
        p: 0.5,
        borderRadius: 1,
        fontSize: '0.875em'
      },
      '& pre': {
        bgcolor: 'rgba(255, 255, 255, 0.1)',
        p: 2,
        borderRadius: 1,
        overflow: 'auto'
      }
    }}>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {content.markdown_content}
      </ReactMarkdown>
    </Box>
  );

  const renderVideoContent = () => (
    <Box sx={{ width: '100%', height: isMobile ? '100vh' : '600px', position: 'relative' }}>
      {content.is_youtube ? (
        <iframe
          width="100%"
          height="100%"
          src={content.video_url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      ) : (
        <video
          width="100%"
          height="100%"
          controls
          src={content.video_url}
          style={{ position: 'absolute', top: 0, left: 0, objectFit: 'contain' }}
        />
      )}
    </Box>
  );

  const renderQuizProgress = () => {
    const answeredCount = Object.keys(quizState.answers).length;
    const totalQuestions = content.questions?.length || 0;
    const progress = (answeredCount / totalQuestions) * 100;

    return (
      <Box sx={{ mt: 2, mb: 3, px: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
            Progress: {answeredCount} of {totalQuestions} questions answered
          </Typography>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
            {progress.toFixed(0)}%
          </Typography>
        </Box>
        <LinearProgress 
          variant="determinate" 
          value={progress}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#7c4dff'
            }
          }}
        />
      </Box>
    );
  };

  const NavigationControls = ({ position = 'bottom', showSubmit = true }) => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      p: 2,
      ...(position === 'bottom' && isMobile && {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: '#1a1a1a',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        zIndex: 1000
      })
    }}>
      <Button
        variant="contained"
        onClick={handlePrev}
        disabled={currentQuestionIndex === 0}
        startIcon={<PrevIcon />}
        sx={{
          bgcolor: '#7c4dff',
          '&:hover': { bgcolor: '#6c3fff' },
          '&.Mui-disabled': {
            bgcolor: 'rgba(255, 255, 255, 0.12)',
            color: 'rgba(255, 255, 255, 0.3)'
          }
        }}
      >
        Previous
      </Button>
      
      {showSubmit && (
        <Button
          variant="contained"
          onClick={handleQuizSubmit}
          disabled={quizState.loading || 
            Object.keys(quizState.answers).length !== content.questions.length}
          sx={{
            bgcolor: '#7c4dff',
            '&:hover': { bgcolor: '#6c3fff' },
            '&.Mui-disabled': {
              bgcolor: 'rgba(255, 255, 255, 0.12)',
              color: 'rgba(255, 255, 255, 0.3)'
            }
          }}
        >
          {quizState.loading ? <CircularProgress size={24} /> : 'Submit Quiz'}
        </Button>
      )}

      <Button
        variant="contained"
        onClick={handleNext}
        disabled={currentQuestionIndex === content.questions.length - 1}
        endIcon={<NextIcon />}
        sx={{
          bgcolor: '#7c4dff',
          '&:hover': { bgcolor: '#6c3fff' },
          '&.Mui-disabled': {
            bgcolor: 'rgba(255, 255, 255, 0.12)',
            color: 'rgba(255, 255, 255, 0.3)'
          }
        }}
      >
        Next
      </Button>
    </Box>
  );

  const renderQuizContent = () => {
    if (!content.questions) {
      return <Typography color="error">No questions available</Typography>;
    }

    const currentQuestion = content.questions[currentQuestionIndex];

    return (
      <Box sx={{ pb: isMobile ? 8 : 0 }}>
        {content.description && !quizState.submitted && (
          <Box sx={{ mb: 3, p: 2 }}>
            <Typography variant="subtitle1" color="rgba(255, 255, 255, 0.7)" gutterBottom>
              Instructions:
            </Typography>
            <Typography variant="body1" color="#ffffff">
              {content.description}
            </Typography>
            <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
          </Box>
        )}

        {quizState.attempts.length > 0 && (
          <Alert 
            severity="info" 
            sx={{ 
              mx: 2,
              mb: 3,
              backgroundColor: 'rgba(41, 121, 255, 0.1)',
              color: '#ffffff'
            }}
          >
            Your best score: {quizState.bestScore}% 
            {quizState.bestScore >= 70 ? ' (Passed)' : ' (Not passed yet)'}
          </Alert>
        )}

        {!quizState.submitted ? (
          <>
            {renderQuizProgress()}
            {!isMobile && (
              <Box sx={{ px: 2, mb: 2 }}>
                <NavigationControls position="top" showSubmit={false} />
              </Box>
            )}
            
            <Card sx={{ 
              mx: 2,
              mb: 4, 
              bgcolor: '#2d2d2d',
              color: '#ffffff'
            }}>
              <CardContent>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mb: 2 
                }}>
                  <Typography variant="h6" color="#ffffff">
                    Question {currentQuestionIndex + 1} of {content.questions.length}
                  </Typography>
                </Box>

                <Typography variant="body1" sx={{ mb: 3, color: '#ffffff' }}>
                  {currentQuestion.question_text}
                </Typography>

                {currentQuestion.question_image && (
                  <Box
                    component="img"
                    src={currentQuestion.question_image}
                    alt="Question illustration"
                    sx={{ 
                      maxWidth: '100%', 
                      height: 'auto', 
                      mb: 3,
                      borderRadius: 2
                    }}
                  />
                )}

                {currentQuestion.question_video_url && (
                  <Box sx={{ width: '100%', aspectRatio: '16/9', mb: 3 }}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={currentQuestion.question_video_url}
                      frameBorder="0"
                      allowFullScreen
                    />
                  </Box>
                )}

                <RadioGroup
                  value={quizState.answers[currentQuestion.id]?.toString() || ''}
                  onChange={(e) => handleQuizAnswer(currentQuestion.id, e.target.value)}
                >
                  {currentQuestion.options.map((option) => (
                    <Paper
                      key={option.id}
                      sx={{
                        p: 2,
                        my: 1,
                        bgcolor: quizState.answers[currentQuestion.id]?.toString() === option.id.toString()
                          ? '#4a148c'
                          : '#2d2d2d',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          bgcolor: '#3d3d3d'
                        },
                        border: '2px solid',
                        borderColor: quizState.answers[currentQuestion.id]?.toString() === option.id.toString()
                          ? '#7c4dff'
                          : 'transparent',
                        borderRadius: 2
                      }}
                      onClick={() => handleQuizAnswer(currentQuestion.id, option.id.toString())}
                    >
                      <FormControlLabel
                        value={option.id.toString()}
                        control={
                          <Radio 
                            sx={{
                              color: '#ffffff',
                              '&.Mui-checked': {
                                color: '#7c4dff'
                              }
                            }}
                          />
                        }
                        label={
                          <Box>
                            <Typography color="#ffffff">{option.option_text}</Typography>
                            {option.option_image && (
                              <Box
                                component="img"
                                src={option.option_image}
                                alt="Option illustration"
                                sx={{ 
                                  maxWidth: '100%', 
                                  height: 'auto', 
                                  mt: 1,
                                  borderRadius: 1
                                }}
                              />
                            )}
                          </Box>
                        }
                        sx={{ margin: 0 }}
                      />
                    </Paper>
                  ))}
                </RadioGroup>
              </CardContent>
            </Card>

            {/* Navigation Controls */}
            {isMobile ? (
              <Box sx={{ height: 80 }} /> // Spacer for mobile bottom navigation
            ) : (
              <Box sx={{ px: 2, mb: 2 }}>
                <NavigationControls position="bottom" />
              </Box>
            )}

            {isMobile && <NavigationControls />}
          </>
        ) : (
          <Box sx={{ p: 2 }}>
            <Alert
              severity={quizState.results?.score >= 70 ? "success" : "error"}
              sx={{ 
                mb: 3,
                backgroundColor: quizState.results?.score >= 70 
                  ? 'rgba(46, 125, 50, 0.1)'
                  : 'rgba(211, 47, 47, 0.1)',
                color: '#ffffff'
              }}
            >
              {quizState.results?.score >= 70 
                ? `Congratulations! You passed with ${quizState.results.score}%`
                : `You scored ${quizState.results.score}%. You need 70% to pass.`
              }
            </Alert>

            {/* Quiz Results Section */}
            {content.questions.map((question, index) => {
              const submission = quizState.results?.answer_submissions?.find(
                sub => sub.question === question.id
              );
              const selectedOption = question.options.find(
                opt => opt.id === submission?.selected_option
              );
              const correctOption = question.options.find(opt => opt.is_correct);

              return (
                <Card 
                  key={question.id} 
                  sx={{ 
                    mb: 2,
                    bgcolor: '#2d2d2d',
                    color: '#ffffff'
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      {submission?.is_correct ? (
                        <CheckCircleIcon sx={{ color: '#4caf50' }} />
                      ) : (
                        <ErrorIcon sx={{ color: '#f44336' }} />
                      )}
                      <Typography variant="subtitle1" color="#ffffff">
                        Question {index + 1}: {submission?.is_correct ? 'Correct' : 'Incorrect'}
                      </Typography>
                    </Box>

                    <Typography variant="body1" gutterBottom color="#ffffff">
                      {question.question_text}
                    </Typography>

                    {question.question_image && (
                      <Box
                        component="img"
                        src={question.question_image}
                        alt="Question illustration"
                        sx={{ 
                          maxWidth: '100%', 
                          height: 'auto', 
                          mb: 2,
                          borderRadius: 2 
                        }}
                      />
                    )}

                    {question.question_video_url && (
                      <Box sx={{ width: '100%', aspectRatio: '16/9', mb: 2 }}>
                        <iframe
                          width="100%"
                          height="100%"
                          src={question.question_video_url}
                          frameBorder="0"
                          allowFullScreen
                        />
                      </Box>
                    )}

                    {/* Display all options with correct/incorrect indicators */}
                    {question.options.map(option => {
                      const isSelected = option.id === submission?.selected_option;
                      const isCorrect = option.is_correct;

                      return (
                        <Paper
                          key={option.id}
                          sx={{
                            p: 2,
                            my: 1,
                            borderRadius: 2,
                            bgcolor: isSelected
                              ? (isCorrect ? '#1b5e20' : '#7f0000')
                              : isCorrect
                                ? '#1b5e20'
                                : '#2d2d2d',
                            border: '1px solid',
                            borderColor: isSelected
                              ? (isCorrect ? '#4caf50' : '#f44336')
                              : isCorrect
                                ? '#4caf50'
                                : 'rgba(255, 255, 255, 0.1)'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                            <Box sx={{ flex: 1 }}>
                              <Typography color="#ffffff">
                                {option.option_text}
                              </Typography>

                              {option.option_image && (
                                <Box
                                  component="img"
                                  src={option.option_image}
                                  alt="Option illustration"
                                  sx={{ 
                                    width: '100%', 
                                    height: 'auto', 
                                    mt: 1,
                                    borderRadius: 1 
                                  }}
                                />
                              )}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                              {isSelected && (
                                <Typography 
                                  variant="caption" 
                                  sx={{ 
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                                    px: 1,
                                    py: 0.5,
                                    borderRadius: 1
                                  }}
                                >
                                  Your answer
                                </Typography>
                              )}
                              {isCorrect && (
                                <Typography 
                                  variant="caption" 
                                  sx={{ 
                                    color: '#4caf50',
                                    bgcolor: 'rgba(76, 175, 80, 0.1)',
                                    px: 1,
                                    py: 0.5,
                                    borderRadius: 1
                                  }}
                                >
                                  Correct answer
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Paper>
                      );
                    })}

                    {question.explanation && (
                      <Box sx={{ 
                        mt: 2, 
                        bgcolor: 'rgba(255, 255, 255, 0.05)', 
                        p: 2, 
                        borderRadius: 2 
                      }}>
                        <Typography variant="subtitle2" color="#7c4dff">
                          Explanation:
                        </Typography>
                        <Typography variant="body2" color="rgba(255, 255, 255, 0.9)">
                          {question.explanation}
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              );
            })}

            {!quizState.results?.passed && (
              <Box sx={{ mb: 4 }}>
                <Button
                  variant="outlined"
                  onClick={handleRetryQuiz}
                  startIcon={<PlayIcon />}
                  fullWidth
                  sx={{ 
                    color: '#7c4dff',
                    borderColor: '#7c4dff',
                    '&:hover': {
                      borderColor: '#6c3fff',
                      backgroundColor: 'rgba(124, 77, 255, 0.1)'
                    }
                  }}
                >
                  Retry Quiz
                </Button>
              </Box>
            )}

            {/* Attempt History Section */}
            {quizState.attempts.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom color="#ffffff">
                  Attempt History
                </Typography>
                {quizState.attempts.map((attempt) => (
                  <Card 
                    key={attempt.id} 
                    sx={{ 
                      mb: 1,
                      bgcolor: '#2d2d2d',
                      color: '#ffffff'
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography color="#ffffff">
                          Attempt {attempt.attempt_number}:
                          <span style={{ 
                            color: attempt.passed ? '#4caf50' : '#f44336',
                            marginLeft: '8px'
                          }}>
                            {attempt.score}%
                          </span>
                        </Typography>
                        <Typography variant="caption" color="rgba(255, 255, 255, 0.7)">
                          {new Date(attempt.created_at).toLocaleString()}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const renderContent = () => {
    switch (content?.content_type) {
      case 'TEXT':
        return renderTextContent();
      case 'VIDEO':
        return renderVideoContent();
      case 'QUIZ':
        return renderQuizContent();
      default:
        return (
          <Typography color="error" sx={{ p: 2 }}>
            Unsupported content type: {content?.content_type}
          </Typography>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          bgcolor: '#1a1a1a',
          color: '#ffffff',
          height: '100%'
        }
      }}
    >
      <AppBar sx={{ position: 'relative', bgcolor: '#2d2d2d' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ ml: 2, flex: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
            {content?.content_type === 'VIDEO' && <VideoIcon sx={{ color: '#7c4dff' }} />}
            {content?.content_type === 'TEXT' && <ArticleIcon sx={{ color: '#7c4dff' }} />}
            {content?.content_type === 'QUIZ' && <QuizIcon sx={{ color: '#7c4dff' }} />}
            <Typography variant="h6" component="div">
              {content?.title || content?.content_title}
            </Typography>
            {currentCompletionStatus && (
              <Tooltip title="Content completed">
                <CheckCircleIcon sx={{ color: '#4caf50' }} />
              </Tooltip>
            )}
          </Box>
          {!currentCompletionStatus && content?.content_type !== 'QUIZ' && (
            <Button 
              autoFocus 
              color="inherit"
              onClick={onComplete}
            >
              Mark Complete
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <DialogContent 
        sx={{ 
          bgcolor: '#1a1a1a',
          p: 0,
          ...(content?.content_type === 'VIDEO' && {
            p: 0,
            height: isMobile ? '100vh' : '600px',
            overflow: 'hidden'
          })
        }}
      >
        {quizState.error && (
          <Alert 
            severity="error" 
            sx={{ 
              m: 2,
              backgroundColor: 'rgba(211, 47, 47, 0.1)',
              color: '#ffffff'
            }} 
            onClose={() => setQuizState(prev => ({ ...prev, error: null }))}
          >
            {quizState.error}
          </Alert>
        )}

        {!content ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress sx={{ color: '#7c4dff' }} />
          </Box>
        ) : (
          <Box sx={{ pb: isMobile && content?.content_type === 'QUIZ' ? 8 : 0 }}>
            {renderContent()}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

ContentViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.shape({
    content_id: PropTypes.number,
    content_type: PropTypes.oneOf(['TEXT', 'VIDEO', 'QUIZ']),
    title: PropTypes.string,
    content_title: PropTypes.string,
    markdown_content: PropTypes.string,
    video_url: PropTypes.string,
    is_youtube: PropTypes.bool,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        question_text: PropTypes.string.isRequired,
        question_image: PropTypes.string,
        question_video_url: PropTypes.string,
        explanation: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            option_text: PropTypes.string.isRequired,
            option_image: PropTypes.string,
            is_correct: PropTypes.bool
          })
        ).isRequired
      })
    ),
    flowId: PropTypes.number,
    itemId: PropTypes.number
  }),
  onComplete: PropTypes.func.isRequired,
  currentCompletionStatus: PropTypes.bool,
  previousContentCompleted: PropTypes.bool
};

ContentViewer.defaultProps = {
  currentCompletionStatus: false,
  previousContentCompleted: true
};

export default ContentViewer;