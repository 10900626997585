import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Tabs,
  Tab
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { getCourseMonthlyAnalytics, debugCourseMonthlyAnalytics } from '../api';

const CourseAnalyticsPage = () => {
  const [analytics, setAnalytics] = useState(null);
  const [debugData, setDebugData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState('new');
  const [filters, setFilters] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    months_back: 2
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const [analyticsResponse, debugResponse] = await Promise.all([
        getCourseMonthlyAnalytics(filters),
        debugCourseMonthlyAnalytics()
      ]);
      setAnalytics(analyticsResponse.data);
      setDebugData(debugResponse.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch analytics data');
      console.error('Error fetching analytics:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const handleFilterChange = (field) => (event) => {
    setFilters(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const groupStudentsByName = (students) => {
    const groupedStudents = {};
    students.forEach(student => {
      if (!groupedStudents[student.student_name]) {
        groupedStudents[student.student_name] = {
          student_name: student.student_name,
          courses: []
        };
      }
      groupedStudents[student.student_name].courses.push({
        course_name: student.course_name,
        start_date: student.start_date
      });
    });
    return Object.values(groupedStudents);
  };

  const renderSummaryCard = () => (
    <Card sx={{ mb: 3, bgcolor: 'background.paper' }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" gutterBottom>Date Range</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {analytics?.summary.date_range.start} to {analytics?.summary.date_range.end}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="subtitle2" gutterBottom>Total Statistics</Typography>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              <Chip 
                label={`${analytics?.summary.totals.new_students || 0} New Students`} 
                color="primary"
              />
              <Chip 
                label={`${analytics?.summary.totals.reassignments || 0} Reassignments`}
                color="warning" 
              />
              <Chip 
                label={`${analytics?.summary.totals.group_assignments || 0} Group Assignments`}
                color="info" 
              />
            </Box>
          </Grid>
          {debugData && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>System Overview</Typography>
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <Chip 
                  label={`${debugData.courses.total_count} Total Courses`} 
                  variant="outlined"
                />
                <Chip 
                  label={`${debugData.courses.personal_courses_count} Personal Courses`} 
                  variant="outlined"
                />
                <Chip 
                  label={`${debugData.courses.group_courses_count} Group Courses`} 
                  variant="outlined"
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );

  const renderStudentsTable = (students, type) => {
    if (type === 'group') {
      const groupedStudents = groupStudentsByName(students);
      
      return (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="5%">#</TableCell>
                <TableCell>Student</TableCell>
                <TableCell>Courses</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedStudents.map((student, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{student.student_name}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      {student.courses.map((course, courseIndex) => (
                        <Box key={courseIndex}>
                          <Typography variant="body2">
                            {course.course_name} - Started {new Date(course.start_date).toLocaleDateString()}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="5%">#</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Student</TableCell>
              <TableCell>Start Date</TableCell>
              {type === 'personal' && <TableCell>Trainer</TableCell>}
              {type === 'returning' && <TableCell>Course Type</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{student.course_name}</TableCell>
                <TableCell>{student.student_name}</TableCell>
                <TableCell>{new Date(student.start_date).toLocaleDateString()}</TableCell>
                {type === 'personal' && (
                  <TableCell>{student.trainer_name || 'Not Assigned'}</TableCell>
                )}
                {type === 'returning' && (
                  <TableCell>
                    <Chip 
                      label={student.is_group ? 'Group' : 'Personal'} 
                      size="small"
                      color={student.is_group ? 'primary' : 'secondary'}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMonthlyData = (monthData) => (
    <Accordion 
      key={`${monthData.year}-${monthData.month}`}
      sx={{ mb: 2, bgcolor: 'background.paper' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
          <Typography variant="h6">
            {monthData.month_name} {monthData.year}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Chip 
              label={`${monthData.new_students.total} New`}
              color="primary"
              size="small"
            />
            <Chip 
              label={`${monthData.returning_students.total} Returning`}
              color="secondary"
              size="small"
            />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: '100%', mb: 3 }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab value="new" label="New Students" />
            <Tab value="returning" label="Returning Students" />
            <Tab value="reassignments" label="Reassignments" />
          </Tabs>
        </Box>

        {currentTab === 'new' && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Personal Courses ({monthData.new_students.personal_courses.count})
                  </Typography>
                  {renderStudentsTable(monthData.new_students.personal_courses.details, 'personal')}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Group Courses ({monthData.new_students.group_courses.count})
                  </Typography>
                  {renderStudentsTable(monthData.new_students.group_courses.details, 'group')}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        {currentTab === 'returning' && (
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Returning Students ({monthData.returning_students.total})
              </Typography>
              {renderStudentsTable(monthData.returning_students.details, 'returning')}
            </CardContent>
          </Card>
        )}

        {currentTab === 'reassignments' && (
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Trainer Reassignments ({monthData.trainer_reassignments.count})
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width="5%">#</TableCell>
                      <TableCell>Course</TableCell>
                      <TableCell>Student</TableCell>
                      <TableCell>Previous Trainer</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {monthData.trainer_reassignments.details.map((reassign, i) => (
                      <TableRow key={i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{reassign.course_name}</TableCell>
                        <TableCell>{reassign.student_name}</TableCell>
                        <TableCell>{reassign.old_trainer || 'None'}</TableCell>
                        <TableCell>{new Date(reassign.date).toLocaleDateString()}</TableCell>
                        <TableCell>{reassign.reason}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )}
      </AccordionDetails>
    </Accordion>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'white', mb: 4 }}>
        Course Assignment Analytics
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Year</InputLabel>
            <Select
              value={filters.year}
              label="Year"
              onChange={handleFilterChange('year')}
              sx={{ bgcolor: 'background.paper' }}
            >
              {[...Array(5)].map((_, i) => (
                <MenuItem key={i} value={new Date().getFullYear() - i}>
                  {new Date().getFullYear() - i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Month</InputLabel>
            <Select
              value={filters.month}
              label="Month"
              onChange={handleFilterChange('month')}
              sx={{ bgcolor: 'background.paper' }}
            >
              {[...Array(12)].map((_, i) => (
                <MenuItem key={i} value={i + 1}>
                  {new Date(2000, i).toLocaleString('default', { month: 'long' })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Months Back</InputLabel>
            <Select
              value={filters.months_back}
              label="Months Back"
              onChange={handleFilterChange('months_back')}
              sx={{ bgcolor: 'background.paper' }}
            >
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <MenuItem key={value} value={value}>
                  {value === 0 ? 'Current Month Only' : `${value} Months`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {renderSummaryCard()}
      
      {analytics?.analytics.map(renderMonthlyData)}
    </Box>
  );
};

export default CourseAnalyticsPage