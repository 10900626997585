import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const CollapsibleTabs = ({ 
  activeTab, 
  onTabChange, 
  onNewStory, 
  currentUser 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const componentRef = useRef(null);
  
  const handleTabClick = (tab) => {
    onTabChange(tab);
  };

  // Handle clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const TabLabel = () => (
    <Typography
      sx={{
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg)',
        textTransform: 'uppercase',
        fontSize: '0.75rem',
        letterSpacing: '0.1em',
        fontWeight: 600,
        color: '#4A90E2',
        userSelect: 'none'
      }}
    >
      {activeTab === 'stories' ? 'Stories' : 'Debates'}
    </Typography>
  );

  return (
    <Box
      ref={componentRef}
      sx={{
        position: 'fixed',
        right: isExpanded ? 0 : '-160px',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1100,
        transition: 'right 0.3s ease',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {/* Collapsed State - Single Tab Label */}
      {!isExpanded && (
        <Box
          sx={{
            position: 'absolute',
            right: '160px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#0B1537',
            borderRadius: '8px 0 0 8px',
            minWidth: '32px',
            height: '120px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            boxShadow: '0 0 10px rgba(74, 144, 226, 0.3)',
            border: '1px solid rgba(74, 144, 226, 0.5)',
            borderRight: 'none',
            '&:hover': {
              backgroundColor: 'rgba(11, 21, 55, 0.9)'
            }
          }}
          onClick={() => handleTabClick(activeTab === 'debates' ? 'stories' : 'debates')}
        >
          <TabLabel />
          {currentUser && activeTab === 'stories' && (
            <Box
              onClick={(e) => {
                e.stopPropagation();
                onNewStory();
              }}
              sx={{
                position: 'absolute',
                top: '-8px',
                right: '-8px',
                backgroundColor: '#4A90E2',
                color: 'white',
                borderRadius: '50%',
                width: '24px',
                height: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                border: '2px solid #0B1537',
                '&:hover': {
                  backgroundColor: '#357abd'
                }
              }}
            >
              +
            </Box>
          )}
        </Box>
      )}

      {/* Expand/Collapse Toggle */}
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          minWidth: 'auto',
          width: '24px',
          height: '80px',
          borderRadius: '8px 0 0 8px',
          backgroundColor: '#ef5350', // Changed to red
          color: 'white',
          '&:hover': {
            backgroundColor: '#d32f2f' // Darker red on hover
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 2px 8px rgba(0,0,0,0.2)'
        }}
      >
        {isExpanded ? <ChevronRight /> : <ChevronLeft />}
      </Button>

      {/* Expanded Panel */}
      <Box
        sx={{
          width: '160px',
          backgroundColor: '#0B1537',
          borderRadius: '8px 0 0 8px',
          padding: '12px',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          boxShadow: '-2px 0 8px rgba(0,0,0,0.2)'
        }}
      >
        <Button
          onClick={() => handleTabClick('debates')}
          sx={{
            color: 'white',
            backgroundColor: activeTab === 'debates' ? '#4A90E2' : 'transparent',
            borderRadius: '6px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            px: 2,
            '&:hover': {
              backgroundColor: activeTab === 'debates' ? '#4A90E2' : 'rgba(74, 144, 226, 0.1)'
            }
          }}
        >
          Debates
        </Button>

        <Button
          onClick={() => handleTabClick('stories')}
          sx={{
            color: 'white',
            backgroundColor: activeTab === 'stories' ? '#4A90E2' : 'transparent',
            borderRadius: '6px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            px: 2,
            '&:hover': {
              backgroundColor: activeTab === 'stories' ? '#4A90E2' : 'rgba(74, 144, 226, 0.1)'
            }
          }}
        >
          Stories
        </Button>

        {currentUser && activeTab === 'stories' && (
          <Button
            onClick={onNewStory}
            sx={{
              color: 'white',
              backgroundColor: '#4A90E2',
              borderRadius: '6px',
              textTransform: 'none',
              px: 2,
              '&:hover': {
                backgroundColor: '#357abd'
              }
            }}
          >
            Create a Story
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CollapsibleTabs;