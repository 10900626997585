// React & Router Imports
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@mui/material';
import MobileSocialPage from './pages/MobileSocialPage';
// MUI Core Imports
import { ThemeProvider, styled } from '@mui/material/styles';
import CourseAssignmentTrackingPage from './pages/CourseAssignmentTrackingPage';

import {
  Box,
  Container,
  CircularProgress,
  Button,
  IconButton,
  CssBaseline
} from '@mui/material';

// MUI Date Imports
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Icon Imports
import { MessageCircle } from 'lucide-react';

// Page Imports
import LoginPage from './LoginPage';
import AdminDashboard from './pages/AdminDashboard';
import TrainerDashboard from './pages/TrainerDashboard';
import StudentDashboard from './pages/StudentDashboard';
import MobileStudentDashboard from './pages/MobileStudentDashboard';
import ManagersDashboard from './pages/ManagersDashboard';
import CoursesPage from './pages/CoursesPage';
import StudentsPage from './pages/StudentsPage';
import TrainersPage from './pages/TrainersPage';
import CoordinatorsPage from './pages/CoordinatorsPage';
import TeamUpdatesPage from './pages/TeamUpdatesPage';
import ClassRecordingsPage from './pages/ClassRecordingsPage';
import MyEmploymentPage from './pages/MyEmploymentPage';
import AllotmentPage from './pages/AllotmentPage';
import CourseCompletionPage from './pages/CourseCompletionPage';
import StudentDetails from './pages/StudentDetails';
import StudentDetailsBig from './pages/StudentDetailsBig';
import CourseManagementPage from './pages/CourseManagement';
import CompetitionsPage from './pages/CompetitionsPage';
import StudentCompetitionsPage from './pages/StudentCompetitionsPage';
import Chatbot from './pages/Chatbot';
import VocabAdmin from './Chat/VocabAdmin';
import PolicyManagementPage from './pages/PolicyManagementPage';
import CourseAnalyticsPage from './pages/CourseAnalyticsPage';
import SocialPage from './pages/SocialPage';

// Component Imports
import TopNavBar from './components/TopNavBar';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import CourseFlowProgress from './components/CourseFlowProgress';
import CompetitionQuiz from './components/CompetitionQuiz';
import MessagingPage from './pages/MessagingPage';
import AnalyticsPage from './pages/AnalyticsPage';
import RightTrainerNav from './components/RightTrainerNav';
import { BarChart as BarChartIcon } from '@mui/icons-material';


// API & Utility Imports
import { getCurrentUser, startTokenValidityCheck, checkTokenValidity } from './api';
import customDarkTheme from './theme';

// Constants
const DRAWER_WIDTH = 300;
const COLLAPSED_DRAWER_WIDTH = 64;

// Initialize token validity check
startTokenValidityCheck();

// Styled Components
const StyledContainer = styled('div', { 
  shouldForwardProp: (prop) => prop !== 'open' 
})(({ theme, open, isMobileStudent }) => ({
  width: '100%',
  minHeight: '100vh',
  background: '#0f172a',
  backgroundImage: 'none',
  paddingLeft: isMobileStudent ? 0 : theme.spacing(3),
  paddingRight: isMobileStudent ? 0 : theme.spacing(3),
  paddingTop: isMobileStudent ? 0 : theme.spacing(3),
  paddingBottom: isMobileStudent ? 0 : theme.spacing(3),
  position: 'relative',
  zIndex: 0,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('md')]: {
    marginLeft: isMobileStudent ? 0 : (open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH),
    width: isMobileStudent ? '100%' : `calc(100% - ${open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH}px)`,
  },
}));

// Protected Route Component
const ProtectedRoute = ({ children, allowedRoles }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userRole: null,
    loading: true,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const isValid = await checkTokenValidity();
        if (isValid) {
          const userResponse = await getCurrentUser();
          const userRole = userResponse.data.role;
          setAuthState({
            isAuthenticated: true,
            userRole: userRole,
            loading: false,
          });
        } else {
          throw new Error('Token is invalid');
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        localStorage.removeItem('token');
        setAuthState({ isAuthenticated: false, userRole: null, loading: false });
        navigate('/login', { replace: true, state: { from: location } });
      }
    } else {
      setAuthState({ isAuthenticated: false, userRole: null, loading: false });
      navigate('/login', { replace: true, state: { from: location } });
    }
  }, [navigate, location]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (authState.loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!authState.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles && !allowedRoles.includes(authState.userRole)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

// Chat Button Component
const ChatButton = ({ onClick }) => (
  <Button
    variant="contained"
    onClick={onClick}
    sx={{
      bgcolor: '#EF4444',
      color: 'white',
      borderRadius: '12px',
      padding: '10px 20px',
      boxShadow: '0 4px 20px rgba(239, 68, 68, 0.3)',
      backdropFilter: 'blur(8px)',
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      '&:hover': {
        bgcolor: '#DC2626',
        boxShadow: '0 6px 24px rgba(239, 68, 68, 0.4)',
      },
      transition: 'all 0.3s ease',
      textTransform: 'none',
      fontWeight: 500,
    }}
  >
    <MessageCircle size={20} />
    <span>Daily Vocab</span>
  </Button>
);

// Main App Content Component
const AppContent = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userRole: null,
    loading: true,
  });
  
  
  const [desktopOpen, setDesktopOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileStudent = authState.userRole === 'STUDENT' && isMobile;
  const showChatButton = authState.isAuthenticated && 
  authState.userRole === 'STUDENT' && 
  location.pathname !== '/Chatbot';

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    setAuthState({
      isAuthenticated: false,
      userRole: null,
      loading: false,
    });
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const isValid = await checkTokenValidity();
          if (isValid) {
            const userResponse = await getCurrentUser();
            setAuthState({
              isAuthenticated: true,
              userRole: userResponse.data.role,
              loading: false,
            });
          } else {
            throw new Error('Token is invalid');
          }
        } catch (error) {
          console.error('Authentication failed:', error);
          localStorage.removeItem('token');
          setAuthState({ isAuthenticated: false, userRole: null, loading: false });
          navigate('/login');
        }
      } else {
        setAuthState({ isAuthenticated: false, userRole: null, loading: false });
      }
    };

    checkAuth();
  }, [navigate]);

  if (authState.loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {authState.isAuthenticated && !isMobileStudent && (
        <TopNavBar 
          userRole={authState.userRole} 
          onLogout={handleLogout}
          desktopOpen={desktopOpen}
          setDesktopOpen={setDesktopOpen}
        />
      )}
      <StyledContainer open={desktopOpen} isMobileStudent={isMobileStudent}>
        <Routes>
          <Route path="/login" element={<LoginPage setAuthState={setAuthState} />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                {authState.userRole === 'ADMIN' ? <AdminDashboard /> :
                 authState.userRole === 'MANAGER' ? <ManagersDashboard /> :
                 authState.userRole === 'TRAINER' ? <TrainerDashboard /> :
                 authState.userRole === 'STUDENT' ? (
                   isMobile ? <MobileStudentDashboard onLogout={handleLogout} /> : <StudentDashboard />
                 ) :
                 <Navigate to="/login" replace />}
              </ProtectedRoute>
            }
          />
          <Route path="/courses" element={<ProtectedRoute><CoursesPage /></ProtectedRoute>} />
          <Route path="/allotment" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><AllotmentPage /></ProtectedRoute>} />
          <Route path="/course-progress" element={<CourseFlowProgress />} />
          <Route path="/students" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><StudentsPage /></ProtectedRoute>} />
          <Route 
  path="/policies" 
  element={
    <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}>
      <PolicyManagementPage />
    </ProtectedRoute>
  } 
/>
<Route 
  path="/course-analytics" 
  element={
    <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}>
      <CourseAnalyticsPage />
    </ProtectedRoute>
  } 
/>
<Route 
  path="/course-assign" 
  element={
    <ProtectedRoute allowedRoles={['ADMIN']}>
      <CourseAssignmentTrackingPage />
    </ProtectedRoute>
  } 
/>
          
          <Route
            path="/competitions"
            element={
              <ProtectedRoute>
                {authState.userRole === 'STUDENT' ? (
                  isMobile ? <MobileStudentDashboard activeTab={2} onLogout={handleLogout} /> : <StudentCompetitionsPage />
                ) : <CompetitionsPage />}
              </ProtectedRoute>
            }
          />
          <Route path="/competitions/:id/quiz" element={<ProtectedRoute><CompetitionQuiz /></ProtectedRoute>} />
          <Route path="/trainers" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><TrainersPage /></ProtectedRoute>} />
          <Route path="/trainers/:id" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><TrainersPage /></ProtectedRoute>} />
          <Route path="/student-details/:studentId" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}><StudentDetails /></ProtectedRoute>} />
          <Route path="/student-details-big/:studentId" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><StudentDetailsBig /></ProtectedRoute>} />
          <Route path="/course-management" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}><CourseManagementPage /></ProtectedRoute>} />
          <Route path="/course-completion" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><CourseCompletionPage /></ProtectedRoute>} />
          <Route path="/coordinators" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><CoordinatorsPage /></ProtectedRoute>} />
          <Route path="/recordings" element={<ProtectedRoute><ClassRecordingsPage /></ProtectedRoute>} />
          <Route path="/vocab-admin" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><VocabAdmin /></ProtectedRoute>} />
          <Route path="/Chatbot" element={<ProtectedRoute><Chatbot /></ProtectedRoute>} />
          <Route path="/team-updates" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}><TeamUpdatesPage /></ProtectedRoute>} />
          <Route path="reset-password/:uid/:token" element={<PasswordResetConfirm />} />
          <Route path="/my-employment" element={<MyEmploymentPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route 
  path="/mail-center" 
  element={
    <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}>
      <MessagingPage />
    </ProtectedRoute>
  } 
/>
<Route 
  path="/social-wall" 
  element={
    <ProtectedRoute>
      {isMobile ? <MobileSocialPage /> : <SocialPage />}
    </ProtectedRoute>
  } 
/>
<Route 
  path="/analytics" 
  element={
    <ProtectedRoute allowedRoles={['ADMIN']}>
      <AnalyticsPage />
    </ProtectedRoute>
  } 
/>
        </Routes>

        {showChatButton && (
          <Box
            sx={{
              position: 'fixed',
              right: { xs: '16px', sm: '24px' },
              bottom: { xs: '80px', sm: '32px' },
              zIndex: 1000,
            }}
          >
            <ChatButton onClick={() => navigate('/Chatbot')} />
          </Box>
        )}
      </StyledContainer>
    </>
  );
};

// PropTypes
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string)
};

ChatButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

TopNavBar.propTypes = {
  userRole: PropTypes.oneOf(['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT']).isRequired,
  onLogout: PropTypes.func.isRequired,
  children: PropTypes.node,
  desktopOpen: PropTypes.bool.isRequired,
  setDesktopOpen: PropTypes.func.isRequired,
};

// Main App Component
// Main App Component
const App = () => {
  return (
    <ThemeProvider theme={customDarkTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Box
          sx={{
            background: '#0f172a !important',
            backgroundImage: 'none !important',
            minHeight: '100vh',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
        />
        <Router>
          <AppContentWithNav />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

const AppContentWithNav = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userRole: null,
    loading: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const isValid = await checkTokenValidity();
          if (isValid) {
            const userResponse = await getCurrentUser();
            setAuthState({
              isAuthenticated: true,
              userRole: userResponse.data.role,
              loading: false,
            });
          } else {
            throw new Error('Token is invalid');
          }
        } catch (error) {
          console.error('Authentication failed:', error);
          localStorage.removeItem('token');
          setAuthState({ isAuthenticated: false, userRole: null, loading: false });
          navigate('/login');
        }
      } else {
        setAuthState({ isAuthenticated: false, userRole: null, loading: false });
      }
    };

    checkAuth();
  }, [navigate]);

  if (authState.loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AppContent />
      {authState.isAuthenticated && ['ADMIN', 'MANAGER'].includes(authState.userRole) && (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <RightTrainerNav />
        </Box>
      )}
    </>
  );
};

export default App;