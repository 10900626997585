import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Chip,
  IconButton,
  Tooltip,
  Paper
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  AutoStories as StoryIcon,
  Psychology as PsychologyIcon
} from '@mui/icons-material';
import { getStorySuggestions, requestNewSuggestions } from '../api';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import LoadingMessages from './LoadingMessages';

const DarkPaper = styled(Paper)({
  backgroundColor: '#2d2d2d',
  border: '1px solid #404040',
  color: '#ffffff',
});

const StyledListItem = styled(ListItem)({
  borderRadius: 4,
  '&:hover': {
    backgroundColor: '#363636',
  },
  marginBottom: '8px',
});

const StorySuggestions = ({ storyId, isVisible = true }) => {
  const [suggestions, setSuggestions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(false);

  const fetchSuggestions = async () => {
    if (!storyId) return;
    
    setLoading(true);
    setError(false);
    try {
      const response = await getStorySuggestions(storyId);
      
      if (response.data.status === 'PENDING') {
        setTimeout(fetchSuggestions, 2000);
        setSuggestions(response.data);
      } else {
        setSuggestions(response.data);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setError(true);
      enqueueSnackbar('Error fetching suggestions', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshSuggestions = async () => {
    if (!storyId) return;
    
    setRefreshing(true);
    try {
      await requestNewSuggestions(storyId);
      await fetchSuggestions();
      enqueueSnackbar('New suggestions requested successfully!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to generate new suggestions', { variant: 'error' });
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchSuggestions();
    }
  }, [storyId, isVisible]);

  if (!isVisible) return null;

  const renderMarkdownContent = (content) => {
    if (!content) return null;

    return content.split('\n\n').map((section, idx) => {
      if (section.startsWith('# ')) {
        // Main heading
        return (
          <Typography key={idx} variant="h5" sx={{ mt: 3, mb: 2, color: '#ffffff' }}>
            {section.replace('# ', '')}
          </Typography>
        );
      } else if (section.startsWith('## ')) {
        // Subheading
        return (
          <Typography key={idx} variant="h6" sx={{ mt: 2, mb: 1, color: '#ffffff' }}>
            {section.replace('## ', '')}
          </Typography>
        );
      } else if (section.startsWith('- ')) {
        // List items
        return (
          <List key={idx} sx={{ pl: 2 }}>
            {section.split('\n').map((item, i) => (
              <StyledListItem key={i}>
                <ListItemText
                  primary={
                    <Typography sx={{ color: '#ffffff' }}>
                      {item.replace('- ', '')}
                    </Typography>
                  }
                />
              </StyledListItem>
            ))}
          </List>
        );
      } else {
        // Regular paragraph
        return (
          <Typography key={idx} sx={{ mb: 2, color: '#ffffff' }}>
            {section}
          </Typography>
        );
      }
    });
  };

  const renderSuggestions = () => {
    if (loading && !suggestions) {
      return <LoadingMessages />;
    }
  
    if (!suggestions) {
      return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography sx={{ color: '#999999' }}>
            No suggestions available. Click refresh to generate new suggestions.
          </Typography>
        </Box>
      );
    }
  
    if (suggestions.status === 'PENDING') {
      return <LoadingMessages />;
    }
  
    if (suggestions.status === 'FAILED') {
      return (
        <LoadingMessages 
          status="FAILED"
          vocabularyUsed={suggestions.vocabulary_used}
        />
      );
    }

    return (
      <Box>
        <DarkPaper sx={{ p: 3, mb: 3 }}>
          {renderMarkdownContent(suggestions.content)}
        </DarkPaper>

        {suggestions.vocabulary_used?.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mb: 2, color: '#ffffff' }}>
              Vocabulary Used
            </Typography>
            <DarkPaper sx={{ p: 2, mb: 3 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {suggestions.vocabulary_used.map((word, index) => (
                  <Chip
                    key={index}
                    label={word}
                    sx={{
                      bgcolor: '#0ea5e9',
                      color: '#ffffff',
                      '&:hover': {
                        bgcolor: '#0284c7',
                      }
                    }}
                  />
                ))}
              </Box>
            </DarkPaper>
          </>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2,
        pb: 2,
        borderBottom: '1px solid #404040'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PsychologyIcon sx={{ color: '#0ea5e9' }} />
          <Typography variant="h6" sx={{ color: '#ffffff' }}>
            AI Writing Assistant
          </Typography>
        </Box>
        <Tooltip title="Get new suggestions">
          <IconButton 
            onClick={handleRefreshSuggestions}
            disabled={refreshing || loading}
            sx={{ 
              color: '#ffffff',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.1)',
              },
              '&.Mui-disabled': {
                color: '#666666',
              }
            }}
          >
            {(refreshing || loading) ? (
              <CircularProgress size={24} sx={{ color: '#0ea5e9' }} />
            ) : (
              <RefreshIcon />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {renderSuggestions()}
    </Box>
  );
};

export default StorySuggestions;