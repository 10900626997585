import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  useTheme,
  useMediaQuery,
  Fade,
  Zoom
} from '@mui/material';
import {
  Close as CloseIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
  LightbulbOutlined as TipIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const NAVBAR_HEIGHT = 64;
const MOBILE_NAVBAR_HEIGHT = 80;
const SPOTLIGHT_OFFSET = 300; // Offset for spotlight boxes

const StyledSpotlight = styled('div')({
    position: 'absolute',
    borderRadius: '8px',
    boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.75)',
    transition: 'all 0.3s ease-in-out',
    zIndex: 1301,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-35px',
      left: '-4px',
      right: '-4px',
      bottom: '-4px',
      border: '2px solid #3b82f6',
      borderRadius: '12px',
      animation: 'pulse 2s infinite'
    },
    '@keyframes pulse': {
      '0%': {
        boxShadow: '0 0 0 0 rgba(59, 130, 246, 0.4)'
      },
      '70%': {
        boxShadow: '0 0 0 10px rgba(59, 130, 246, 0)'
      },
      '100%': {
        boxShadow: '0 0 0 0 rgba(59, 130, 246, 0)'
      }
    }
  });

const TooltipCard = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  width: '400px',
  padding: theme.spacing(3),
  background: 'rgba(30, 41, 59, 0.98)',
  backdropFilter: 'blur(8px)',
  border: '1px solid rgba(59, 130, 246, 0.2)',
  borderRadius: '12px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.24)',
  zIndex: 1302,
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
    maxWidth: '400px'
  }
}));

const StepIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2)
}));

const StepDot = styled('div')(({ active, completed }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: completed ? '#3b82f6' : active ? '#60a5fa' : 'rgba(255, 255, 255, 0.3)',
  transition: 'all 0.3s ease'
}));

const NavButton = styled(Button)(({ theme }) => ({
  minWidth: '120px',
  backgroundColor: 'rgba(59, 130, 246, 0.1)',
  color: '#3b82f6',
  '&:hover': {
    backgroundColor: 'rgba(59, 130, 246, 0.2)',
  }
}));

const TipBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(59, 130, 246, 0.1)',
  borderRadius: theme.spacing(1),
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1)
}));

const tutorialSteps = [
    {
      target: '.active-debate',  // Update class name for the active debate section
      title: 'Active Debates',
      content: 'View current debate topics and participate in ongoing discussions. Each debate has a specific duration and topic to focus the conversation.',
      tip: 'Look for the "Active" badge to find current debates you can participate in.',
      position: 'right'
    },
    {
      target: '.video-upload-section', // Update class for the video upload box
      title: 'Share Your Response',
      content: 'Select your stance and record your 3 min video response to the active debate. Share your perspective with the community through video.',
      tip: 'Choose your stance (For/Against) before uploading your video response.',
      position: 'bottom'
    },
    {
      target: '.video-responses-list', // Update class for the video responses section
      title: 'Video Responses',
      content: 'Watch responses from other participants and engage with their perspectives through reactions and comments.',
      tip: 'Use reactions and comments to engage in meaningful dialogue with others.',
      position: 'top'
    },
    {
      target: '.stories-column', // Update class for the right stories column
      title: 'Stories',
      content: 'Create and share written stories with the community. Get AI-powered suggestions to enhance your writing.',
      tip: 'Click "CREATE NEW STORY" to start writing, and look out for the AI assistant\'s suggestions.',
      position: 'left'
    }
  ];

  const SocialPageTutorial = ({ onClose }) => {
    const [step, setStep] = useState(0);
    const [elementPosition, setElementPosition] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    useEffect(() => {
      const updatePosition = () => {
        const element = document.querySelector(tutorialSteps[step].target);
        if (element) {
          const rect = element.getBoundingClientRect();
          setElementPosition({
            top: rect.top,
            left: rect.left - SPOTLIGHT_OFFSET, // Adjust left position of spotlight
            width: rect.width,
            height: rect.height
          });
        }
      };
  
      updatePosition();
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition);
  
      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition);
      };
    }, [step]);


  const OFFSET_LEFT = -150;
  const getTooltipPosition = () => {
    if (!elementPosition) return {};
    const navbarOffset = isMobile ? MOBILE_NAVBAR_HEIGHT : NAVBAR_HEIGHT;
    
    const position = { top: 0, left: 0 };
    const margin = 20;
  
    // Adjust tooltip positions with the additional left offset
    switch (tutorialSteps[step].position) {
      case 'top':
        position.top = Math.max(elementPosition.top - 200 - margin + window.scrollY, navbarOffset);
        position.left = elementPosition.left + (elementPosition.width / 2) - 200 - OFFSET_LEFT;
        break;
      case 'bottom':
        position.top = elementPosition.top + elementPosition.height + margin + window.scrollY;
        position.left = elementPosition.left + (elementPosition.width / 2) - 200 - OFFSET_LEFT;
        break;
      case 'left':
        position.top = elementPosition.top + (elementPosition.height / 2) - 100 + window.scrollY;
        position.left = Math.max(20, elementPosition.left - 420 - OFFSET_LEFT);
        break;
      case 'right':
        position.top = elementPosition.top + (elementPosition.height / 2) - 100 + window.scrollY;
        position.left = Math.min(
          window.innerWidth - 420,
          elementPosition.left + elementPosition.width + margin - OFFSET_LEFT
        );
        break;
    }
  
    // Ensure tooltip doesn't go off-screen on the left
    position.left = Math.max(20, position.left);
  
    // Ensure tooltip doesn't go off-screen on the right
    position.left = Math.min(position.left, window.innerWidth - 420);
  
    return position;
  };

  const handleComplete = () => {
    localStorage.setItem('socialPageTutorialCompleted', 'true');
    onClose();
  };

  if (!elementPosition) return null;

  return (
    <Box>
      {/* Semi-transparent overlay */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(0, 0, 0, 0.05)',
          zIndex: 1300,
          pt: isMobile ? `${MOBILE_NAVBAR_HEIGHT}px` : `${NAVBAR_HEIGHT}px`
        }}
      />

      {/* Spotlight on current element */}
      <Zoom in={true}>
        <StyledSpotlight
          style={{
            top: elementPosition?.top + window.scrollY - 50,
            left: elementPosition?.left,
            width: elementPosition?.width,
            height: elementPosition?.height +30 
          }}
        />
      </Zoom>

      {/* Tutorial tooltip */}
      <Fade in={true}>
        <TooltipCard
          style={getTooltipPosition()}
          elevation={24}
        >
          <Box sx={{ position: 'relative' }}>
            <IconButton
              onClick={handleComplete}
              sx={{
                position: 'absolute',
                right: -16,
                top: -16,
                color: 'rgba(255, 255, 255, 0.7)'
              }}
            >
              <CloseIcon />
            </IconButton>

            <StepIndicator>
              {tutorialSteps.map((_, index) => (
                <StepDot
                  key={index}
                  active={index === step}
                  completed={index < step}
                />
              ))}
            </StepIndicator>

            <Typography variant="h6" gutterBottom sx={{ color: '#3b82f6' }}>
              {tutorialSteps[step].title}
            </Typography>

            <Typography variant="body1" sx={{ color: 'white', mb: 2 }}>
              {tutorialSteps[step].content}
            </Typography>

            <TipBox>
              <TipIcon sx={{ color: '#3b82f6' }} />
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {tutorialSteps[step].tip}
              </Typography>
            </TipBox>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <NavButton
                onClick={() => setStep(step - 1)}
                disabled={step === 0}
                startIcon={<ArrowBackIcon />}
              >
                Previous
              </NavButton>

              {step === tutorialSteps.length - 1 ? (
                <NavButton
                  onClick={handleComplete}
                  endIcon={<CheckCircleIcon />}
                  sx={{
                    backgroundColor: '#3b82f6',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#2563eb',
                    }
                  }}
                >
                  Complete
                </NavButton>
              ) : (
                <NavButton
                  onClick={() => setStep(step + 1)}
                  endIcon={<ArrowForwardIcon />}
                >
                  Next
                </NavButton>
              )}
            </Box>
          </Box>
        </TooltipCard>
      </Fade>
    </Box>
  );
};

export default SocialPageTutorial;