import React, { useState } from 'react';
import { Card, Typography, Box, IconButton, Button, Dialog, DialogTitle, 
         DialogContent, DialogActions, Chip, Stack } from '@mui/material';
import {
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Star as StarIcon,
  Comment as CommentIcon,
  Reply as ReplyIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  ReadMore as ReadMoreIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';

// Helper function to extract first image from HTML content
const extractFirstImage = (htmlContent) => {
  const div = document.createElement('div');
  div.innerHTML = htmlContent;
  const img = div.querySelector('img');
  return img ? img.src : null;
};

// Helper function to get text excerpt
const getExcerpt = (htmlContent) => {
  // Remove HTML tags and decode entities
  const div = document.createElement('div');
  div.innerHTML = htmlContent;
  const text = div.textContent || div.innerText;
  
  // Get first 50 words
  const words = text.trim().split(/\s+/);
  if (words.length <= 50) return text;
  return words.slice(0, 50).join(' ') + '...';
};

const MobileStoryCard = ({
  story,
  currentUser,
  onEdit,
  onDelete,
  onMarkComplete,
  SocialInteractions,
  showComments,
  setShowComments,
  handleAddReaction,
  handleRemoveReaction,
  handleCreateComment,
  handleUpdateComment,
  handleDeleteComment,
  handleReplyToComment,
  visibleComments,
  showAllComments,
  setShowAllComments,
  hasMoreComments
}) => {
  const [readMode, setReadMode] = useState(false);
  const [showCommentSection, setShowCommentSection] = useState(showComments);
  
  const excerpt = getExcerpt(story.content);
  const thumbnailImage = extractFirstImage(story.content);
  
  const getTimeRemaining = () => {
    if (story.status === 'COMPLETE') return null;
    
    const deadline = new Date(story.deadline);
    const now = new Date();
    const diff = deadline - now;
    
    if (diff <= 0) return { expired: true };
    
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    
    return { expired: false, days, hours };
  };

  const timeRemaining = getTimeRemaining();

  return (
    <>
      <Card sx={{ mb: 2 }} className="bg-opacity-5 backdrop-blur-lg rounded-lg border border-opacity-10 transition-transform hover:-translate-y-1">
        <Box sx={{ p: 2 }}>
          {/* Header */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 2
          }}>
            <Typography variant="h6" sx={{
              fontSize: '1.125rem',
              fontWeight: 600,
              color: 'white'
            }}>
              {story.title}
            </Typography>
            
            {(currentUser?.role === 'ADMIN' || currentUser?.id === story.user) && (
              <Box sx={{ display: 'flex', gap: 0.5 }}>
                {story.status === 'INCOMPLETE' && (
                  <IconButton 
                    size="small"
                    onClick={onMarkComplete}
                    sx={{
                      color: '#4caf50',
                      p: 0.5,
                      '&:hover': {
                        backgroundColor: 'rgba(76, 175, 80, 0.1)'
                      }
                    }}
                  >
                    <CheckCircleIcon fontSize="small" />
                  </IconButton>
                )}
                <IconButton 
                  size="small"
                  onClick={onEdit}
                  sx={{
                    color: 'white',
                    p: 0.5,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small"
                  onClick={onDelete}
                  sx={{
                    color: '#ef5350',
                    p: 0.5,
                    '&:hover': {
                      backgroundColor: 'rgba(239, 83, 80, 0.1)'
                    }
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>

          {/* Metadata */}
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="caption" sx={{
              fontSize: '0.75rem',
              color: 'rgba(255, 255, 255, 0.7)'
            }}>
              By {story.user_name}
            </Typography>
            <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.3)' }}>•</Typography>
            <Typography variant="caption" sx={{
              fontSize: '0.75rem',
              color: 'rgba(255, 255, 255, 0.7)'
            }}>
              {dayjs(story.created_at).fromNow()}
            </Typography>
            <Chip 
              label={story.status}
              size="small"
              color={story.status === 'COMPLETE' ? "success" : "warning"}
              sx={{ height: '24px' }}
            />
            {story.status === 'INCOMPLETE' && timeRemaining && !timeRemaining.expired && (
              <Chip
                size="small"
                color="info"
                label={`${timeRemaining.days}d ${timeRemaining.hours}h left`}
                sx={{ height: '24px' }}
              />
            )}
          </Stack>

          {/* Thumbnail Image (if exists) */}
          {thumbnailImage && (
            <Box sx={{ mb: 2, borderRadius: 1, overflow: 'hidden' }}>
              <img 
                src={thumbnailImage} 
                alt="Story thumbnail"
                style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover'
                }}
              />
            </Box>
          )}

          {/* Excerpt */}
          <Typography 
            variant="body2"
            sx={{
              mb: 2,
              fontSize: '0.875rem',
              lineHeight: 1.6,
              color: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            {excerpt}
          </Typography>

          {/* Read More Button */}
          <Button
            startIcon={<ReadMoreIcon />}
            onClick={() => setReadMode(true)}
            sx={{
              color: 'white',
              textTransform: 'none',
              mb: 2,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            Read More
          </Button>

          {/* Social Interactions */}
          <SocialInteractions
            type="story"
            item={story}
            showComments={showCommentSection}
            onToggleComments={() => {
              setShowCommentSection(!showCommentSection);
              setShowComments(!showComments);
            }}
            onReact={handleAddReaction}
            onRemoveReaction={handleRemoveReaction}
            onComment={handleCreateComment}
            onUpdateComment={handleUpdateComment}
            onDeleteComment={handleDeleteComment}
            onReply={handleReplyToComment}
            currentUser={currentUser}
            visibleComments={visibleComments}
            showAllComments={showAllComments}
            setShowAllComments={setShowAllComments}
            hasMoreComments={hasMoreComments}
          />
        </Box>
      </Card>

      {/* Reading Mode Dialog */}
      <Dialog
        fullScreen
        open={readMode}
        onClose={() => setReadMode(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#0A0F24',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle sx={{
          backgroundColor: '#0B1537',
          color: 'white',
          position: 'sticky',
          top: 0,
          zIndex: 1100
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setReadMode(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flex: 1 }}>
              {story.title}
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ p: 2 }}>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="caption">
              By {story.user_name}
            </Typography>
            <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.3)' }}>•</Typography>
            <Typography variant="caption">
              {dayjs(story.created_at).fromNow()}
            </Typography>
            <Chip 
              label={story.status}
              size="small"
              color={story.status === 'COMPLETE' ? "success" : "warning"}
            />
          </Stack>

          <Typography
            variant="body1"
            component="div"
            sx={{
              fontSize: '1rem',
              lineHeight: 1.8,
              color: 'rgba(255, 255, 255, 0.9)'
            }}
            dangerouslySetInnerHTML={{ __html: story.content }}
          />

          <Box sx={{ mt: 4 }}>
            <SocialInteractions
              type="story"
              item={story}
              showComments={showCommentSection}
              onToggleComments={() => {
                setShowCommentSection(!showCommentSection);
                setShowComments(!showComments);
              }}
              onReact={handleAddReaction}
              onRemoveReaction={handleRemoveReaction}
              onComment={handleCreateComment}
              onUpdateComment={handleUpdateComment}
              onDeleteComment={handleDeleteComment}
              onReply={handleReplyToComment}
              currentUser={currentUser}
              visibleComments={visibleComments}
              showAllComments={showAllComments}
              setShowAllComments={setShowAllComments}
              hasMoreComments={hasMoreComments}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MobileStoryCard;