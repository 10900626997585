import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Paper,
  CircularProgress,
} from '@mui/material';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  CheckCircle as CheckCircleIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/material/styles';
import StoryEditorTutorial from './StoryEditorTutorial';
import StorySuggestions from './StorySuggestions';

const FullScreenDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: '90vw',
    height: '90vh',
    margin: '20px',
    backgroundColor: '#1a1a1a',
  },
}));

const EditorContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& .quill': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: 'calc(100vh - 280px)',
  },
  '& .ql-toolbar': {
    border: '1px solid #404040',
    borderRadius: '4px 4px 0 0',
    background: '#2d2d2d',
    '& .ql-stroke': {
      stroke: '#ffffff',
    },
    '& .ql-fill': {
      fill: '#ffffff',
    },
    '& .ql-picker': {
      color: '#ffffff',
    },
    '& .ql-picker-options': {
      backgroundColor: '#2d2d2d',
      border: '1px solid #404040',
    },
  },
  '& .ql-container': {
    flex: 1,
    overflow: 'auto',
    border: '1px solid #404040',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    backgroundColor: '#2d2d2d',
    '&.ql-snow': {
      border: '1px solid #404040',
      borderTop: 'none',
    },
  },
  '& .ql-editor': {
    minHeight: '100%',
    fontSize: '1.1rem',
    lineHeight: '1.5',
    color: '#ffffff',
    '&.ql-blank::before': {
      color: '#666666',
    },
  },
});

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: '#ffffff',
    backgroundColor: '#2d2d2d',
    '& fieldset': {
      borderColor: '#404040',
    },
    '&:hover fieldset': {
      borderColor: '#666666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0ea5e9',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#999999',
  },
});

const SidePanel = styled(Box)(({ theme }) => ({
  width: 380,
  backgroundColor: '#1a1a1a',
  borderLeft: '1px solid #404040',
  overflow: 'auto',
}));

const ActionButton = styled(Button)(({ theme, variant }) => ({
  minWidth: '120px',
  marginLeft: theme.spacing(1),
  backgroundColor: variant === 'publish' ? '#22c55e' : '#0ea5e9',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: variant === 'publish' ? '#16a34a' : '#0284c7',
  },
  '&:disabled': {
    backgroundColor: '#404040',
    color: '#666666',
  },
}));

const StoryEditorDialog = ({
  open,
  onClose,
  onSave,
  initialTitle = '',
  initialContent = '',
  storyId = null,
  isNewStory = true
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [content, setContent] = useState(initialContent);
  const [isSaving, setIsSaving] = useState(false);
  const [showTutorial, setShowTutorial] = useState(() => {
    return !localStorage.getItem('storyEditorTutorialComplete');
  });

  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      ['link', 'blockquote'],
      [{ 'align': [] }],
      ['clean']
    ]
  };

  const handleSave = async (status = 'INCOMPLETE') => {
    if (!title.trim() || !content.trim()) {
      return;
    }

    setIsSaving(true);
    try {
      await onSave({
        id: storyId,
        title,
        content,
        status,
      });
      if (status === 'COMPLETE') {
        onClose();
      }
    } catch (error) {
      console.error('Error saving story:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    if (window.confirm('Are you sure you want to close? Any unsaved changes will be lost.')) {
      onClose();
    }
  };

  return (
    <FullScreenDialog 
      open={open} 
      onClose={handleClose}
      maxWidth={false}
    >
      {showTutorial && (
        <StoryEditorTutorial onClose={() => setShowTutorial(false)} />
      )}

      <Box sx={{ 
        display: 'flex', 
        height: '100%',
        backgroundColor: '#1a1a1a' 
      }}>
        {/* Main Editor Area */}
        <Box sx={{ 
          flex: 1, 
          display: 'flex',
          flexDirection: 'column',
          p: 3,
          borderRight: '1px solid #404040',
          overflow: 'hidden',
        }}>
          {/* Header Section */}
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}>
            <DialogTitle sx={{ p: 0, color: '#ffffff' }}>
              {isNewStory ? 'Create New Story' : 'Edit Story'}
            </DialogTitle>
            
            <Box sx={{ display: 'flex', gap: 1 }}>
              {isSaving ? (
                <CircularProgress size={24} sx={{ mr: 2 }} />
              ) : (
                <>
                  {!isNewStory && (
                    <ActionButton
                      className="publish-button"
                      onClick={() => handleSave('COMPLETE')}
                      disabled={!title.trim() || !content.trim()}
                      startIcon={<CheckCircleIcon />}
                      variant="publish"
                    >
                      Publish
                    </ActionButton>
                  )}
                  <ActionButton
                    className="save-draft-button"
                    onClick={() => handleSave('INCOMPLETE')}
                    disabled={!title.trim() || !content.trim()}
                    startIcon={<SaveIcon />}
                    variant="save"
                  >
                    Save Draft
                  </ActionButton>
                </>
              )}
              <IconButton 
                onClick={handleClose} 
                sx={{ color: '#ffffff' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Title Input */}
          <CustomTextField
            className="story-title-input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Story Title"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
          />

          {/* Editor Container */}
          <EditorContainer className="editor-container">
            <div className="ql-toolbar-wrapper">
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                modules={quillModules}
                placeholder="Start writing your story..."
              />
            </div>
          </EditorContainer>
        </Box>

        {/* AI Assistance Sidebar */}
        <SidePanel className="ai-suggestions-panel">
          {storyId ? (
            <StorySuggestions 
              storyId={storyId}
              isVisible={true}
            />
          ) : (
            <Box sx={{ p: 3 }}>
              <Paper sx={{ 
                p: 2, 
                bgcolor: '#2d2d2d',
                border: '1px solid #404040',
                borderRadius: 1
              }}>
                <Typography sx={{ color: '#0ea5e9' }}>
                  Save your story as a draft to get AI-powered writing suggestions!
                </Typography>
              </Paper>
            </Box>
          )}
        </SidePanel>
      </Box>
    </FullScreenDialog>
  );
};

export default StoryEditorDialog;