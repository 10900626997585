import axios from 'axios';
const BASE_URL = 'https://dj.nilconnect.in'; // or your actual backend URL
const API_URL = `${BASE_URL}/`;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true, // You might not need this for token auth
  headers: {
    'Content-Type': 'application/json',
  }
});
export const BASE1_URL = 'https://dj.nilconnect.in';

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

class ChunkedUploader {
  constructor(file, chunkSize = 10 * 1024 * 1024) { // 1MB chunks by default
    this.file = file;
    this.chunkSize = chunkSize;
    this.chunkCount = Math.ceil(file.size / chunkSize);
    this.currentChunk = 0;
    this.retryAttempts = 3;
    this.retryDelay = 2000; // 2 seconds
  }

  async uploadChunk(chunk, chunkIndex, uploadId) {
    const formData = new FormData();
    formData.append('chunk', chunk);
    formData.append('chunk_index', chunkIndex);
    formData.append('total_chunks', this.chunkCount);
    formData.append('upload_id', uploadId);

    try {
      const response = await api.post('/api/social/video-responses/upload-chunk/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            // Calculate progress with higher precision
            const chunkProgress = (progressEvent.loaded / progressEvent.total) * 100;
            const overallProgress = ((chunkIndex * 100) + chunkProgress) / this.chunkCount;
            
            // Format to 2 decimal places and ensure we don't exceed 99.99%
            // (save 100% for when processing is complete)
            const formattedProgress = Math.min(parseFloat(overallProgress.toFixed(2)), 99.99);
            
            if (this.onProgress) {
              this.onProgress(formattedProgress);
            }
          }
        }
      });
      return response.data;
    } catch (error) {
      if (this.retryAttempts > 0) {
        this.retryAttempts--;
        await new Promise(resolve => setTimeout(resolve, this.retryDelay));
        return this.uploadChunk(chunk, chunkIndex, uploadId);
      }
      throw error;
    }
  }

  async start(metadata) {
    try {
      // Initialize upload and get upload ID
      const initResponse = await api.post('/api/social/video-responses/init-upload/', {
        filename: this.file.name,
        total_size: this.file.size,
        chunk_size: this.chunkSize,
        total_chunks: this.chunkCount,
        ...metadata
      });

      const { upload_id } = initResponse.data;

      // Upload chunks
      while (this.currentChunk < this.chunkCount) {
        const start = this.currentChunk * this.chunkSize;
        const end = Math.min(start + this.chunkSize, this.file.size);
        const chunk = this.file.slice(start, end);

        await this.uploadChunk(chunk, this.currentChunk, upload_id);
        this.currentChunk++;
      }

      // Complete upload
      const completeResponse = await api.post('/api/social/video-responses/complete-upload/', {
        upload_id
      });

      return completeResponse.data;
    } catch (error) {
      if (this.onError) {
        this.onError(error);
      }
      throw error;
    }
  }

  onProgress(callback) {
    this.onProgress = callback;
  }

  onError(callback) {
    this.onError = callback;
  }

  pause() {
    this.isPaused = true;
  }

  resume() {
    this.isPaused = false;
    this.start();
  }

  cancel() {
    this.isCancelled = true;
  }
}

export default ChunkedUploader;