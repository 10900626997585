import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Badge,
  Tooltip,
  Popper,
  Paper,
  ClickAwayListener,
  Grow,
  Drawer,
  Divider,
  AppBar,
  Toolbar,
  useMediaQuery,
  Tabs,
  Tab,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Description as DescriptionIcon } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BookIcon from '@mui/icons-material/Book';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GroupIcon from '@mui/icons-material/Group';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import WorkIcon from '@mui/icons-material/Work';
import ForumIcon from '@mui/icons-material/Forum';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SchoolIcon from '@mui/icons-material/School';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MailIcon from '@mui/icons-material/Mail';
import { BarChart as BarChartIcon } from '@mui/icons-material';
import { SitemarkIcon } from '../CustomIcons';
import NotificationItem from './NotificationItem';
import { getTrainerTransitionNotifications } from '../api';
import { keyframes } from '@emotion/react';
import AbsenceAlertDialog from './AbsenceAlertDialog';
import TeamUpdateNotificationModal from './TeamUpdateNotificationModal';
import { 
  listNotifications, 
  markNotificationAsRead,
  listInboxMessages,
  getCurrentUser
} from '../api';
import TrainerAuditNotificationModal from './TrainerAuditNotificationModal';
import UserMessagesNotification from './UserMessagesNotification';

const DRAWER_WIDTH = 300;
const COLLAPSED_DRAWER_WIDTH = 64;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  boxShadow: 'none',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const DesktopHeader = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    display: open ? 'none' : 'flex',
    position: 'fixed',
    left: COLLAPSED_DRAWER_WIDTH,
    top: 0,
    height: 64,
    alignItems: 'center',
    padding: theme.spacing(2),
    zIndex: theme.zIndex.drawer - 1,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
);

const DesktopDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      width: open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      background: 'rgba(255, 255, 255, 0.05)',
      backdropFilter: 'blur(10px)',
      borderRight: '1px solid rgba(255, 255, 255, 0.1)',
      overflowX: 'hidden',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '& .MuiListItem-root:hover .expand-button': {
      opacity: 1,
    },
  }),
);

const MobileDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '85%',
    background: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(10px)',
    borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  minHeight: 64,
}));

const StyledListItem = styled(ListItem)(({ theme, selected, highlight }) => ({
  margin: '4px 8px',
  borderRadius: '8px',
  color: 'white',
  background: selected 
    ? 'rgba(255, 255, 255, 0.15)' 
    : highlight 
      ? 'rgba(59, 130, 246, 0.1)' 
      : 'transparent',
  '&:hover': {
    background: highlight 
      ? 'rgba(59, 130, 246, 0.2)' 
      : 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiListItemIcon-root': {
    color: highlight ? '#3b82f6' : 'white',
    minWidth: 40,
  },
  '& .MuiListItemText-primary': {
    color: highlight ? '#3b82f6' : 'white',
  }
}));

const LogoContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    display: 'flex',
    alignItems: 'center',
    opacity: open ? 1 : 0,
    transition: theme.transitions.create(['opacity', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: open ? 'auto' : 0,
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
  })
);

const NotificationPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
  width: 360,
  maxHeight: '70vh',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '95vw',
    position: 'fixed',
    left: '50% !important',
    transform: 'translateX(-50%) !important',
    top: '80px !important',
  },
}));

const MoreMenuPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
  minWidth: 250,
  backgroundColor: 'rgba(25, 25, 25, 0.95)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden', // Add this to contain child elements
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%' // Ensure paper takes full width
  },
  '& .MuiList-root': {
    padding: theme.spacing(1), // Add padding to the list
    width: '100%' // Ensure list takes full width
  },
  '& .MuiListItem-root': {
    margin: '4px 0', // Adjust margin to be vertical only
    borderRadius: '8px',
    width: 'calc(100% - 0px)' // Ensure list item fills width minus padding
  }
}));

const NotificationPaper = styled(Paper)(({ theme }) => ({
  background: 'rgba(25, 25, 25, 0.95)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

const NotificationList = styled(List)(({ theme }) => ({
  maxHeight: '50vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
  },
}));

const pulse = keyframes`
  0% { transform: scale(1); opacity: 0.7; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 0.7; }
`;

const TransitionDot = styled('div')({
  position: 'absolute',
  top: -2,
  right: -2,
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: '#FF4444',
  animation: `${pulse} 2s infinite ease-in-out`
});

const TransitionTooltip = styled(Tooltip)({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'rgba(255, 68, 68, 0.9)',
    color: 'white',
    fontSize: '0.875rem',
    padding: '8px 12px',
    borderRadius: '4px',
    maxWidth: 'none'
  }
});

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, isMobile }) => ({
    flexGrow: 1,
    marginLeft: isMobile ? 0 : (open ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH),
    paddingTop: isMobile ? 32 : 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
);

const ExpandButton = styled(IconButton)(({ theme, open }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  color: 'white',
  opacity: 1,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

// Main menu items
const mainMenuItems = [
  { text: 'Dashboard', icon: <DashboardIcon />, link: '/', roles: ['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'] },
  { text: 'Students', icon: <PeopleIcon />, link: '/students', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Trainers', icon: <SchoolIcon />, link: '/trainers', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Courses', icon: <BookIcon />, link: '/courses', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Coordinators', icon: <SupervisorAccountIcon />, link: '/coordinators', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Team Updates', icon: <GroupIcon />, link: '/team-updates', roles: ['ADMIN', 'MANAGER', 'TRAINER'] },
  { text: 'Class Recordings', icon: <VideoLibraryIcon />, link: '/recordings', roles: ['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'] },
  { text: 'My Employment', icon: <WorkIcon />, link: '/my-employment', roles: ['TRAINER'] },
  { text: 'Allotment', icon: <WorkIcon />, link: '/allotment', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Course Completion', icon: <AssignmentTurnedInIcon />, link: '/course-completion', roles: ['ADMIN', 'MANAGER'] },
  { text: 'Competitions', icon: <EmojiEventsIcon />, link: '/competitions', roles: ['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'] },
  { text: 'Syllabus', icon: <SupervisorAccountIcon />, link: '/course-management', roles: ['ADMIN', 'MANAGER'] },
  { 
    text: 'Mail Center', 
    icon: <MailIcon />, 
    link: '/mail-center', 
    roles: ['ADMIN', 'MANAGER', 'TRAINER'],
    highlight: true
  },
  {
    text: 'Social Wall',
    icon: <ForumIcon />, // Import ForumIcon from @mui/icons-material
    link: '/social-wall',
    roles: ['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'],
    highlight: true  // Makes it stand out in the menu
  }
];

// More menu items
const moreMenuItems = [
  { 
    text: 'Daily Vocab', 
    icon: <SupervisorAccountIcon />, 
    link: '/Chatbot', 
    roles: ['ADMIN', 'MANAGER', 'STUDENT'] 
  },
  { 
    text: 'VocabAdmin', 
    icon: <SupervisorAccountIcon />, 
    link: '/vocab-admin', 
    roles: ['ADMIN', 'MANAGER'] 
  },
  { 
    text: 'Policy Management', 
    icon: <DescriptionIcon />, 
    link: '/policies', 
    roles: ['ADMIN', 'MANAGER'] 
  },
  { 
    text: 'Chat Analytics', 
    icon: <BarChartIcon />, 
    link: '/analytics', 
    roles: ['ADMIN'] 
  },
  { 
    text: 'Course Analysis', 
    icon: <SchoolIcon />, 
    link: '/course-analytics', 
    roles: ['ADMIN'],
    highlight: true
  }
];

const TopNavBar = ({ userRole, onLogout, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [desktopOpen, setDesktopOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notifications, setNotifications] = useState({ unread: [], read: [] });
  const [notificationPopperOpen, setNotificationPopperOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [hasTransitionNotifications, setHasTransitionNotifications] = useState(false);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const location = useLocation();
  const notificationIconRef = useRef(null);
  const desktopNotificationIconRef = useRef(null);
  const [showAbsenceDialog, setShowAbsenceDialog] = useState(true);
  const [showTeamUpdateModal, setShowTeamUpdateModal] = useState(false);
  const [teamUpdateNotifications, setTeamUpdateNotifications] = useState([]);
  const [showAuditModal, setShowAuditModal] = useState(true);
  const [auditNotifications, setAuditNotifications] = useState([]);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);

  const REGULAR_REFRESH_INTERVAL = 10 * 60 * 1000; // 10 minutes
  const QUICK_REFRESH_INTERVAL = 10 * 1000; // 10 seconds
  
  // Track if we're in mail center
  const isInMailCenter = location.pathname === '/mail-center';

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await listInboxMessages();
        const messages = Array.isArray(response.data) ? response.data : [response.data];
        
        const unreadCount = messages.reduce((count, message) => {
          let messageUnreadCount = 0;
          
          if (!message.read_at && message.recipient?.id === currentUser?.id) {
            messageUnreadCount++;
          }
          
          if (message.replies?.length > 0) {
            const unreadReplies = message.replies.filter(reply => 
              !reply.read_at && reply.recipient?.id === currentUser?.id
            );
            messageUnreadCount += unreadReplies.length;
          }
          
          return count + (messageUnreadCount > 0 ? 1 : 0);
        }, 0);
        
        setUnreadMessageCount(unreadCount);
      } catch (error) {
        console.error('Error fetching unread messages:', error);
      }
    };

    if (currentUser?.id) {
      // Initial fetch
      fetchUnreadCount();

      // Set up appropriate interval based on location
      const intervalTime = isInMailCenter ? QUICK_REFRESH_INTERVAL : REGULAR_REFRESH_INTERVAL;
      const intervalId = setInterval(fetchUnreadCount, intervalTime);

      // Cleanup interval on unmount or location change
      return () => clearInterval(intervalId);
    }
  }, [currentUser?.id, isInMailCenter]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await getCurrentUser();
        setCurrentUser(response.data);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };
    
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await listNotifications();
        const allNotifications = Array.isArray(response.data) ? response.data : [];
        
        // Set all notifications first
        const unreadNotifs = allNotifications.filter(notification => !notification.is_read);
        const readNotifs = allNotifications.filter(notification => notification.is_read);
        
        setNotifications({
          unread: unreadNotifs,
          read: readNotifs
        });
  
        // Check for unread team update notifications
        const teamUpdateNotifs = unreadNotifs.filter(
          notif => notif.notification_type === 'NEW_UPDATE_COMMENT' || 
                   notif.notification_type === 'NEW_TEAM_UPDATE'
        );
  
        // Check for unread audit notifications - only for trainers
        if (userRole === 'TRAINER') {
          const auditNotifs = unreadNotifs.filter(
            notif => notif.notification_type === 'TRAINER_AUDIT_CREATED' ||
                     notif.notification_type === 'TRAINER_AUDIT_REMARKS'
          );
  
          if (auditNotifs.length > 0) {
            setAuditNotifications(auditNotifs);
            setShowAuditModal(true);
          }
        }
  
        // Show team update modal if there are notifications
        if (teamUpdateNotifs.length > 0) {
          setTeamUpdateNotifications(teamUpdateNotifs);
          setShowTeamUpdateModal(true);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
  
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 30000);
    return () => clearInterval(intervalId);
  }, [userRole]);

  useEffect(() => {
    const checkTransitions = async () => {
      try {
        const response = await getTrainerTransitionNotifications();
        setHasTransitionNotifications(response.data.length > 0);
      } catch (error) {
        console.error('Error checking trainer transitions:', error);
      }
    };

    checkTransitions();
    const intervalId = setInterval(checkTransitions, 300000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const hasAbsenceNotifications = notifications.unread.some(
      notification => notification.notification_type === 'ABSENCE'
    );
    
    if (hasAbsenceNotifications && (userRole === 'ADMIN' || userRole === 'MANAGER')) {
      setShowAbsenceDialog(true);
    }
  }, [notifications.unread, userRole]);

  const handleCloseAbsenceDialog = () => {
    setShowAbsenceDialog(false);
    // Mark all absence notifications as read
    const absenceNotifications = notifications.unread.filter(
      n => n.notification_type === 'ABSENCE'
    );
    absenceNotifications.forEach(notification => {
      handleNotificationRead(notification.id);
    });
  };

  const handleDesktopDrawerToggle = () => {
    setDesktopOpen(!desktopOpen);
  };

  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMoreMenuClick = (event) => {
    setMoreMenuAnchorEl(event.currentTarget);
    setMoreMenuOpen(true);
  };

  const handleMoreMenuClose = () => {
    setMoreMenuOpen(false);
  };

  const handleMoreMenuItemClick = () => {
    handleMoreMenuClose();
    handleMenuItemClick();
  };

  const handleMenuItemClick = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleNotificationClick = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
    setNotificationPopperOpen((prev) => !prev);
  };

  const handleNotificationRead = async (id) => {
    const notification = notifications.unread.find(n => n.id === id);
    if (notification) {
      try {
        await markNotificationAsRead(id);
        setNotifications(prevNotifications => ({
          unread: prevNotifications.unread.filter(n => n.id !== id),
          read: [...prevNotifications.read, notification]
        }));
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filteredMoreMenuItems = moreMenuItems.filter(item => item.roles.includes(userRole));

  const drawerContent = (isDesktop = false) => (
    <>
      <DrawerHeader>
        <LogoContainer open={!isDesktop || desktopOpen}>
          <SitemarkIcon sx={{ height: 32, width: 'auto' }} />
        </LogoContainer>
        {isDesktop && (
          <ExpandButton
            onClick={handleDesktopDrawerToggle}
            className={!desktopOpen || 'visible'}
          >
            {desktopOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ExpandButton>
        )}
      </DrawerHeader>
      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
      <List>
        {mainMenuItems.filter(item => item.roles.includes(userRole)).map((item) => {
          const isCourseCompletion = item.link === '/course-completion';
          const isMailCenter = item.link === '/mail-center';
          
          return (
            <TransitionTooltip
              key={item.text}
              title={
                isCourseCompletion && hasTransitionNotifications
                  ? "Trainers are getting free soon! Click to view details"
                  : (isDesktop && !desktopOpen ? item.text : '')
              }
              placement="right"
              arrow
              open={isCourseCompletion && hasTransitionNotifications ? undefined : false}
            >
              <StyledListItem
                button
                component={Link}
                to={item.link}
                selected={location.pathname === item.link}
                highlight={item.highlight}
                onClick={handleMenuItemClick}
                sx={{ position: 'relative' }}
              >
                <ListItemIcon>
                  {isMailCenter ? (
                    <Badge badgeContent={unreadMessageCount} color="secondary">
                      {item.icon}
                    </Badge>
                  ) : (
                    <>
                      {item.icon}
                      {isCourseCompletion && hasTransitionNotifications && (
                        <TransitionDot />
                      )}
                    </>
                  )}
                </ListItemIcon>
                {(!isDesktop || desktopOpen) && <ListItemText primary={item.text} />}
              </StyledListItem>
            </TransitionTooltip>
          );
        })}

        {filteredMoreMenuItems.length > 0 && (
          <StyledListItem
            button
            onClick={handleMoreMenuClick}
            sx={{ position: 'relative' }}
          >
            <ListItemIcon>
              <MoreHorizIcon />
            </ListItemIcon>
            {(!isDesktop || desktopOpen) && (
              <>
                <ListItemText primary="More" />
                <KeyboardArrowRightIcon 
                  sx={{ 
                    ml: 'auto',
                    transition: 'transform 0.2s',
                    transform: moreMenuOpen ? 'rotate(90deg)' : 'none'
                  }} 
                />
              </>
            )}
          </StyledListItem>
        )}
      </List>

      {/* More Menu Popper */}
      <MoreMenuPopper
        open={moreMenuOpen}
        anchorEl={moreMenuAnchorEl}
        placement="right-start"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper sx={{ bgcolor: 'transparent' }}>
              <ClickAwayListener onClickAway={handleMoreMenuClose}>
                <List>
                  {filteredMoreMenuItems.map((item) => (
                    <StyledListItem
                      key={item.text}
                      button
                      component={Link}
                      to={item.link}
                      selected={location.pathname === item.link}
                      highlight={item.highlight}
                      onClick={handleMoreMenuItemClick}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </StyledListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </MoreMenuPopper>

      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)', mt: 'auto' }} />
      <List>
        <StyledListItem button>
          <UserMessagesNotification userRole={userRole} />
          {(!isDesktop || desktopOpen) && <ListItemText primary="Instant Messages" />}
        </StyledListItem>
        <StyledListItem
          button
          onClick={handleNotificationClick}
          ref={isDesktop ? desktopNotificationIconRef : notificationIconRef}
        >
          <ListItemIcon>
            <Badge badgeContent={notifications.unread.length} color="secondary">
              <NotificationsIcon />
            </Badge>
          </ListItemIcon>
          {(!isDesktop || desktopOpen) && <ListItemText primary="Notifications" />}
        </StyledListItem>
        <StyledListItem button onClick={onLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          {(!isDesktop || desktopOpen) && <ListItemText primary="Logout" />}
        </StyledListItem>
      </List>
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {!desktopOpen && (
        <DesktopHeader open={desktopOpen}>
          <SitemarkIcon sx={{ height: 32, width: 'auto' }} />
        </DesktopHeader>
      )}

      <StyledAppBar position="fixed">
        <Toolbar sx={{ width: '100%', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SitemarkIcon sx={{ height: 32, width: 'auto', mr: 1 }} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              component={Link}
              to="/mail-center"
              color="inherit"
              sx={{ mr: 1 }}
            >
              <Badge badgeContent={unreadMessageCount} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              color="inherit"
              onClick={handleNotificationClick}
              sx={{ mr: 1 }}
              ref={notificationIconRef}
            >
              <Badge badgeContent={notifications.unread.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton 
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleMobileDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </StyledAppBar>

      <DesktopDrawer 
        variant="permanent" 
        open={desktopOpen}
      >
        {drawerContent(true)}
      </DesktopDrawer>

      <MobileDrawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawerContent(false)}
      </MobileDrawer>

      <NotificationPopper
        open={notificationPopperOpen}
        anchorEl={isMobile ? notificationIconRef.current : desktopNotificationIconRef.current}
        placement={isMobile ? "bottom" : "right-start"}
        transition
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: window,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <NotificationPaper>
              <ClickAwayListener onClickAway={() => setNotificationPopperOpen(false)}>
                <Box>
                  <Box sx={{ px: 2, pb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      Notifications
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => setNotificationPopperOpen(false)}
                      sx={{ color: 'white' }}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>
                  
                  <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange} 
                    centered
                    sx={{
                      borderBottom: 1,
                      borderColor: 'rgba(255, 255, 255, 0.1)',
                      mb: 2,
                      '& .MuiTab-root': {
                        color: 'rgba(255, 255, 255, 0.7)',
                        '&.Mui-selected': {
                          color: 'white',
                        },
                      },
                      '& .MuiTabs-indicator': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    <Tab 
                      label={`Unread (${notifications.unread.length})`}
                      sx={{ 
                        textTransform: 'none',
                        fontSize: '0.9rem',
                      }} 
                    />
                    <Tab 
                      label={`Read (${notifications.read.length})`}
                      sx={{ 
                        textTransform: 'none',
                        fontSize: '0.9rem',
                      }}
                    />
                  </Tabs>

                  <NotificationList>
                    <Box sx={{ px: 2 }}>
                      {activeTab === 0 ? (
                        notifications.unread.length === 0 ? (
                          <Box sx={{ py: 4, textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgba(255,255,255,0.6)' }}>
                              No unread notifications
                            </Typography>
                          </Box>
                        ) : (
                          notifications.unread.map((notification) => (
                            <NotificationItem
                              key={notification.id}
                              notification={notification}
                              onMarkRead={handleNotificationRead}
                            />
                          ))
                        )
                      ) : (
                        notifications.read.length === 0 ? (
                          <Box sx={{ py: 4, textAlign: 'center' }}>
                            <Typography sx={{ color: 'rgba(255,255,255,0.6)' }}>
                              No read notifications
                            </Typography>
                          </Box>
                        ) : (
                          notifications.read.map((notification) => (
                            <NotificationItem
                              key={notification.id}
                              notification={notification}
                            />
                          ))
                        )
                      )}
                    </Box>
                  </NotificationList>
                </Box>
              </ClickAwayListener>
            </NotificationPaper>
          </Grow>
        )}
      </NotificationPopper>

      <Main open={desktopOpen} isMobile={isMobile}>
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      </Main>

      {(userRole === 'ADMIN' || userRole === 'MANAGER') && (
        <AbsenceAlertDialog 
          open={showAbsenceDialog} 
          onClose={handleCloseAbsenceDialog} 
          notifications={[...notifications.unread, ...notifications.read]}
          onMarkRead={handleNotificationRead}
        />
      )}

      {teamUpdateNotifications.length > 0 && (
        <TeamUpdateNotificationModal 
          open={showTeamUpdateModal}
          onClose={() => {
            setShowTeamUpdateModal(false);
            // Don't mark as read when just closing
          }}
          notifications={teamUpdateNotifications}
          onMarkRead={handleNotificationRead}
        />
      )}
      
      {userRole === 'TRAINER' && auditNotifications.length > 0 && (
        <TrainerAuditNotificationModal
          open={showAuditModal}
          onClose={() => setShowAuditModal(false)}
          notifications={auditNotifications}
          onMarkRead={handleNotificationRead}
        />
      )}
    </Box>
  );
};

TopNavBar.propTypes = {
  userRole: PropTypes.oneOf(['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT']).isRequired,
  onLogout: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default React.memo(TopNavBar);