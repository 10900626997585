import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  ReadMore as ReadMoreIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';

const getExcerpt = (htmlContent) => {
  const plainText = htmlContent.replace(/<[^>]+>/g, '');
  const words = plainText.trim().split(/\s+/);
  if (words.length <= 50) return plainText;
  return words.slice(0, 50).join(' ') + '...';
};

const StoryCard = ({ 
  story, 
  currentUser, 
  onEdit, 
  onDelete,
  SocialInteractions,
  showComments,
  setShowComments,
  handleAddReaction,
  handleRemoveReaction,
  handleCreateComment,
  handleUpdateComment,
  handleDeleteComment,
  handleReplyToComment,
  visibleComments,
  showAllComments,
  setShowAllComments,
  hasMoreComments 
}) => {
  const [readMode, setReadMode] = useState(false);
  const [showCommentSection, setShowCommentSection] = useState(showComments);

  const getTimeRemaining = () => {
    if (story.status === 'COMPLETE') return null;
    
    const deadline = new Date(story.deadline);
    const now = new Date();
    const diff = deadline - now;

    if (diff <= 0) return { expired: true };

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    return { expired: false, days, hours };
  };

  const timeRemaining = getTimeRemaining();
  const excerpt = getExcerpt(story.content);

  const StoryContent = ({ isExcerpt }) => (
    <Box>
      <Typography 
        variant="body1" 
        sx={{ mb: 2 }}
        dangerouslySetInnerHTML={{ 
          __html: isExcerpt ? excerpt : story.content 
        }}
      />
      {isExcerpt && story.content.length > excerpt.length && (
        <Button
          startIcon={<ReadMoreIcon />}
          onClick={() => setReadMode(true)}
          sx={{ mt: 1 }}
        >
          Read More
        </Button>
      )}
    </Box>
  );

  return (
    <>
      <Card sx={{ mb: 2 }} className="bg-opacity-5 backdrop-blur-lg rounded-lg border border-opacity-10 transition-transform hover:-translate-y-1">
        <CardHeader
          title={
            <Typography variant="h6" component="div">
              {story.title}
            </Typography>
          }
          subheader={
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption">
                By {story.user_name}
              </Typography>
              <Typography variant="caption">•</Typography>
              <Typography variant="caption">
                {dayjs(story.created_at).fromNow()}
              </Typography>
              <Typography variant="caption">•</Typography>
              <Chip 
                label={story.status}
                size="small"
                color={story.status === 'COMPLETE' ? "success" : "warning"}
              />
              {story.status === 'INCOMPLETE' && timeRemaining && !timeRemaining.expired && (
                <Tooltip title="Time remaining until deadline">
                  <Chip
                    size="small"
                    color="info"
                    label={`${timeRemaining.days}d ${timeRemaining.hours}h left`}
                  />
                </Tooltip>
              )}
            </Stack>
          }
          action={
            (currentUser?.role === 'ADMIN' || currentUser?.id === story.user) && (
              <Box>
                {((story.status === 'INCOMPLETE' && currentUser?.id === story.user) || 
                  currentUser?.role === 'ADMIN') && (
                  <Tooltip title="Edit Story">
                    <IconButton 
                      size="small" 
                      onClick={onEdit}
                      sx={{ mr: 1 }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Delete Story">
                  <IconButton 
                    size="small" 
                    onClick={onDelete}
                  >
                    <DeleteIcon sx={{ color: '#ef4444' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )
          }
        />
        <Divider />
        <CardContent>
          <StoryContent isExcerpt={true} />
          <SocialInteractions
            type="story"
            item={story}
            showComments={showCommentSection}
            onToggleComments={() => {
              setShowCommentSection(!showCommentSection);
              setShowComments(!showComments);
            }}
            onReact={handleAddReaction}
            onRemoveReaction={handleRemoveReaction}
            onComment={handleCreateComment}
            onUpdateComment={handleUpdateComment}
            onDeleteComment={handleDeleteComment}
            onReply={handleReplyToComment}
            currentUser={currentUser}
            visibleComments={visibleComments}
            showAllComments={showAllComments}
            setShowAllComments={setShowAllComments}
            hasMoreComments={hasMoreComments}
          />
        </CardContent>
      </Card>

      <Dialog 
        open={readMode} 
        onClose={() => setReadMode(false)}
        maxWidth="md"
        fullWidth
        scroll="paper"
        PaperProps={{
          sx: {
            bgcolor: '#1e1e1e', // Dark background
            color: 'white',     // White text
            minHeight: '60vh'   // Minimum height
          }
        }}
      >
        <DialogTitle sx={{ bgcolor: '#1e1e1e', color: 'white' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{story.title}</Typography>
            <IconButton onClick={() => setReadMode(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent 
          dividers
          sx={{
            bgcolor: '#1e1e1e',
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-track': {
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
              bgcolor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '4px',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.3)'
              }
            }
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption">
                By {story.user_name}
              </Typography>
              <Typography variant="caption">•</Typography>
              <Typography variant="caption">
                {dayjs(story.created_at).fromNow()}
              </Typography>
              <Chip 
                label={story.status}
                size="small"
                color={story.status === 'COMPLETE' ? "success" : "warning"}
              />
            </Stack>
          </Box>
          <StoryContent isExcerpt={false} />
          <Box sx={{ mt: 4 }}>
            <SocialInteractions
              type="story"
              item={story}
              showComments={showCommentSection}
              onToggleComments={() => {
                setShowCommentSection(!showCommentSection);
                setShowComments(!showComments);
              }}
              onReact={handleAddReaction}
              onRemoveReaction={handleRemoveReaction}
              onComment={handleCreateComment}
              onUpdateComment={handleUpdateComment}
              onDeleteComment={handleDeleteComment}
              onReply={handleReplyToComment}
              currentUser={currentUser}
              visibleComments={visibleComments}
              showAllComments={showAllComments}
              setShowAllComments={setShowAllComments}
              hasMoreComments={hasMoreComments}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ bgcolor: '#1e1e1e', color: 'white' }}>
          <Button onClick={() => setReadMode(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StoryCard;