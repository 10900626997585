import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Box, IconButton, TextField, Typography, CircularProgress } from '@mui/material';
import { Book, Search, Close as CloseIcon } from '@mui/icons-material';
import { getVocabProgressHistory } from '../api';

const VocabReferenceDrawer = ({ isOpen, onClose }) => {
  const [vocabList, setVocabList] = useState([]); // Stores all words
  const [filteredWords, setFilteredWords] = useState([]); // Stores search results
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const loadVocabulary = async () => {
      try {
        setLoading(true);
        const response = await getVocabProgressHistory();
        const words = response.data.flatMap(entry => 
          entry.block.words.map(word => ({
            ...word,
            blockOrder: entry.block.order,
            completedAt: entry.completed_at
          }))
        );
        words.sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt));
        setVocabList(words); // Store full word list
        setFilteredWords(words); // Initialize filtered words with full list
      } catch (error) {
        console.error('Error loading vocabulary:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && vocabList.length === 0) {
      loadVocabulary();
    }
  }, [isOpen]);

  // Search without making an API request
  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredWords(vocabList); // Reset to full list if search is empty
    } else {
      const lowerSearch = searchTerm.toLowerCase();
      setFilteredWords(
        vocabList.filter(word =>
          word.word.toLowerCase().includes(lowerSearch) ||
          word.english_definition.toLowerCase().includes(lowerSearch) ||
          word.hindi_definition.toLowerCase().includes(lowerSearch)
        )
      );
    }
  }, [searchTerm, vocabList]); // Runs when search term changes

  if (!isOpen) return null; // Prevents rendering when closed

  return ReactDOM.createPortal(
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        transform: isOpen ? 'translateY(0)' : 'translateY(100%)',
        transition: 'transform 0.3s ease-in-out',
        zIndex: 20000, // Ensures it appears above ShareStoryDialog
        bgcolor: '#0B1537',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        boxShadow: '0 -4px 20px rgba(0, 0, 0, 0.5)',
      }}
    >
      {/* Header */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Book sx={{ color: '#4A90E2', fontSize: 20 }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Vocabulary Reference
          </Typography>
        </Box>
        <IconButton 
          onClick={onClose}
          sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Search */}
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
        <TextField
          fullWidth
          placeholder="Search vocabulary..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <Search sx={{ color: 'rgba(255, 255, 255, 0.5)', mr: 1 }} />,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.1)' },
            },
            '& .MuiOutlinedInput-input': { color: 'white' },
          }}
        />
      </Box>

      {/* Word List */}
      <Box sx={{ height: '60vh', overflow: 'auto' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress sx={{ color: '#4A90E2' }} />
          </Box>
        ) : (
          <Box sx={{ p: 2 }}>
            {filteredWords.length > 0 ? (
              filteredWords.map((word, index) => (
                <Box
                  key={`${word.word}-${index}`}
                  sx={{
                    mb: 2,
                    p: 2,
                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.08)' },
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 500 }}>
                      {word.word}
                    </Typography>
                    <Box sx={{
                      px: 1,
                      py: 0.5,
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                      borderRadius: 1,
                    }}>
                      <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                        {word.part_of_speech}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.9)', mb: 1 }}>
                    {word.english_definition}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 1 }}>
                    {word.hindi_definition}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                    From Block #{word.blockOrder}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box sx={{ textAlign: 'center', py: 4, color: 'rgba(255, 255, 255, 0.5)' }}>
                No matching words found.
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>,
    document.body // Ensure it's rendered at the root level
  );
};

export default VocabReferenceDrawer;
