import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  Paper,
  Card,
  CardContent,
  Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAttendance, updateAttendance } from '../api';
import { formatDate } from '../utils/dateUtils';

const GlassContainer = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '15px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  padding: theme.spacing(3),
  color: theme.palette.text.primary,
}));

const ContentCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.03)',
  
  borderRadius: '10px',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  marginBottom: theme.spacing(2),
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    background: 'rgba(255, 255, 255, 0.05)',
  }
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
  '&:disabled': {
    background: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

const ScrollableContainer = styled(Box)(({ theme }) => ({
  maxHeight: '400px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
}));

const ClassContentSection = ({ studentId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [classContents, setClassContents] = useState([]);
  const [displayedContents, setDisplayedContents] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchClassContents = async () => {
      try {
        const response = await getAttendance({ student: studentId });
        const contentsWithClassContent = response.data.filter(attendance => attendance.class_content);
        setClassContents(contentsWithClassContent);
        setDisplayedContents(contentsWithClassContent.slice(0, 4)); // Show only 4 initially
      } catch (error) {
        console.error('Error fetching class contents:', error);
        setSnackbar({ open: true, message: 'Failed to fetch class contents', severity: 'error' });
      }
    };

    fetchClassContents();
  }, [studentId]);

  const handleFeedback = async (attendanceId, feedback) => {
    try {
      const attendanceToUpdate = classContents.find(content => content.id === attendanceId);
      if (!attendanceToUpdate) {
        throw new Error('Attendance record not found');
      }

      await updateAttendance(attendanceId, {
        student: studentId,
        status: attendanceToUpdate.status,
        student_feedback: feedback
      });

      const updatedContents = classContents.map(content => 
        content.id === attendanceId ? { ...content, student_feedback: feedback } : content
      );
      setClassContents(updatedContents);
      setDisplayedContents(showAll ? updatedContents : updatedContents.slice(0, 4));
      setSnackbar({ open: true, message: `Class content ${feedback.toLowerCase()}ed`, severity: 'success' });
    } catch (error) {
      console.error('Error updating feedback:', error);
      setSnackbar({ open: true, message: 'Failed to update feedback', severity: 'error' });
    }
  };

  const handleShowMore = () => {
    setShowAll(true);
    setDisplayedContents(classContents);
  };

  return (
    <GlassContainer>
      <Typography variant="h6" gutterBottom color="white">
        Class Content Updates
      </Typography>

      <ScrollableContainer>
        {displayedContents.map((content) => (
          <ContentCard key={content.id}>
            <CardContent>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={isMobile ? 'stretch' : 'center'}
                spacing={2}
              >
                <Box flex={1}>
                  <Typography variant="body2" color="white" gutterBottom>
                    {formatDate(content.timestamp)}
                  </Typography>
                  <Typography variant="body1" color="white" gutterBottom>
                    {content.class_content}
                  </Typography>
                  <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                    Status: {content.student_feedback || 'Pending'}
                  </Typography>
                </Box>

                {content.student_feedback === 'NO_ACTION' && (
                  <Stack
                    direction={isMobile ? 'column' : 'row'}
                    spacing={1}
                    sx={{ minWidth: isMobile ? '100%' : 'auto' }}
                  >
                    <GradientButton
                      onClick={() => handleFeedback(content.id, 'ACCEPTED')}
                      fullWidth={isMobile}
                      size="small"
                    >
                      Accept
                    </GradientButton>
                    <GradientButton
                      onClick={() => handleFeedback(content.id, 'REJECTED')}
                      fullWidth={isMobile}
                      size="small"
                    >
                      Reject
                    </GradientButton>
                  </Stack>
                )}
              </Stack>
            </CardContent>
          </ContentCard>
        ))}
      </ScrollableContainer>

      {!showAll && classContents.length > 4 && (
        <Box mt={2} display="flex" justifyContent="center">
          <GradientButton onClick={handleShowMore}>
            Show More
          </GradientButton>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassContainer>
  );
};

export default ClassContentSection;