import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Alert,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const DebateCreateDialog = ({ open, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    start_date: dayjs(),
    end_date: dayjs().add(7, 'days'),
    is_active: true
  });
  
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.title.trim()) {
      newErrors.title = 'Title is required';
    }
    
    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
    }
    
    if (!formData.start_date) {
      newErrors.start_date = 'Start date is required';
    }
    
    if (!formData.end_date) {
      newErrors.end_date = 'End date is required';
    }
    
    if (formData.start_date && formData.end_date && 
        formData.start_date.isAfter(formData.end_date)) {
      newErrors.end_date = 'End date must be after start date';
    }
    
    if (formData.start_date && formData.start_date.isBefore(dayjs())) {
      newErrors.start_date = 'Start date cannot be in the past';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit({
        ...formData,
        start_date: formData.start_date.toISOString(),
        end_date: formData.end_date.toISOString()
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: 'background.paper',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle>Create New Debate</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="Title"
              fullWidth
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              error={!!errors.title}
              helperText={errors.title}
            />
            
            <Box>
              <ReactQuill
                theme="snow"
                value={formData.description}
                onChange={(value) => setFormData({ ...formData, description: value })}
                style={{ height: '200px', marginBottom: '50px' }}
              />
              {errors.description && (
                <Alert severity="error" sx={{ mt: 1 }}>{errors.description}</Alert>
              )}
            </Box>
            
            <Box sx={{ display: 'flex', gap: 2 }}>
              <DateTimePicker
                label="Start Date"
                value={formData.start_date}
                onChange={(newValue) => setFormData({ ...formData, start_date: newValue })}
                sx={{ flex: 1 }}
                slotProps={{
                  textField: {
                    error: !!errors.start_date,
                    helperText: errors.start_date
                  }
                }}
              />
              
              <DateTimePicker
                label="End Date"
                value={formData.end_date}
                onChange={(newValue) => setFormData({ ...formData, end_date: newValue })}
                sx={{ flex: 1 }}
                slotProps={{
                  textField: {
                    error: !!errors.end_date,
                    helperText: errors.end_date
                  }
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            color="primary"
          >
            Create Debate
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default DebateCreateDialog;