import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  CircularProgress,
  Tooltip,
  Chip,
  Alert,
  LinearProgress,
  Collapse,
  Stack,
  Select,
  MenuItem,
  Paper,
  Container, DialogActions,DialogTitle,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import StorySuggestions from '../components/StorySuggestions';
import { Autorenew as AutorenewIcon } from '@mui/icons-material';
import StoryEditorDialog from '../components/StoryEditorDialog';
import StoryCard from '../components/StoryCard';
import SocialPageTutorial from '../components/SocialPageTutorial';
import { useTutorial } from '../components/useTutorial';
import DebateCreateDialog from '../components/DebateCreateDialog';
import ChunkedUploader from '../components/ChunkedUploader';



import { styled } from '@mui/material/styles';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Star as StarIcon,
  Comment as CommentIcon,
  Reply as ReplyIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  CheckCircle as CheckCircleIcon,
  Psychology as PsychologyIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  getCurrentUser,
  listDebateTopics,
  createDebateTopic,
  updateDebateTopic,
  deleteDebateTopic,
  listVideoResponses,
  createVideoResponse,
  updateVideoResponse,
  deleteVideoResponse,
  listStories,
  createStory,
  updateStory,
  deleteStory,
  addReaction,
  createComment,
  updateComment1,
  deleteComment1,
  replyToComment,
  removeReaction,requestNewSuggestions
} from '../api';


dayjs.extend(relativeTime);

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #3b82f6 30%, #2563eb 90%)',
  borderRadius: theme.spacing(1),
  border: 0,
  color: 'white',
  height: 36,
  padding: '0 16px',
  boxShadow: '0 3px 5px 2px rgba(59, 130, 246, .3)',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const VideoPreview = styled('video')({
  width: '100%',
  maxHeight: '400px',
  borderRadius: '8px',
  marginTop: '16px',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
});

const CommentSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.03)',
  borderRadius: theme.spacing(1),
}));

const ReplyField = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.02)',
  borderRadius: theme.spacing(1),
  border: '1px solid rgba(255, 255, 255, 0.05)',
}));

const StoryCreateBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  position: 'relative', // Add this line
  overflow: 'hidden'    // Add this line
}));

const SocialPage = () => {
  // State Management
  const [debates, setDebates] = useState([]);
  const [videos, setVideos] = useState([]);
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [replyTo, setReplyTo] = useState(null);
  const [processingVideo, setProcessingVideo] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoPreview, setVideoPreview] = useState(null);
  const [newStoryContent, setNewStoryContent] = useState('');
  const [storyTitle, setStoryTitle] = useState('');
  const [stance, setStance] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [editingStory, setEditingStory] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);
  const [storyEditorOpen, setStoryEditorOpen] = useState(false);
  const { showTutorial, completeTutorial, resetTutorial } = useTutorial();
  const [debateDialogOpen, setDebateDialogOpen] = useState(false);
  const canManageDebates = currentUser && ['ADMIN', 'MANAGER', 'TRAINER'].includes(currentUser.role);



const [editingStoryData, setEditingStoryData] = useState(null);
  // Initial Load
  useEffect(() => {
    const init = async () => {
      try {
        const userResponse = await getCurrentUser();
        setCurrentUser(userResponse.data);
        await loadContent();
      } catch (error) {
        const errorMsg = error.response?.data?.error || 'Error initializing page';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      }
    };
    init();
  }, []);

  // Permission checks
  const canCreateDebate = currentUser && ['ADMIN', 'MANAGER', 'TRAINER'].includes(currentUser.role);
  const canCreateVideo = currentUser && debates.some(d => {
    const now = new Date();
    const startDate = new Date(d.start_date);
    const endDate = new Date(d.end_date);
    return d.is_active && startDate <= now && now <= endDate;
  });
  const canCreateStory = currentUser;

  // Content Loading
  const loadContent = async () => {
    setLoading(true);
    try {
      const [debatesRes, videosRes, storiesRes] = await Promise.all([
        listDebateTopics({ ordering: '-created_at' }),
        listVideoResponses({ ordering: '-created_at' }),
        listStories({ ordering: '-created_at' })
      ]);
      
      setDebates(debatesRes.data);
      setVideos(videosRes.data);
      
      // Sort stories to keep user's incomplete stories on top
      const sortedStories = [...storiesRes.data].sort((a, b) => {
        if (a.user === currentUser?.id && a.status === 'INCOMPLETE') return -1;
        if (b.user === currentUser?.id && b.status === 'INCOMPLETE') return 1;
        return new Date(b.created_at) - new Date(a.created_at);
      });
      
      setStories(sortedStories);
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Error loading content';
      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Get active debate
  const getActiveDebate = () => {
    const now = new Date();
    return debates.find(debate => 
      debate.is_active && 
      new Date(debate.start_date) <= now && 
      now <= new Date(debate.end_date)
    );
  };

  // Debate handlers
  const handleUpdateDebate = async (debate) => {
    try {
      const payload = {
        title: debate.title,
        description: debate.description,
        start_date: dayjs(debate.start_date).toISOString(),
        end_date: dayjs(debate.end_date).toISOString(),
        is_active: debate.is_active
      };
      await updateDebateTopic(debate.id, payload);
      enqueueSnackbar('Debate updated successfully', { variant: 'success' });
      await loadContent();
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Error updating debate';
      enqueueSnackbar(errorMsg, { variant: 'error' });
    }
  };

  const handleDeleteDebate = async (id) => {
    if (window.confirm('Are you sure you want to delete this debate? This will also delete all associated video responses.')) {
      try {
        await deleteDebateTopic(id);
        enqueueSnackbar('Debate deleted successfully', { variant: 'success' });
        await loadContent();
      } catch (error) {
        const errorMsg = error.response?.data?.error || 'Error deleting debate';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      }
    }
  };

  const handleCreateDebate = async (debateData) => {
    try {
      await createDebateTopic(debateData);
      setDebateDialogOpen(false);
      enqueueSnackbar('Debate created successfully', { variant: 'success' });
      await loadContent(); // Reload the debates list
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Error creating debate';
      enqueueSnackbar(errorMsg, { variant: 'error' });
    }
  };

  // Video handlers
  const handleDeleteVideoResponse = async (id) => {
    if (window.confirm('Are you sure you want to delete this video response?')) {
      try {
        await deleteVideoResponse(id);
        setVideos(videos.filter(video => video.id !== id));
        enqueueSnackbar('Video response deleted successfully', { variant: 'success' });
      } catch (error) {
        const errorMsg = error.response?.data?.error || 'Error deleting video response';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      }
    }
  };

  // Story handlers
  const handleCreateStory = async (data) => {
    if (!data.title.trim() || !data.content.trim()) {
      enqueueSnackbar('Please enter both title and content for your story', { variant: 'error' });
      return;
    }
  
    try {
      const response = await createStory({
        content: data.content,
        title: data.title,
        status: 'INCOMPLETE',
        deadline: dayjs().add(7, 'days').toISOString()
      });
  
      setStories(prevStories => [response.data, ...prevStories]);
      setStoryEditorOpen(false);
      enqueueSnackbar('Story created successfully', { variant: 'success' });
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Error creating story';
      enqueueSnackbar(errorMsg, { variant: 'error' });
    }
  };

  const handleUpdateStory = async (story) => {
    try {
      const response = await updateStory(story.id, {
        title: story.title,
        content: story.content,
        status: story.status,
        deadline: story.deadline
      });
      
      // Update local state immediately
      setStories(prevStories => 
        prevStories.map(s => s.id === story.id ? response.data : s)
      );
      
      setEditingStory(null);
      enqueueSnackbar('Story updated successfully', { variant: 'success' });
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Error updating story';
      enqueueSnackbar(errorMsg, { variant: 'error' });
    }
  };

  const handleCompleteStory = async (story) => {
    try {
      const response = await updateStory(story.id, {
        ...story,
        status: 'COMPLETE'
      });
      
      // Update local state immediately
      setStories(prevStories => 
        prevStories.map(s => s.id === story.id ? response.data : s)
      );
      
      enqueueSnackbar('Story marked as complete', { variant: 'success' });
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Error completing story';
      enqueueSnackbar(errorMsg, { variant: 'error' });
    }
  };

  const handleDeleteStory = async (id) => {
    if (window.confirm('Are you sure you want to delete this story?')) {
      try {
        await deleteStory(id);
        // Update local state immediately
        setStories(prevStories => prevStories.filter(story => story.id !== id));
        enqueueSnackbar('Story deleted successfully', { variant: 'success' });
      } catch (error) {
        const errorMsg = error.response?.data?.error || 'Error deleting story';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      }
    }
  };

  // Reaction handlers
  const handleAddReaction = async (type, itemId, reactionType) => {
    try {
      await addReaction(type, itemId, reactionType);
      // Update local state immediately
      if (type === 'video') {
        setVideos(prevVideos => prevVideos.map(video => 
          video.id === itemId 
            ? {
                ...video,
                user_reaction: reactionType,
                reactions_count: {
                  ...video.reactions_count,
                  [`${reactionType.toLowerCase()}s`]: (video.reactions_count?.[`${reactionType.toLowerCase()}s`] || 0) + 1
                }
              }
            : video
        ));
      } else if (type === 'story') {
        setStories(prevStories => prevStories.map(story =>
          story.id === itemId
            ? {
                ...story,
                user_reaction: reactionType,
                reactions_count: {
                  ...story.reactions_count,
                  [`${reactionType.toLowerCase()}s`]: (story.reactions_count?.[`${reactionType.toLowerCase()}s`] || 0) + 1
                }
              }
            : story
        ));
      }
    } catch (error) {
      enqueueSnackbar('Error adding reaction', { variant: 'error' });
    }
  };

  const handleRemoveReaction = async (itemId) => {
    try {
      await removeReaction(itemId);
      // Update local state immediately
      setVideos(prevVideos => prevVideos.map(video =>
        video.id === itemId
          ? {
              ...video,
              user_reaction: null,
              reactions_count: {
                ...video.reactions_count,
                [`${video.user_reaction?.toLowerCase()}s`]: Math.max(
                  0,
                  (video.reactions_count?.[`${video.user_reaction?.toLowerCase()}s`] || 1) - 1
                )
              }
            }
          : video
      ));
      setStories(prevStories => prevStories.map(story =>
        story.id === itemId
          ? {
              ...story,
              user_reaction: null,
              reactions_count: {
                ...story.reactions_count,
                [`${story.user_reaction?.toLowerCase()}s`]: Math.max(
                  0,
                  (story.reactions_count?.[`${story.user_reaction?.toLowerCase()}s`] || 1) - 1
                )
              }
            }
          : story
      ));
    } catch (error) {
      enqueueSnackbar('Error removing reaction', { variant: 'error' });
    }
  };

  // Comment handlers
  const handleCreateComment = async (type, itemId, payload) => {
    try {
      const response = await createComment(type, itemId, payload);
      const newComment = response.data;
      
      // Update local state based on type
      if (type === 'video') {
        setVideos(prevVideos => prevVideos.map(video =>
          video.id === itemId
            ? {
                ...video,
                comments: [...(video.comments || []), newComment]
              }
            : video
        ));
      } else if (type === 'story') {
        setStories(prevStories => prevStories.map(story =>
          story.id === itemId
            ? {
                ...story,
                comments: [...(story.comments || []), newComment]
              }
            : story
        ));
      }
      return newComment;
    } catch (error) {
      enqueueSnackbar('Error creating comment', { variant: 'error' });
      throw error;
    }
  };

  const handleUpdateComment = async (commentId, content) => {
    try {
      const response = await updateComment1(commentId, { content });
      // Update local state
      const updateCommentInItems = (items) =>
        items.map(item => ({
          ...item,
          comments: (item.comments || []).map(comment =>
            comment.id === commentId
              ? { ...comment, content, is_edited: true }
              : {
                  ...comment,
                  replies: (comment.replies || []).map(reply =>
                    reply.id === commentId
                      ? { ...reply, content, is_edited: true }
                      : reply
                  )
                }
          )
        }));

      setVideos(prevVideos => updateCommentInItems(prevVideos));
      setStories(prevStories => updateCommentInItems(prevStories));
      
      return response.data;
    } catch (error) {
      enqueueSnackbar('Error updating comment', { variant: 'error' });
      throw error;
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteComment1(commentId);
      // Update local state
      const removeCommentFromItems = (items) =>
        items.map(item => ({
          ...item,
          comments: (item.comments || [])
            .filter(comment => comment.id !== commentId)
            .map(comment => ({
              ...comment,
              replies: (comment.replies || []).filter(reply => reply.id !== commentId)
            }))
        }));

      setVideos(prevVideos => removeCommentFromItems(prevVideos));
      setStories(prevStories => removeCommentFromItems(prevStories));
    } catch (error) {
      enqueueSnackbar('Error deleting comment', { variant: 'error' });
      throw error;
    }
  };

  const handleReplyToComment = async (commentId, content) => {
    try {
      const response = await replyToComment(commentId, content);
      const newReply = response.data;
      
      // Update local state
      const addReplyToItems = (items) =>
        items.map(item => ({
          ...item,
          comments: (item.comments || []).map(comment =>
            comment.id === commentId
              ? {
                  ...comment,
                  replies: [...(comment.replies || []), newReply]
                }
              : comment
          )
        }));

      setVideos(prevVideos => addReplyToItems(prevVideos));
      setStories(prevStories => addReplyToItems(prevStories));
      
      return newReply;
    } catch (error) {
      enqueueSnackbar('Error adding reply', { variant: 'error' });
      throw error;
    }
  };

  // Video upload handlers
  const handleVideoSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedVideo(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const handleVideoUpload = async () => {
    if (!selectedVideo || !stance) {
      enqueueSnackbar('Please select a video and choose your stance', { variant: 'error' });
      return;
    }
  
    const activeDebate = getActiveDebate();
    if (!activeDebate) {
      enqueueSnackbar('No active debate found', { variant: 'error' });
      return;
    }
  
    setProcessingVideo(true);
    setUploadProgress(0);
  
    try {
      const uploader = new ChunkedUploader(selectedVideo);
      
      uploader.onProgress((progress) => {
        const formattedProgress = Math.min(parseFloat(progress.toFixed(2)), 99.99);
        setUploadProgress(formattedProgress);
      });
  
      const response = await uploader.start({
        debate_topic: activeDebate.id,
        stance: stance,
        title: `${currentUser.first_name} ${currentUser.last_name}`
      });
  
      // Show processing state
      setUploadProgress(100);
  
      // After successful upload, reload the video list
      const videosRes = await listVideoResponses({ ordering: '-created_at' });
      setVideos(videosRes.data);
  
      // Reset form
      setSelectedVideo(null);
      setVideoPreview(null);
      setStance('');
      
      enqueueSnackbar('Video uploaded successfully!', { variant: 'success' });
      
      setTimeout(() => {
        setProcessingVideo(false);
        setUploadProgress(0);
      }, 1500);
  
    } catch (error) {
      console.error('Upload error:', error);
      enqueueSnackbar(error.message || 'Error uploading video', { variant: 'error' });
      setProcessingVideo(false);
      setUploadProgress(0);
    }
  };

  // Components
  const DebateCard = ({ 
    debate, 
    currentUser, 
    onUpdate, 
    onDelete,
    isAuthorized 
  }) => {
    const [showEditConfirm, setShowEditConfirm] = useState(false);
    const [editStatus, setEditStatus] = useState(debate.is_active);
  
    const now = new Date();
    const startDate = new Date(debate.start_date);
    const endDate = new Date(debate.end_date);
    
    const isActive = debate.is_active && startDate <= now && now <= endDate;
    const isPast = endDate < now;
    const isFuture = startDate > now;
  
    const getStatusColor = () => {
      if (!debate.is_active) return 'error';
      if (isPast) return 'error';
      if (isFuture) return 'warning';
      return 'success';
    };
  
    const getStatusLabel = () => {
      if (!debate.is_active) return 'Inactive';
      if (isPast) return 'Ended';
      if (isFuture) return 'Upcoming';
      return 'Active';
    };
  
    const handleStatusToggle = () => {
      setEditStatus(!editStatus);
      setShowEditConfirm(true);
    };
  
    const handleConfirmEdit = () => {
      onUpdate({
        ...debate,
        is_active: editStatus
      });
      setShowEditConfirm(false);
    };
  
    return (
      <>
        <Card sx={{ 
          mb: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        }}>
          <CardHeader
            title={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h6">{debate.title}</Typography>
                <Chip 
                  label={getStatusLabel()}
                  size="small"
                  color={getStatusColor()}
                />
              </Box>
            }
            subheader={
              <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
                <Typography variant="caption">
                  By {debate.created_by_name}
                </Typography>
                <Typography variant="caption">•</Typography>
                <Typography variant="caption">
                  {dayjs(debate.created_at).fromNow()}
                </Typography>
                {isAuthorized && (
                  <>
                    <Typography variant="caption">•</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <ScheduleIcon sx={{ fontSize: 14 }} />
                      <Tooltip title="Toggle debate active status">
                        <Switch
                          size="small"
                          checked={debate.is_active}
                          onChange={handleStatusToggle}
                        />
                      </Tooltip>
                    </Box>
                  </>
                )}
              </Stack>
            }
            action={
              isAuthorized && (
                <Box>
                  <IconButton 
                    size="small" 
                    onClick={() => onUpdate(debate)}
                    sx={{ color: 'primary.main' }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    onClick={() => onDelete(debate.id)}
                    sx={{ color: 'error.main' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )
            }
          />
          <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
          <CardContent>
            <Typography 
              variant="body1" 
              sx={{ mb: 2 }}
              dangerouslySetInnerHTML={{ __html: debate.description }}
            />
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.03)',
              borderRadius: 1,
              p: 1
            }}>
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Starts:
                </Typography>
                <Typography variant="body2">
                  {dayjs(debate.start_date).format('MMM D, YYYY h:mm A')}
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Ends:
                </Typography>
                <Typography variant="body2">
                  {dayjs(debate.end_date).format('MMM D, YYYY h:mm A')}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
  
        {/* Status Change Confirmation Dialog */}
        <Dialog open={showEditConfirm} onClose={() => setShowEditConfirm(false)}>
          <DialogTitle>
            Confirm Status Change
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to {editStatus ? 'activate' : 'deactivate'} this debate?
              {editStatus ? 
                ' Students will be able to submit responses during the specified date range.' : 
                ' Students will not be able to submit responses.'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowEditConfirm(false)}>Cancel</Button>
            <Button onClick={handleConfirmEdit} variant="contained" color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const VideoResponseCard = ({ video }) => {
    const [showComments, setShowComments] = useState(false);
    const [showAllComments, setShowAllComments] = useState(false);
    
    // Fix the video URL construction
    const videoUrl = video.original_video_url 
      ? video.original_video_url 
      : video.video_url 
        ? `https://dj.nilconnect.in${video.video_url}` 
        : '';
    
    // Show only first 2 comments by default
    const visibleComments = showAllComments ? video.comments : video.comments?.slice(0, 2);
    const hasMoreComments = video.comments?.length > 2;

    return (
      <StyledCard>
        <CardHeader
          title={video.title}
          subheader={
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption">
                By {video.user_name}
              </Typography>
              <Typography variant="caption">•</Typography>
              <Typography variant="caption">
                {dayjs(video.created_at).fromNow()}
              </Typography>
              <Typography variant="caption">•</Typography>
              <Chip 
                label={video.stance}
                size="small"
                color={video.stance === 'FOR' ? "success" : "error"}
              />
              <Typography variant="caption">•</Typography>
              <Chip 
                label={video.processing_status}
                size="small"
                color={video.processing_status === 'COMPLETED' ? "success" : 
                       video.processing_status === 'FAILED' ? "error" : "warning"}
              />
            </Stack>
          }
          action={
            (currentUser?.role === 'ADMIN' || currentUser?.id === video.user) && (
              <IconButton size="small" onClick={() => handleDeleteVideoResponse(video.id)}>
                <DeleteIcon sx={{ color: '#ef4444' }} />
              </IconButton>
            )
          }
        />
        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <video
              controls
              width="100%"
              style={{ borderRadius: '8px' }}
              src={videoUrl}
            >
              Your browser does not support the video tag.
            </video>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {video.description}
          </Typography>
          <SocialInteractions
           className="social-interactions"
            type="video"
            item={video}
            showComments={showComments}
            onToggleComments={() => setShowComments(!showComments)}
            onReact={handleAddReaction}
            onRemoveReaction={handleRemoveReaction}
            onComment={handleCreateComment}
            onUpdateComment={handleUpdateComment}
            onDeleteComment={handleDeleteComment}
            onReply={handleReplyToComment}
            currentUser={currentUser}
            visibleComments={visibleComments}
            showAllComments={showAllComments}
            setShowAllComments={setShowAllComments}
            hasMoreComments={hasMoreComments}
          />
        </CardContent>
      </StyledCard>
    );
  };

  
  
    

  const SocialInteractions = ({
    type,
    item,
    showComments,
    onToggleComments,
    onReact,
    onRemoveReaction,
    onComment,
    onUpdateComment,
    onDeleteComment,
    onReply,
    currentUser,
    visibleComments,
    showAllComments,
    setShowAllComments,
    hasMoreComments
  }) => {
    const [commentText, setCommentText] = useState('');
    const [replyText, setReplyText] = useState('');

    const handleSubmitComment = async (e) => {
      e.preventDefault();
      if (commentText.trim()) {
        try {
          const newComment = await onComment(type, item.id, { content: commentText });
          setCommentText('');
        } catch (error) {
          console.error('Error submitting comment:', error);
        }
      }
    };

    const handleSubmitReply = async (commentId) => {
      if (replyText.trim()) {
        try {
          const newReply = await onReply(commentId, replyText);
          setReplyText('');
        } catch (error) {
          console.error('Error submitting reply:', error);
        }
      }
    };

    return (
      <Box>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Tooltip title="Upvote">
            <IconButton
              size="small"
              onClick={() => item.user_reaction === 'UPVOTE' ? 
                onRemoveReaction(item.id) : 
                onReact(type, item.id, 'UPVOTE')}
              sx={{ 
                color: item.user_reaction === 'UPVOTE' ? '#22c55e' : 'white',
                '&:hover': { color: '#22c55e' }
              }}
            >
              <ThumbUpIcon />
            </IconButton>
          </Tooltip>
          <Typography sx={{ mr: 2 }}>
            {item.reactions_count?.upvotes || 0}
          </Typography>

          <Tooltip title="Downvote">
            <IconButton
              size="small"
              onClick={() => item.user_reaction === 'DOWNVOTE' ? 
                onRemoveReaction(item.id) : 
                onReact(type, item.id, 'DOWNVOTE')}
              sx={{ 
                color: item.user_reaction === 'DOWNVOTE' ? '#ef4444' : 'white',
                '&:hover': { color: '#ef4444' }
              }}
            >
              <ThumbDownIcon />
            </IconButton>
          </Tooltip>
          <Typography sx={{ mr: 2 }}>
            {item.reactions_count?.downvotes || 0}
          </Typography>

          <Tooltip title="Superlike">
            <IconButton
              size="small"
              onClick={() => item.user_reaction === 'SUPERLIKE' ? 
                onRemoveReaction(item.id) : 
                onReact(type, item.id, 'SUPERLIKE')}
              sx={{ 
                color: item.user_reaction === 'SUPERLIKE' ? '#eab308' : 'white',
                '&:hover': { color: '#eab308' }
              }}
            >
              <StarIcon />
            </IconButton>
          </Tooltip>
          <Typography sx={{ mr: 2 }}>
            {item.reactions_count?.superlikes || 0}
          </Typography>

          <Button
            startIcon={showComments ? <ExpandLessIcon /> : <CommentIcon />}
            onClick={onToggleComments}
            sx={{ color: 'white' }}
          >
            {item.comments?.length || 0} Comments
          </Button>
        </Box>

        <Collapse in={showComments}>
          <CommentSection>
            <form onSubmit={handleSubmitComment}>
              <TextField
                fullWidth
                size="small"
                placeholder="Add a comment..."
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                sx={{
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.5)',
                    },
                  },
                }}
              />
            </form>

            {visibleComments?.map((comment) => (
              <CommentItem
                key={comment.id}
                comment={comment}
                currentUser={currentUser}
                onUpdate={onUpdateComment}
                onDelete={onDeleteComment}
                onReply={onReply}
                replyText={replyText}
                setReplyText={setReplyText}
                handleSubmitReply={handleSubmitReply}
              />
            ))}

            {hasMoreComments && (
              <Button
                fullWidth
                onClick={() => setShowAllComments(!showAllComments)}
                sx={{ mt: 2 }}
              >
                {showAllComments ? 'Show Less' : `Show ${item.comments.length - 2} More Comments`}
              </Button>
            )}
          </CommentSection>
        </Collapse>
      </Box>
    );
  };

  const CommentItem = ({
    comment,
    currentUser,
    onUpdate,
    onDelete,
    onReply,
    replyText,
    setReplyText,
    handleSubmitReply,
    level = 0
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState(comment.content);
    const [showReplyField, setShowReplyField] = useState(false);
    const [showReplies, setShowReplies] = useState(false);

    const handleUpdate = async () => {
      try {
        await onUpdate(comment.id, editedContent);
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating comment:', error);
      }
    };

    const canModify = currentUser?.role === 'ADMIN' || currentUser?.id === comment.user;

    return (
      <Box sx={{ ml: level * 3, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            {comment.user_name}
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
            {dayjs(comment.created_at).fromNow()}
            {comment.is_edited && ' (edited)'}
          </Typography>
        </Box>

        {isEditing ? (
          <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
            <TextField
              fullWidth
              size="small"
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                },
              }}
            />
            <Button size="small" onClick={handleUpdate}>Save</Button>
            <Button size="small" onClick={() => setIsEditing(false)}>Cancel</Button>
          </Box>
        ) : (
          <Typography variant="body2" sx={{ mb: 1 }}>
            {comment.content}
          </Typography>
        )}

        <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
          <Button
            size="small"
            startIcon={<ReplyIcon />}
            onClick={() => setShowReplyField(!showReplyField)}
            sx={{ color: 'white' }}
          >
            Reply
          </Button>
          {canModify && (
            <>
              <Button
                size="small"
                onClick={() => setIsEditing(true)}
                sx={{ color: 'white' }}
              >
                Edit
              </Button>
              <Button
                size="small"
                onClick={() => onDelete(comment.id)}
                sx={{ color: '#ef4444' }}
              >
                Delete
              </Button>
            </>
          )}
        </Box>

        {showReplyField && (
          <ReplyField>
            <TextField
              fullWidth
              size="small"
              placeholder="Write a reply..."
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                },
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Button
                size="small"
                onClick={() => {
                  handleSubmitReply(comment.id);
                  setShowReplyField(false);
                }}
              >
                Submit Reply
              </Button>
            </Box>
          </ReplyField>
        )}

        {comment.replies?.length > 0 && (
          <>
            <Button
              size="small"
              onClick={() => setShowReplies(!showReplies)}
              startIcon={showReplies ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              sx={{ color: 'white', mb: 1 }}
            >
              {showReplies ? 'Hide' : 'Show'} {comment.replies.length} Replies
            </Button>

            <Collapse in={showReplies}>
              {comment.replies.map((reply) => (
                <CommentItem
                  key={reply.id}
                  comment={reply}
                  currentUser={currentUser}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  onReply={onReply}
                  replyText={replyText}
                  setReplyText={setReplyText}
                  handleSubmitReply={handleSubmitReply}
                  level={level + 1}
                />
              ))}
            </Collapse>
          </>
        )}
      </Box>
    );
  };

  // Main render
  return (
    <Box sx={{ 
      maxWidth: 1400, 
      mx: 'auto', 
      p: 3,
      height: 'calc(100vh - 48px)',
      display: 'flex',
      gap: 3
    }}>
      {/* Left Column - Debates and Videos */}
      <Box sx={{ 
        flex: 1,
        overflowY: 'auto',
        height: '100%',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '4px',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.3)',
          },
        },
        pr: 2
      }}>
        {/* Create Debate Button */}
        {canCreateDebate && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setDebateDialogOpen(true)}
            sx={{ mb: 3 }}
          >
            Create New Debate
          </Button>
        )}
  
        <DebateCreateDialog
          open={debateDialogOpen}
          onClose={() => setDebateDialogOpen(false)}
          onSubmit={handleCreateDebate}
        />
  
        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* Active Debates Section */}
            <Typography variant="h6" sx={{ mb: 2 }}>Active Debates</Typography>
            {debates
              .filter(d => {
                const now = new Date();
                const startDate = new Date(d.start_date);
                const endDate = new Date(d.end_date);
                return d.is_active && startDate <= now && now <= endDate;
              })
              .map((debate) => (
                <div className="active-debates" key={debate.id}>
                  <DebateCard
                    debate={debate}
                    currentUser={currentUser}
                    isAuthorized={canCreateDebate}
                    onUpdate={handleUpdateDebate}
                    onDelete={handleDeleteDebate}
                  />
                  
                  {/* Video Upload Section */}
                  {canCreateVideo && (
                    <StyledCard className="video-upload-section">
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          Upload Your Response
                        </Typography>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Your Stance</InputLabel>
                          <Select
                            value={stance}
                            onChange={(e) => setStance(e.target.value)}
                            required
                          >
                            <MenuItem value="FOR">For</MenuItem>
                            <MenuItem value="AGAINST">Against</MenuItem>
                          </Select>
                        </FormControl>
  
                        <Box sx={{ mt: 2 }}>
                          <Button
                            variant="outlined"
                            component="label"
                            fullWidth
                            sx={{ mb: 2 }}
                          >
                            {selectedVideo ? 'Change Video' : 'Upload Video'}
                            <input
                              type="file"
                              ref={fileInputRef}
                              accept="video/*"
                              onChange={handleVideoSelect}
                              style={{ display: 'none' }}
                            />
                          </Button>
  
                          {videoPreview && (
                            <Box sx={{ mt: 2 }}>
                              <Typography variant="subtitle2" gutterBottom>
                                Preview:
                              </Typography>
                              <video
                                src={videoPreview}
                                controls
                                style={{
                                  width: '100%',
                                  maxHeight: '400px',
                                  borderRadius: '8px',
                                  marginTop: '16px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                }}
                              />
                            </Box>
                          )}
  
  <Dialog
  open={processingVideo}
  PaperProps={{
    sx: {
      backgroundColor: 'rgba(13, 17, 38, 0.95)',
      backdropFilter: 'blur(10px)',
      borderRadius: 2,
      border: '1px solid rgba(255, 255, 255, 0.1)',
      minWidth: 300
    }
  }}
>
  <DialogTitle sx={{
    color: 'white',
    fontSize: '1.125rem',
    fontWeight: 600
  }}>
    {uploadProgress === 100 ? 'Processing Video' : 'Uploading Video'}
  </DialogTitle>
  <DialogContent>
    <Box sx={{
      textAlign: 'center',
      py: 2
    }}>
      {uploadProgress === 100 ? (
        <>
          <CircularProgress sx={{
            mb: 2,
            color: '#4A90E2'
          }} />
          <Typography sx={{
            color: 'white',
            mb: 2
          }}>
            Processing your video...
          </Typography>
        </>
      ) : (
        <>
          <Box sx={{ position: 'relative', display: 'inline-flex', mb: 2 }}>
            <CircularProgress
              variant="determinate"
              value={uploadProgress}
              sx={{ color: '#4A90E2' }}
            />
            <Box sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Typography variant="caption" sx={{ color: 'white' }}>
                {`${uploadProgress.toFixed(2)}%`}
              </Typography>
            </Box>
          </Box>
          <Typography sx={{
            color: 'white',
            mb: 2
          }}>
            Uploading: {uploadProgress.toFixed(2)}% Complete
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={uploadProgress}
            sx={{
              height: 8,
              borderRadius: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#4A90E2'
              }
            }}
          />
        </>
      )}
    </Box>
  </DialogContent>
</Dialog>
  
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={handleVideoUpload}
                            disabled={!selectedVideo || !stance || processingVideo}
                            sx={{ mt: 2 }}
                          >
                            Submit Video Response
                          </Button>
                        </Box>
                      </CardContent>
                    </StyledCard>
                  )}
  
                  {/* Video Responses Section */}
                  <Box className="video-responses-list">
                    <Typography variant="h6" gutterBottom>
                      Video Responses
                    </Typography>
                    {videos
  .filter(video => video && video.debate_topic && video.debate_topic === debate.id)
  .map((video) => (
    <VideoResponseCard key={video.id} video={video} />
  ))}
                    {videos.filter(video => video.debate_topic === debate.id).length === 0 && (
                      <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 4 }}>
                        No video responses yet. Be the first to respond!
                      </Typography>
                    )}
                  </Box>
                </div>
              ))}
  
            {/* Other Debates Section (Only visible to admins, managers, and trainers) */}
            {canCreateDebate && (
              <>
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Other Debates</Typography>
                {debates
                  .filter(d => {
                    const now = new Date();
                    const startDate = new Date(d.start_date);
                    const endDate = new Date(d.end_date);
                    return !d.is_active || startDate > now || endDate < now;
                  })
                  .map((debate) => (
                    <div key={debate.id}>
                      <DebateCard
                        debate={debate}
                        currentUser={currentUser}
                        isAuthorized={canCreateDebate}
                        onUpdate={handleUpdateDebate}
                        onDelete={handleDeleteDebate}
                      />
                      {/* Video Responses Section for inactive debates */}
                      {videos.filter(video => video.debate_topic === debate.id).length > 0 && (
                        <Box className="video-responses-list">
                          <Typography variant="h6" gutterBottom>
                            Video Responses
                          </Typography>
                          {videos
                            .filter(video => video.debate_topic === debate.id)
                            .map((video) => (
                              <VideoResponseCard key={video.id} video={video} />
                            ))}
                        </Box>
                      )}
                    </div>
                  ))}
              </>
            )}
  
            {debates.length === 0 && (
              <Typography variant="body1" align="center" sx={{ py: 4 }}>
                No debates available.
              </Typography>
            )}
          </>
        )}
      </Box>
  
      {/* Right Column - Stories */}
      <Box className="stories-column" sx={{ 
        flex: 1,
        overflowY: 'auto',
        height: '100%',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '4px',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.3)',
          },
        },
        pr: 2
      }}>
        <Typography variant="h6" gutterBottom>
          Stories
        </Typography>
        
        {/* Story Creation Box */}
        {canCreateStory && (
          <Button
            variant="contained"
            onClick={() => {
              setEditingStoryData(null);
              setStoryEditorOpen(true);
            }}
            fullWidth
            sx={{ mb: 3 }}
            startIcon={<AddIcon />}
          >
            Create New Story
          </Button>
        )}
  
        {/* Stories List */}
        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {stories.map((story) => (
              <StoryCard
                key={story.id}
                story={story}
                currentUser={currentUser}
                onEdit={() => {
                  setEditingStoryData(story);
                  setStoryEditorOpen(true);
                }}
                onDelete={() => handleDeleteStory(story.id)}
                SocialInteractions={SocialInteractions}
                showComments={story.showComments || false}
                setShowComments={(show) => {
                  setStories(prevStories =>
                    prevStories.map(s =>
                      s.id === story.id ? { ...s, showComments: show } : s
                    )
                  );
                }}
                handleAddReaction={handleAddReaction}
                handleRemoveReaction={handleRemoveReaction}
                handleCreateComment={handleCreateComment}
                handleUpdateComment={handleUpdateComment}
                handleDeleteComment={handleDeleteComment}
                handleReplyToComment={handleReplyToComment}
                visibleComments={story.showAllComments ? story.comments : story.comments?.slice(0, 2)}
                showAllComments={story.showAllComments || false}
                setShowAllComments={(show) => {
                  setStories(prevStories =>
                    prevStories.map(s =>
                      s.id === story.id ? { ...s, showAllComments: show } : s
                    )
                  );
                }}
                hasMoreComments={story.comments?.length > 2}
              />
            ))}
            {stories.length === 0 && (
              <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 4 }}>
                No stories yet. Be the first to share!
              </Typography>
            )}
          </>
        )}
      </Box>
  
      {processingVideo && (
        <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}>
          <Alert severity="info">
            Video Upload Progress: {uploadProgress}%
          </Alert>
        </Box>
      )}
  
      {(storyEditorOpen || editingStoryData) && (
        <StoryEditorDialog
          open={true}
          onClose={() => {
            setStoryEditorOpen(false);
            setEditingStoryData(null);
          }}
          onSave={async (data) => {
            if (editingStoryData) {
              await handleUpdateStory({
                ...editingStoryData,
                title: data.title,
                content: data.content,
                status: data.status
              });
            } else {
              await handleCreateStory({
                title: data.title,
                content: data.content,
                status: data.status,
                deadline: dayjs().add(7, 'days').toISOString()
              });
            }
            setStoryEditorOpen(false);
            setEditingStoryData(null);
          }}
          initialTitle={editingStoryData?.title || ''}
          initialContent={editingStoryData?.content || ''}
          storyId={editingStoryData?.id || null}
          isNewStory={!editingStoryData}
        />
      )}
      {showTutorial && <SocialPageTutorial onClose={completeTutorial} />}
    </Box>
  );
};

export default SocialPage;