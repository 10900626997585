import { useState, useEffect } from 'react';

export const useTutorial = () => {
  const [showTutorial, setShowTutorial] = useState(false);
  
  useEffect(() => {
    // Check if the tutorial has been completed before
    const tutorialCompleted = localStorage.getItem('socialPageTutorialCompleted');
    if (!tutorialCompleted) {
      setShowTutorial(true);
    }
  }, []);

  const completeTutorial = () => {
    localStorage.setItem('socialPageTutorialCompleted', 'true');
    setShowTutorial(false);
  };

  const resetTutorial = () => {
    localStorage.removeItem('socialPageTutorialCompleted');
    setShowTutorial(true);
  };

  return {
    showTutorial,
    completeTutorial,
    resetTutorial
  };
};