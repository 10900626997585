// CourseAssignmentTrackingPage.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Grid,
  CircularProgress,
  IconButton,
  Chip,
  Tooltip
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Calendar, Download } from 'lucide-react';
import dayjs from 'dayjs';
import { getCourseAssignmentTracking } from '../api';

const CourseAssignmentTrackingPage = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [year, setYear] = useState(dayjs().year());
  const [month, setMonth] = useState(dayjs().month() + 1);
  const [selectedAdmin, setSelectedAdmin] = useState('');
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getCourseAssignmentTracking({
        year,
        month,
        assigned_by: selectedAdmin
      });
      setData(response.data);
    } catch (err) {
      console.error('Error fetching course assignments:', err);
      setError('Failed to fetch course assignment data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [year, month, selectedAdmin]);

  const handleMonthYearChange = (date) => {
    if (date) {
      setYear(date.year());
      setMonth(date.month() + 1);
    }
  };

  const getAssignmentChipColor = (daysAgo) => {
    if (daysAgo === 0) return 'success';
    if (daysAgo <= 3) return 'info';
    if (daysAgo <= 7) return 'warning';
    return 'default';
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  // Create an admin summary from assignments
  const adminsData = {};
  data?.assignments.forEach(assignment => {
    if (assignment.assigned_by) {
      const adminId = assignment.assigned_by.id;
      if (!adminsData[adminId]) {
        adminsData[adminId] = {
          id: adminId,
          name: assignment.assigned_by.name,
          role: assignment.assigned_by.role,
          count: 0
        };
      }
      adminsData[adminId].count++;
    }
  });

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Course Assignment Tracking
      </Typography>

      {/* Filters */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year', 'month']}
              label="Select Month and Year"
              value={dayjs(`${year}-${month.toString().padStart(2, '0')}-01`)}
              onChange={handleMonthYearChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            select
            fullWidth
            label="Filter by Admin/Manager"
            value={selectedAdmin}
            onChange={(e) => setSelectedAdmin(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {Object.values(adminsData).map((admin) => (
              <MenuItem key={admin.id} value={admin.id}>
                {admin.name} ({admin.count} assignments)
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {/* Summary Card */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" color="textSecondary">
                Total Assignments
              </Typography>
              <Typography variant="h3">
                {data?.total_assignments || 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" color="textSecondary">
                Assignments by Admin/Manager
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                {Object.values(adminsData).map((admin) => (
                  <Chip
                    key={admin.id}
                    label={`${admin.name}: ${admin.count}`}
                    variant="outlined"
                    onClick={() => setSelectedAdmin(admin.id)}
                    color={selectedAdmin === admin.id ? 'primary' : 'default'}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Assignments Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Assigned Date</TableCell>
              <TableCell>Assigned By</TableCell>
              <TableCell>Student</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Trainer</TableCell>
              <TableCell>Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.assignments.map((assignment) => {
              const assignedDate = dayjs(assignment.assigned_at);
              const daysAgo = dayjs().diff(assignedDate, 'day');
              
              return (
                <TableRow key={assignment.id}>
                  <TableCell>
                    <Tooltip title={assignedDate.format('YYYY-MM-DD HH:mm:ss')}>
                      <Chip
                        icon={<Calendar size={16} />}
                        label={assignedDate.format('MMM D, YYYY')}
                        color={getAssignmentChipColor(daysAgo)}
                        variant="outlined"
                        size="small"
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {assignment.assigned_by.name}
                      <Typography variant="caption" display="block" color="textSecondary">
                        {assignment.assigned_by.role}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {assignment.student.name}
                      <Typography variant="caption" display="block" color="textSecondary">
                        {assignment.student.email}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {assignment.course.name}
                      <Typography variant="caption" display="block" color="textSecondary">
                        {assignment.course.is_group_class ? 'Group Class' : 'Personal Training'}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {assignment.trainer ? (
                      <Typography variant="body2">
                        {assignment.trainer.name}
                      </Typography>
                    ) : (
                      <Typography variant="caption" color="textSecondary">
                        No trainer assigned
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={`${assignment.start_date} to ${assignment.end_date}`}>
                      <Typography variant="body2">
                        {dayjs(assignment.end_date).diff(assignment.start_date, 'days')} days
                      </Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CourseAssignmentTrackingPage;