import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getAttendance, getUser } from '../api';
import dayjs from 'dayjs';

// Styled components with glass effect
const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(18, 18, 18, 0.95)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.18)',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    borderRadius: '10px',
    maxWidth: '95vw !important',
    maxHeight: '90vh !important',
  },
}));

const VirtualizedTableContainer = styled(Box)(({ theme }) => ({
  height: '70vh',
  width: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.4)',
    },
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'transparent',
  borderRadius: theme.shape.borderRadius,
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme, status }) => ({
  padding: '8px',
  textAlign: 'center',
  fontWeight: 'bold',
  backgroundColor: status === 'P' ? 'rgba(76, 175, 80, 0.3)' :
                 status === 'A' ? 'rgba(244, 67, 54, 0.3)' :
                 status === 'TA' ? 'rgba(255, 23, 68, 0.3)' :
                 status === 'O' ? 'rgba(255, 235, 59, 0.3)' :
                 status === 'C' ? 'rgba(255, 152, 0, 0.3)' :
                 'transparent',
  color: theme.palette.common.white,
  transition: 'background-color 0.2s ease',
  '&:hover': {
    backgroundColor: status === 'P' ? 'rgba(76, 175, 80, 0.5)' :
                   status === 'A' ? 'rgba(244, 67, 54, 0.5)' :
                   status === 'TA' ? 'rgba(255, 23, 68, 0.5)' :
                   status === 'O' ? 'rgba(255, 235, 59, 0.5)' :
                   status === 'C' ? 'rgba(255, 152, 0, 0.5)' :
                   'rgba(255, 255, 255, 0.1)',
  },
}));

const HeaderRow = styled(TableRow)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: 'rgba(18, 18, 18, 0.95)',
  zIndex: 1,
  '& th': {
    backgroundColor: 'rgba(18, 18, 18, 0.95)',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));

const GlassButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: theme.palette.common.white,
  backdropFilter: 'blur(5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 3),
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'translateY(-2px)',
  },
}));

const SlideTransition = styled('div')(({ theme, direction }) => ({
  position: 'relative',
  width: '100%',
  '&.slide-enter': {
    transform: direction === 'left' ? 'translateX(100%)' : 'translateX(-100%)',
    opacity: 0,
  },
  '&.slide-enter-active': {
    transform: 'translateX(0%)',
    opacity: 1,
    transition: 'all 300ms ease-in',
  },
  '&.slide-exit': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    transform: 'translateX(0%)',
    opacity: 1,
  },
  '&.slide-exit-active': {
    transform: direction === 'left' ? 'translateX(-100%)' : 'translateX(100%)',
    opacity: 0,
    transition: 'all 300ms ease-in',
  },
}));

const getStatusAbbreviation = (status) => {
  switch (status) {
    case 'PRESENT': return 'P';
    case 'ABSENT': return 'A';
    case 'TRAINER_ABSENT': return 'TA';
    case 'OFF': return 'O';
    case 'COMP': return 'C';
    default: return '-';
  }
};

// Memoized row component
const StudentRow = memo(({ data, style }) => {
  const { studentData, days, currentMonth } = data;
  
  return (
    <TableRow style={style}>
      <TableCell 
        style={{ 
          position: 'sticky', 
          left: 0, 
          backgroundColor: 'rgba(18, 18, 18, 0.95)',
          zIndex: 1,
          minWidth: '200px'
        }}
      >
        {studentData.name}
      </TableCell>
      <TableCell 
        style={{ 
          position: 'sticky', 
          left: '200px', 
          backgroundColor: 'rgba(18, 18, 18, 0.95)',
          zIndex: 1,
          minWidth: '150px'
        }}
      >
        {studentData.classTime}
      </TableCell>
      {days.map(day => {
        const date = currentMonth.date(day).format('YYYY-MM-DD');
        const status = getStatusAbbreviation(studentData.attendance[date]);
        return (
          <StyledTableCell key={day} status={status} style={{ minWidth: '60px' }}>
            {status}
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
});

const AttendanceOverviewModal = ({ open, onClose, trainerId, studentMap }) => {
  const [attendanceData, setAttendanceData] = useState({});
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [slideDirection, setSlideDirection] = useState('left');
  const [missingStudentData, setMissingStudentData] = useState({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const days = useMemo(() => {
    const daysInMonth = currentMonth.daysInMonth();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  }, [currentMonth]);

  const fetchMissingStudentData = useCallback(async (studentId) => {
    if (missingStudentData[studentId]) return missingStudentData[studentId];
    
    try {
      const response = await getUser(studentId);
      const studentData = {
        name: response.data.full_name || response.data.username || `Student ${studentId}`,
        classTime: 'Historical'
      };
      
      setMissingStudentData(prev => ({
        ...prev,
        [studentId]: studentData
      }));
      
      return studentData;
    } catch (error) {
      console.error(`Error fetching data for student ${studentId}:`, error);
      return null;
    }
  }, [missingStudentData]);

  const fetchAttendanceData = useCallback(async () => {
    if (!trainerId || !open) return;

    setLoading(true);
    try {
      const startDate = currentMonth.startOf('month').format('YYYY-MM-DD');
      const endDate = currentMonth.endOf('month').format('YYYY-MM-DD');
      
      const attendanceResponse = await getAttendance({
        trainer: trainerId,
        start_date: startDate,
        end_date: endDate
      });

      const missingStudentIds = new Set(
        attendanceResponse.data
          .filter(a => !studentMap[a.student] && !missingStudentData[a.student])
          .map(a => a.student)
      );

      if (missingStudentIds.size > 0) {
        await Promise.all(
          Array.from(missingStudentIds).map(fetchMissingStudentData)
        );
      }

      // Group attendance records by student
      const attendanceByStudent = attendanceResponse.data.reduce((acc, attendance) => {
        const studentId = attendance.student;
        const date = dayjs(attendance.timestamp).format('YYYY-MM-DD');
        
        if (!acc[studentId]) {
          const studentInfo = studentMap[studentId] || missingStudentData[studentId] || {
            name: `Loading...`,
            classTime: 'N/A'
          };
          
          acc[studentId] = {
            name: studentInfo.name,
            classTime: studentInfo.classTime,
            attendance: {}
          };
        }
        acc[studentId].attendance[date] = attendance.status;
        return acc;
      }, {});

      // Enhanced filtering: Remove students with no records AND historical students
      const filteredData = Object.entries(attendanceByStudent).reduce((acc, [studentId, data]) => {
        const hasAttendanceRecords = Object.keys(data.attendance).length > 0;
        const isHistorical = data.classTime === 'Historical';
        
        // Include only if:
        // 1. Has attendance records OR
        // 2. Is not a historical student
        if (hasAttendanceRecords || !isHistorical) {
          acc[studentId] = data;
        }
        return acc;
      }, {});

      setAttendanceData(filteredData);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    } finally {
      setLoading(false);
      setIsInitialLoad(false);
    }
  }, [trainerId, currentMonth, studentMap, missingStudentData, fetchMissingStudentData, open]);

  useEffect(() => {
    let mounted = true;

    if (open && trainerId) {
      fetchAttendanceData().then(() => {
        if (mounted) {
          setIsInitialLoad(false);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [open, fetchAttendanceData, trainerId]);

  const handlePreviousMonth = useCallback(() => {
    setSlideDirection('right');
    setCurrentMonth(prev => prev.subtract(1, 'month'));
  }, []);

  const handleNextMonth = useCallback(() => {
    setSlideDirection('left');
    setCurrentMonth(prev => prev.add(1, 'month'));
  }, []);

  const sortedStudents = useMemo(() => {
    // Get the start and end dates for the current month
    const startDate = currentMonth.startOf('month').format('YYYY-MM-DD');
    const endDate = currentMonth.endOf('month').format('YYYY-MM-DD');
  
    return Object.entries(attendanceData)
      .filter(([studentId, data]) => {  // Changed from _ to studentId
        return Object.entries(data.attendance).some(([date]) => {
          return date >= startDate && date <= endDate;
        });
      })
      .sort(([id1, a], [id2, b]) => a.name.localeCompare(b.name))  // Changed from _ to id1, id2
      .map(([id, data]) => ({ id, ...data }));
  }, [attendanceData, currentMonth]);

  if (!open) return null;

  return (
    <GlassDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
      }}
      PaperProps={{
        sx: {
          minHeight: '80vh',
          maxHeight: '90vh',
          minWidth: '80vw',
          maxWidth: '95vw !important',
        }
      }}
    >
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <GlassButton onClick={handlePreviousMonth}>Previous Month</GlassButton>
          <Typography variant="h6" sx={{ color: 'white' }}>
            {currentMonth.format('MMMM YYYY')}
          </Typography>
          <GlassButton onClick={handleNextMonth}>Next Month</GlassButton>
        </Box>

        {isInitialLoad ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <TransitionGroup>
            <CSSTransition
              key={currentMonth.format('YYYY-MM')}
              classNames="slide"
              timeout={300}
              mountOnEnter
              unmountOnExit
            >
              <SlideTransition direction={slideDirection}>
                <VirtualizedTableContainer>
                  <StyledTableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <HeaderRow>
                          <TableCell 
                            style={{ 
                              position: 'sticky', 
                              left: 0, 
                              zIndex: 2,
                              minWidth: '200px'
                            }}
                          >
                            Student Name
                          </TableCell>
                          <TableCell 
                            style={{ 
                              position: 'sticky', 
                              left: '200px', 
                              zIndex: 2,
                              minWidth: '150px'
                            }}
                          >
                            Class Time
                          </TableCell>
                          {days.map(day => (
                            <TableCell 
                              key={day} 
                              align="center"
                              style={{ minWidth: '60px' }}
                            >
                              {day}
                            </TableCell>
                          ))}
                        </HeaderRow>
                      </TableHead>
                      <TableBody>
                        {sortedStudents.map((student) => (
                          <StudentRow
                            key={student.id}
                            data={{
                              studentData: student,
                              days,
                              currentMonth
                            }}
                            style={{ height: 48 }}
                          />
                        ))}
                        {sortedStudents.length === 0 && (
                          <TableRow>
                            <TableCell 
                              colSpan={days.length + 2}
                              align="center"
                              sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                            >
                              No attendance records found for this month
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                </VirtualizedTableContainer>
              </SlideTransition>
            </CSSTransition>
          </TransitionGroup>
        )}

        <Box sx={{ 
          mt: 2, 
          display: 'flex', 
          gap: 2, 
          flexWrap: 'wrap',
          justifyContent: 'center',
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          paddingTop: 2
        }}>
          <Typography variant="caption" sx={{ color: 'rgba(76, 175, 80, 0.7)' }}>
            P - Present
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(244, 67, 54, 0.7)' }}>
            A - Absent
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 23, 68, 0.7)' }}>
            TA - Trainer Absent
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 235, 59, 0.7)' }}>
            O - Off
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 152, 0, 0.7)' }}>
            C - Comp
          </Typography>
        </Box>
      </DialogContent>
    </GlassDialog>
  );
};

AttendanceOverviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  trainerId: PropTypes.string,
  studentMap: PropTypes.object
};

AttendanceOverviewModal.defaultProps = {
  studentMap: {}
};

export default memo(AttendanceOverviewModal);