import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
  Avatar,
  TextField,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Badge,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Paper,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Snackbar, Tooltip,
  Alert, ListItemIcon
} from '@mui/material';
import { styled } from '@mui/material/styles';
import webSocketService from '../services/apiws';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MessagePopupHandler from '../components/MessagePopupHandler';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import GoogleIcon from '@mui/icons-material/Google';
import { listAssignments } from '../api';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import { getCurrentUser,  listStudentCourses, getImageUrl,getCompletedVocabBlocks,getCurrentVocabProgress, getVocabProgressStats, getStudyMaterialsForCourse, joinBBBRoom, getTrainerGroupCourses, listInboxMessages, listSentMessages, listUnreadMessages, sendMessage, markMessageAsRead, getMessage, listUsers, getTrainer, updateUserMe, listStudyMaterials, createStudyMaterial, getStudyMaterialsForStudent, getUser, getAttendance, getTrainerOccupation, updateAttendance, createAttendanceReview, listNotices, listGroupCourses, listAttendanceReviews, getAttendanceReviewHistory } from '../api';

import { createAssignment } from '../api';
import AttendanceCalendar from '../components/AttendanceCalendar'; 
import AssignmentManagement from '../components/AssignmentManagement';


import NoticeCard from './NoticeCard';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import VideocamIcon from '@mui/icons-material/Videocam';
import AttendanceManagement from '../components/AttendanceManagement';
import StudentNotification from '../components/StudentNotification';
import MessagePopup from '../components/MessagePopup';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Image as ImageIcon,
  
} from '@mui/icons-material';


const CompactButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  padding: '4px 8px',
  fontSize: '0.75rem',
  minWidth: '32px',
  height: '32px',
  margin: '2px',
  borderRadius: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 2px 5px rgba(255, 27, 107, 0.3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 4px 10px rgba(255, 27, 107, 0.5)',
  },
  '&:disabled': {
    background: 'linear-gradient(45deg, #888, #666)',
    color: 'rgba(255, 255, 255, 0.5)',
  },
}));


const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));
const GlassCard = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  
  
  borderRadius: '10px',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  
}))

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const GlowingAvatar = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  borderRadius: '50%',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: '0 0 20px rgba(255, 27, 107, 0.5)',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -4,
    left: -4,
    right: -4,
    bottom: -4,
    background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
    borderRadius: 'inherit',
    zIndex: 0,
  },
}));

const AvatarImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: 1,
});

const AvatarFallback = styled(Typography)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  zIndex: 1,
});

const DebugBox = styled(Box)({
  marginTop: 10,
  padding: 10,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  borderRadius: 5,
});

const ScrollableList = styled(List)({
  maxHeight: '200px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
  },
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StudentAvatar = styled('img')({
  width: 100,
  height: 100,
  borderRadius: '50%',
  objectFit: 'cover',
  marginBottom: 16,
  boxShadow: '0 0 20px rgba(255, 27, 107, 0.5)',
});

const GlassModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: theme.palette.common.white,
  },
}));

const GradientChip = styled(Chip)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const NoticeExcerpt = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  marginBottom: '8px',
});

const TrainerDashboard = () => {
  const [trainerData, setTrainerData] = useState({
    profile: null,
    associatedStudents: [],
    groupCourses: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
  const [attendanceData, setAttendanceData] = useState({});
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [reviewRemark, setReviewRemark] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [materialModalOpen, setMaterialModalOpen] = useState(false);
  const [selectedStudentForMaterial, setSelectedStudentForMaterial] = useState(null);
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [newMaterials, setNewMaterials] = useState({ topic: '', files: [] });
  const [studentDetailsModalOpen, setStudentDetailsModalOpen] = useState(false);
  const [selectedStudentDetails, setSelectedStudentDetails] = useState(null);
  const [messages, setMessages] = useState({ inbox: [], sent: [] });
  const [unreadCount, setUnreadCount] = useState(0);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [newMessage, setNewMessage] = useState({ recipient: '', subject: '', body: '' });
  const [adminManagerList, setAdminManagerList] = useState([]);
  const [messageTab, setMessageTab] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [notices, setNotices] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [groupCourses, setGroupCourses] = useState([]);
  const [occupation, setOccupation] = useState(null);
  const [attendanceReviews, setAttendanceReviews] = useState([]);
  const [attendanceReviewHistory, setAttendanceReviewHistory] = useState([]);
  const [students, setStudents] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [fullImageUrl, setFullImageUrl] = useState('');
  const navigate = useNavigate();
  const [vocabModalOpen, setVocabModalOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
const [selectedStudentCourse, setSelectedStudentCourse] = useState(null);
const [assignmentModalOpen, setAssignmentModalOpen] = useState(null);

  
const [selectedStudentVocab, setSelectedStudentVocab] = useState(null);
const [vocabData, setVocabData] = useState({
  completedBlocks: [],
  currentBlock: null,
  stats: null,
  loading: false,
  error: null
});

  const fetchTrainerData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const userResponse = await getCurrentUser();
      const trainerId = userResponse.data.id;
      
      const today = new Date().toISOString().split('T')[0];
      
      const [
        noticesResponse,
        groupCoursesResponse,
        studentsResponse,
        occupationResponse,
        messagesResponse,
        adminManagersResponse,
        reviewsResponse,
        reviewHistoryResponse
      ] = await Promise.all([
        listNotices(),
        getTrainerGroupCourses(),
        listStudentCourses({ trainer: trainerId }),
        getTrainerOccupation(trainerId, today),
        Promise.all([listUnreadMessages(), listInboxMessages(), listSentMessages()]),
        listUsers({ role: ['ADMIN', 'MANAGER'] }),
        listAttendanceReviews(),
        getAttendanceReviewHistory()
      ]);

      const studentsWithUserData = await Promise.all(
        (studentsResponse.data ?? []).map(async (studentCourse) => {
          try {
            const userResponse = await getUser(studentCourse.student);
            return {
              ...studentCourse,
              studentData: userResponse.data ?? {}
            };
          } catch (error) {
            console.error(`Failed to fetch data for student ${studentCourse.student}:`, error);
            return studentCourse;
          }
        })
      );
      
      
      
      setTrainerData({
        profile: userResponse.data,
        associatedStudents: studentsResponse.data,
        groupCourses: groupCoursesResponse.data,
      });
      
      setOccupation(occupationResponse.data);
      setNotices(noticesResponse.data || []);
      setStudents(studentsWithUserData);
      
      const [unreadResponse, inboxResponse, sentResponse] = messagesResponse;
      setUnreadCount(unreadResponse.data.length);
      setMessages({
        inbox: inboxResponse.data,
        sent: sentResponse.data,
      });
      
      setAdminManagerList(adminManagersResponse.data);
      setAttendanceReviews(reviewsResponse.data);
      setAttendanceReviewHistory(reviewHistoryResponse.data);

    } catch (err) {
      console.error('Error fetching trainer data:', err);
      setError('Failed to fetch trainer data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTrainerData();
  }, [fetchTrainerData]);

  useEffect(() => {
    if (trainerData.profile?.profile_image) {
      const url = getImageUrl(trainerData.profile.profile_image);
      setFullImageUrl(url);
      console.log("Full Profile Image URL:", url);
    }
  }, [trainerData.profile?.profile_image]);


  
  

  

  const handleJoinClass = (joinUrl) => {
    if (joinUrl) {
      window.open(joinUrl, '_blank');
    } else {
      setSnackbar({ open: true, message: 'Join URL not available', severity: 'error' });
    }
  };

  const handleViewAttendance = (student) => {
    setSelectedStudent(student);
    setAttendanceModalOpen(true);
  };

  const handleRequestReview = (attendance) => {
    setSelectedAttendance(attendance);
    setReviewModalOpen(true);
  };

  const handleViewVocabProgress = async (studentId) => {
    setSelectedStudentVocab(studentId);
    setVocabModalOpen(true);
    setVocabData(prev => ({ ...prev, loading: true, error: null }));
    
    try {
      // Get the student's name for display
      const studentResponse = await getUser(studentId);
      const studentName = studentResponse.data.full_name;
  
      // Make API calls separately to handle individual failures
      const completedResponse = await getCompletedVocabBlocks(studentId);
      const statsResponse = await getVocabProgressStats(studentId);
      
      let currentBlockData = null;
      try {
        const currentResponse = await getCurrentVocabProgress(studentId);
        currentBlockData = currentResponse.data.message ? null : currentResponse.data;
      } catch (currentBlockError) {
        console.error('Error fetching current block:', currentBlockError);
        // Don't set error state, just continue with null currentBlock
      }
  
      setVocabData({
        studentName,
        completedBlocks: completedResponse.data.completed_blocks || [],
        currentBlock: currentBlockData,
        stats: statsResponse.data,
        loading: false,
        error: null
      });
    } catch (err) {
      console.error('Error fetching vocab progress:', err);
      setVocabData(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to fetch vocabulary progress'
      }));
    }
  };
  
  

  const handleEditProfile = () => {
    setEditedProfile({
      first_name: trainerData.profile.first_name,
      last_name: trainerData.profile.last_name,
      email: trainerData.profile.email,
      phone: trainerData.profile.phone,
      city: trainerData.profile.city,
      about_me: trainerData.profile.about_me,
      date_of_birth: trainerData.profile.date_of_birth,
    });
    setEditProfileModalOpen(true);
  };

  const handleProfileInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleProfileImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewProfileImage(e.target.files[0]);
    }
  };

  const handleSaveProfile = async () => {
    try {
      const formData = new FormData();
      
      Object.keys(editedProfile).forEach(key => {
        if (editedProfile[key] !== trainerData.profile[key]) {
          formData.append(key, editedProfile[key]);
        }
      });
  
      if (newProfileImage) {
        formData.append('profile_image', newProfileImage);
      }
  
      const response = await updateUserMe(formData);
  
      setTrainerData(prev => ({
        ...prev,
        profile: {
          ...prev.profile,
          ...response.data
        }
      }));
  
      setEditProfileModalOpen(false);
      setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
    } catch (err) {
      console.error('Error updating profile:', err);
      setSnackbar({ open: true, message: 'Failed to update profile. Please try again.', severity: 'error' });
    }
  };

  const handleOpenMaterialModal = async (courseOrStudentCourse) => {
    setSelectedStudentForMaterial(courseOrStudentCourse);
    try {
      let materials;
      let courseId;
  
      if (courseOrStudentCourse.course) {
        courseId = courseOrStudentCourse.course.id;
        if (courseOrStudentCourse.course.is_group_class) {
          materials = await getStudyMaterialsForCourse(courseId);
        } else {
          materials = await getStudyMaterialsForStudent(courseOrStudentCourse.student);
        }
      } else {
        courseId = courseOrStudentCourse.id;
        materials = await getStudyMaterialsForCourse(courseId);
      }
  
      setStudyMaterials(materials.data);
      setMaterialModalOpen(true);
    } catch (err) {
      console.error('Error fetching study materials:', err);
      setSnackbar({ open: true, message: 'Failed to fetch study materials. Please try again.', severity: 'error' });
    }
  };

  const handleFileChange = (event) => {
    setNewMaterials(prev => ({ ...prev, files: [...prev.files, ...event.target.files] }));
  };

  const handleAttendanceReview = (attendance) => {
    setSnackbar({ 
      open: true, 
      message: 'Attendance review requested successfully', 
      severity: 'success' 
    });
    fetchTrainerData(); // Refresh data after review request
  };
  
  const handleAddMaterial = async () => {
    try {
      const formData = new FormData();
      formData.append('topic', newMaterials.topic);
      newMaterials.files.forEach((file, index) => {
        formData.append(`uploaded_files[${index}]`, file);
        
        // Limit file type length to 50 characters
        const fileType = file.type.split('/')[1].substring(0, 50);
        formData.append(`file_types[${index}]`, fileType);
      });
  
      if (selectedStudentForMaterial.course) {
        if (selectedStudentForMaterial.course.is_group_class) {
          formData.append('course', selectedStudentForMaterial.course.id);
        } else {
          formData.append('student_course', selectedStudentForMaterial.id);
        }
      } else {
        formData.append('course', selectedStudentForMaterial.id);
      }
  
      await createStudyMaterial(formData);
      setSnackbar({ open: true, message: 'Study materials added successfully', severity: 'success' });
  
      let updatedMaterials;
      if (selectedStudentForMaterial.course) {
        if (selectedStudentForMaterial.course.is_group_class) {
          updatedMaterials = await getStudyMaterialsForCourse(selectedStudentForMaterial.course.id);
        } else {
          updatedMaterials = await getStudyMaterialsForStudent(selectedStudentForMaterial.student);
        }
      } else {
        updatedMaterials = await getStudyMaterialsForCourse(selectedStudentForMaterial.id);
      }
  
      setStudyMaterials(updatedMaterials.data);
      setNewMaterials({ topic: '', files: [] });
    } catch (err) {
      console.error('Error adding study materials:', err);
      setSnackbar({ open: true, message: 'Failed to add study materials. Please try again.', severity: 'error' });
    }
  };
  

  const handleStudentClick = (studentCourse) => {
    setSelectedStudentDetails(studentCourse.studentData);
    setStudentDetailsModalOpen(true);
  };

  const handleSendMessage = async () => {
    try {
      await sendMessage(newMessage);
      setMessageModalOpen(false);
      setNewMessage({ recipient: '', subject: '', body: '' });
      fetchTrainerData();
      setSnackbar({ open: true, message: 'Message sent successfully', severity: 'success' });
    } catch (error) {
      console.error('Error sending message:', error);
      setSnackbar({ open: true, message: 'Failed to send message. Please try again.', severity: 'error' });
    }
  };

  const handleOpenMessage = async (message) => {
    try {
      const fullMessage = await getMessage(message.id);
      setSelectedMessage(fullMessage.data);
      if (!message.read_at) {
        await markMessageAsRead(message.id);
        fetchTrainerData();
      }
    } catch (error) {
      console.error('Error opening message:', error);
      setSnackbar({ open: true, message: 'Failed to open message. Please try again.', severity: 'error' });
    }
  };

  const handleCloseMessage = () => {
    setSelectedMessage(null);
  };

  const handleChangeTab = (event, newValue) => {
    setMessageTab(newValue);
  };

  const handleImageError = () => {
    console.error("Failed to load image:", trainerData.profile?.profile_image);
    setImageError(true);
  };

  const handleNoticeClick = (notice) => {
    setSelectedNotice(notice);
  };

  const handleCloseNotice = () => {
    setSelectedNotice(null);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <GlassContainer maxWidth="sm">
        <Alert severity="error">{error}</Alert>
      </GlassContainer>
    );
  }

  return (
    <>
      
      <GlassContainer  sx={{ paddingBottom: '400px'}}>
        <Typography variant="h4" gutterBottom color="white" align="left">My Dashboard</Typography>
        <Grid container spacing={3}>
          {/* Trainer Profile */}
          {/* Trainer Profile */}
<Grid item xs={12} md={6}>
  <GlassCard>
    <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
      <GlowingAvatar>
        {!imageError && fullImageUrl ? (
          <AvatarImage
            src={fullImageUrl}
            alt={`${trainerData.profile?.first_name} ${trainerData.profile?.last_name}`}
            onError={handleImageError}
          />
        ) : (
          <AvatarFallback variant="h4">
            {`${trainerData.profile?.first_name?.[0]}${trainerData.profile?.last_name?.[0]}`}
          </AvatarFallback>
        )}
      </GlowingAvatar>
      <Typography variant="h5" mt={2}>{`${trainerData.profile?.first_name} ${trainerData.profile?.last_name}`}</Typography>
      <Typography variant="body1">{trainerData.profile?.email}</Typography>
    </Box>
    <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="body2">Role: {trainerData.profile?.role}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">Phone: {trainerData.profile?.phone}</Typography>
      </Grid>
    </Grid>
    <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="body2">City: {trainerData.profile?.city}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">
          DOB: {trainerData.profile?.date_of_birth ? dayjs(trainerData.profile.date_of_birth).format('D-MMM-YYYY') : 'N/A'}
        </Typography>
      </Grid>
    </Grid>
    <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
    <Typography variant="body2" mb={2}>About: {trainerData.profile?.about_me}</Typography>
    <Box display="flex" justifyContent="center" mt={2}>
      <Button
        onClick={handleEditProfile}
        sx={{
          background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
          color: 'white',
          borderRadius: '20px',
          padding: '8px 24px',
          '&:hover': {
            background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
            boxShadow: '0 4px 10px rgba(255, 27, 107, 0.5)',
          },
        }}
        startIcon={<EditIcon />}
      >
        Edit Profile
      </Button>
    </Box>
  </GlassCard>
</Grid>

          {/* Summary and Notices */}
          <Grid item xs={12} md={6}>
            <GlassCard>
            <Typography variant="h6" gutterBottom>Summary & Notices</Typography>
              <Typography variant="body1">
                Total Students: {trainerData.associatedStudents.length}
              </Typography>
              <Typography variant="body1">
                Occupied Hours: {occupation?.occupied_hours ?? 'N/A'}
              </Typography>
              
              {/* Google Meet Section */}
              <Box sx={{ mt: 2, mb: 2 }}>
                <GradientButton
                  startIcon={<GoogleIcon />}
                  onClick={() => window.open(trainerData.profile?.google_meet_link, '_blank')}
                  disabled={!trainerData.profile?.google_meet_link}
                  fullWidth
                  sx={{ mb: 1 }}
                >
                  Join Google Meet
                </GradientButton>
                {trainerData.profile?.google_meet_link && (
                  <Chip
                    label={trainerData.profile.google_meet_link}
                    sx={{
                      width: '100%',
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      '& .MuiChip-label': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }
                    }}
                  />
                )}
              </Box>

              <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
              <NoticeCard notices={notices} />
              
            </GlassCard>
            
          </Grid>

          

          {/* Associated Students */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>My Students</Typography>
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Class Time</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((studentCourse) => (
                <TableRow key={studentCourse.id}>
                  <TableCell>
  <Button
    variant="text"
    onClick={() => navigate(`/student-details/${studentCourse.student}`)}
    sx={{ 
      color: 'white', 
      textTransform: 'none',
      '&:hover': {
        color: '#FF930F',
        background: 'rgba(255, 255, 255, 0.05)'
      }
    }}
  >
    {studentCourse.studentData?.full_name || `Student ID: ${studentCourse.student}`}
  </Button>
</TableCell>
                  <TableCell>{studentCourse.course.name}</TableCell>
                  <TableCell>{dayjs(studentCourse.start_date).format('MMM D, YYYY')}</TableCell>
                  <TableCell>{dayjs(studentCourse.end_date).format('MMM D, YYYY')}</TableCell>
                  <TableCell>{dayjs(studentCourse.class_time, 'HH:mm:ss').format('hh:mm A')}</TableCell>
                  <TableCell>
  <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
    <Tooltip title="View Vocabulary Progress">
      <CompactButton
        variant="contained"
        onClick={() => handleViewVocabProgress(studentCourse.student)}
        sx={{
          background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
          '&:hover': {
            background: 'linear-gradient(45deg, #21CBF3, #2196F3)',
          }
        }}
      >
        <FontDownloadIcon fontSize="small" />
      </CompactButton>
    </Tooltip>
    
    {/* Your existing buttons */}
    <Tooltip title="View Attendance">
      <CompactButton
        variant="contained"
        color="primary"
        onClick={() => handleViewAttendance(studentCourse.student)}
      >
        <CalendarMonthIcon fontSize="small" />
      </CompactButton>
    </Tooltip>
                      <Tooltip title="Assign Materials">
                        <CompactButton
                          variant="contained"
                          color="secondary"
                          onClick={() => handleOpenMaterialModal(studentCourse)}
                        >
                          <MenuBookIcon fontSize="small" />
                        </CompactButton>
                      </Tooltip>
                      <Tooltip title="Join Room">
                        <span>
                          <CompactButton
                            variant="contained"
                            color="success"
                            onClick={() => handleJoinClass(studentCourse.bbb_join_url)}
                            disabled={!studentCourse.bbb_join_url}
                          >
                            <VideocamIcon fontSize="small" />
                          </CompactButton>
                         
                        </span>
                      </Tooltip>
                      <Tooltip title="Manage Assignments">
  <CompactButton
    variant="contained"
    onClick={() => setAssignmentModalOpen(studentCourse)}
    sx={{
      background: 'linear-gradient(45deg, #4CAF50, #81C784)',
      '&:hover': {
        background: 'linear-gradient(45deg, #81C784, #4CAF50)',
      }
    }}
  >
    <AssignmentIcon fontSize="small" />
  </CompactButton>
</Tooltip>
                      <StudentNotification student={studentCourse.student} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Grid>

          {/* Group Courses Table */}
          <Grid item xs={12}>
            <GlassCard>
              <Typography variant="h6" gutterBottom>Group Courses</Typography>
              <StyledTableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Course Name</TableCell>
                      <TableCell>Class Time</TableCell>
                      <TableCell>Assigned Time</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trainerData.groupCourses.map((course) => (
                      <TableRow key={course.id}><TableCell>{course.name}</TableCell>
                      <TableCell>{course.class_time}</TableCell>
                      <TableCell>
                        {course.trainer_assignments.map((assignment, index) => (
                          <div key={index}>{`${assignment.start_time} - ${assignment.end_time}`}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        <GradientButton
                          onClick={() => handleJoinClass(course.bbb_join_url)}
                          disabled={!course.bbb_join_url}
                        >
                          Join Room
                        </GradientButton>
                        <GradientButton 
                          onClick={() => handleOpenMaterialModal(course)}
                          style={{ marginLeft: '10px' }}
                        >
                          Material
                        </GradientButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </GlassCard>
        </Grid>
        

        {/* Attendance Reviews Section */}
        <Grid item xs={12} md={6}>
          <GlassCard>
            <Typography variant="h6" gutterBottom>Attendance Reviews</Typography>
            <ScrollableList>
              {attendanceReviews.slice(0, 3).map((review) => (
                <ListItem key={review.id}>
                  <ListItemText
                    primary={`Review for ${review.attendance.student} on ${dayjs(review.created_at).format('MMMM D, YYYY')}`}
                    secondary={review.remark}
                    secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)' }}
                  />
                  <GradientChip label={review.status} color={review.status === 'PENDING' ? 'warning' : 'success'} />
                </ListItem>
              ))}
            </ScrollableList>
          </GlassCard>
        </Grid>

        {/* Attendance Review History Section */}
        <Grid item xs={12} md={6}>
          <GlassCard>
            <Typography variant="h6" gutterBottom>Review History</Typography>
            <ScrollableList>
              {attendanceReviewHistory.slice(0, 3).map((review) => (
                <ListItem key={review.id}>
                  <ListItemText
                    primary={`Review for attendance on ${dayjs(review.created_at).format('MMMM D, YYYY')}`}
                    secondary={`Status: ${review.status}, Processed on: ${dayjs(review.processed_at).format('MMMM D, YYYY')}`}
                    secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)' }}
                  />
                </ListItem>
              ))}
            </ScrollableList>
          </GlassCard>
        </Grid>
      </Grid>
    </GlassContainer>

    {/* Modals */}
    
    {/* Attendance Calendar Modal */}
    <GlassModal
          open={attendanceModalOpen}
          onClose={() => setAttendanceModalOpen(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            Attendance for {selectedStudent?.full_name}
          </DialogTitle>
          <DialogContent>
          <AttendanceManagement
  studentId={selectedStudent}
  trainerId={trainerData.profile?.id}
  userRole="TRAINER"
  onRequestReview={handleAttendanceReview}  // Add this line
/>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setAttendanceModalOpen(false)}>Close</GradientButton>
          </DialogActions>
        </GlassModal>

    {/* Edit Profile Modal */}
    <GlassModal open={editProfileModalOpen} onClose={() => setEditProfileModalOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="First Name"
          name="first_name"
          value={editedProfile.first_name || ''}
          onChange={handleProfileInputChange}
          margin="normal"
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />
        <TextField
          fullWidth
          label="Last Name"
          name="last_name"
          value={editedProfile.last_name || ''}
          onChange={handleProfileInputChange}
          margin="normal"
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={editedProfile.email || ''}
          onChange={handleProfileInputChange}
          margin="normal"
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />
        <TextField
          fullWidth
          label="Phone"
          name="phone"
          value={editedProfile.phone || ''}
          onChange={handleProfileInputChange}
          margin="normal"
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />
        <TextField
          fullWidth
          label="City"
          name="city"
          value={editedProfile.city || ''}
          onChange={handleProfileInputChange}
          margin="normal"
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />
        <TextField
          fullWidth
          label="About Me"
          name="about_me"
          value={editedProfile.about_me || ''}
          onChange={handleProfileInputChange}
          margin="normal"
          multiline
          rows={4}
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />
         <Box sx={{ mt: 2, mb: 2 }}>
      <input
        accept="image/*"
        id="profile-image-upload"
        type="file"
        style={{ display: 'none' }}
        onChange={handleProfileImageChange}
      />
      <label htmlFor="profile-image-upload">
        <Button
          variant="contained"
          component="span"
          sx={{
            background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
            color: 'white',
            '&:hover': {
              background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
            }
          }}
        >
          Upload Profile Image
        </Button>
      </label>
      {newProfileImage && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" sx={{ color: 'white' }}>
            Selected file: {newProfileImage.name}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <img 
              src={URL.createObjectURL(newProfileImage)} 
              alt="Preview" 
              style={{ 
                maxWidth: '200px', 
                maxHeight: '200px',
                borderRadius: '8px'
              }} 
            />
          </Box>
        </Box>
      )}
    </Box>
  </DialogContent>

      <DialogActions>
        <GradientButton onClick={() => setEditProfileModalOpen(false)}>Cancel</GradientButton>
        <GradientButton onClick={handleSaveProfile}>
          Save Changes
        </GradientButton>
      </DialogActions>
    </GlassModal>

    {/* Study Material Modal */}
    <GlassModal
      open={materialModalOpen}
      onClose={() => setMaterialModalOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Study Materials for {selectedStudentForMaterial?.course
          ? (selectedStudentForMaterial.course.is_group_class 
            ? `${selectedStudentForMaterial.course.name} (Group Course)` 
            : selectedStudentForMaterial.studentDetails?.full_name)
          : `${selectedStudentForMaterial?.name} (Group Course)`}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>Assigned Materials</Typography>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Topic</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>File</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studyMaterials.map((material) => (
                <TableRow key={material.id}>
                  <TableCell>{material.topic}</TableCell>
                  <TableCell>{dayjs(material.created_at).format('MMMM D, YYYY HH:mm')}</TableCell>
                  <TableCell>
                    {material.files.map((file, index) => (
                      <GradientButton key={index} href={file.file} target="_blank" rel="noopener noreferrer">
                        View File {index + 1}
                      </GradientButton>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>Add New Material</Typography>
        <TextField
          fullWidth
          label="Topic"
          value={newMaterials.topic}
          onChange={(e) => setNewMaterials({ ...newMaterials, topic: e.target.value })}
          margin="normal"
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />
        <input
          accept="*/*"
          style={{ display: 'none' }}
          id="material-files"
          type="file"
          multiple
          onChange={handleFileChange}
        />
        <label htmlFor="material-files">
          <GradientButton component="span">
            Upload Files
          </GradientButton>
        </label>
        {newMaterials.files.map((file, index) => (
          <Typography key={index} variant="body2">{file.name}</Typography>
        ))}
        <GradientButton 
          onClick={handleAddMaterial}
          style={{ marginTop: '10px' }}
          disabled={!newMaterials.topic || newMaterials.files.length === 0}
        >
          Add Material
        </GradientButton>
      </DialogContent>
      <DialogActions>
        <GradientButton onClick={() => setMaterialModalOpen(false)}>Close</GradientButton>
      </DialogActions>
    </GlassModal>

    <GlassModal 
        open={studentDetailsModalOpen} 
        onClose={() => setStudentDetailsModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Student Details</DialogTitle>
        <DialogContent>
          {selectedStudentDetails && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <StudentAvatar 
                src={selectedStudentDetails.profile_image} 
                alt={selectedStudentDetails.full_name}
                onError={(e) => {
                  console.error("Failed to load student image:", selectedStudentDetails.profile_image);
                  e.target.src = "path/to/fallback/image.jpg"; // Replace with a path to a default image
                }}
              />
              <Typography variant="h6">{selectedStudentDetails.full_name}</Typography>
              <Typography>Email: {selectedStudentDetails.email}</Typography>
              <Typography>Phone: {selectedStudentDetails.phone}</Typography>
              <Typography>Date of Birth: {selectedStudentDetails.date_of_birth ? new Date(selectedStudentDetails.date_of_birth).toLocaleDateString() : 'N/A'}</Typography>
              <Typography>City: {selectedStudentDetails.city || 'N/A'}</Typography>
              <Typography>About: {selectedStudentDetails.about_me || 'N/A'}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={() => setStudentDetailsModalOpen(false)}>
            Close
          </GradientButton>
        </DialogActions>
      </GlassModal>

    {/* Notice Modal */}
    <GlassModal open={!!selectedNotice} onClose={handleCloseNotice} maxWidth="sm" fullWidth>
      <DialogTitle>{selectedNotice?.title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{selectedNotice?.content}</Typography>
        {selectedNotice?.attachments && selectedNotice.attachments.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1">Attachments:</Typography>
            {selectedNotice.attachments.map((attachment, index) => (
              <GradientChip
                key={index}
                label={attachment.file_name}
                onClick={() => window.open(attachment.file, '_blank')}
                style={{ margin: '4px' }}
              />
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <GradientButton onClick={handleCloseNotice}>
          Close
        </GradientButton>
      </DialogActions>
    </GlassModal>

    {/* Message Modal */}
    <GlassModal open={messageModalOpen} onClose={() => setMessageModalOpen(false)} fullWidth maxWidth="md">
      <DialogTitle>Messages</DialogTitle>
      <DialogContent>
        <Tabs value={messageTab} onChange={handleChangeTab} textColor="inherit" indicatorColor="secondary">
          <Tab label="Inbox" />
          <Tab label="Sent" />
          <Tab label="New Message" />
        </Tabs>
        {messageTab === 0 && (
          <List>
            {messages.inbox?.map((message) => (
              <ListItem key={message.id} button onClick={() => handleOpenMessage(message)}>
                <ListItemAvatar>
                  <GlowingAvatar>{message.sender.full_name[0]}</GlowingAvatar>
                </ListItemAvatar>
                <ListItemText 
                  primary={message.subject} 
                  secondary={message.body.substring(0, 50) + '...'}
                  secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)' }}
                />
                {!message.read_at && <GradientChip label="Unread" size="small" />}
              </ListItem>
            ))}
          </List>
        )}
        {messageTab === 1 && (
          <List>
            {messages.sent?.map((message) => (
              <ListItem key={message.id} button onClick={() => handleOpenMessage(message)}>
                <ListItemAvatar>
                  <GlowingAvatar>{message.recipient.full_name[0]}</GlowingAvatar>
                </ListItemAvatar>
                <ListItemText 
                  primary={message.subject} 
                  secondary={message.body.substring(0, 50) + '...'}
                  secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)' }}
                />
              </ListItem>
            ))}
          </List>
        )}
        {messageTab === 2 && (
          <Box mt={2}>
            <FormControl fullWidth>
              <InputLabel style={{ color: 'white' }}>Recipient</InputLabel>
              <Select
                value={newMessage.recipient}
                onChange={(e) => setNewMessage({ ...newMessage, recipient: e.target.value })}
                style={{ color: 'white' }}
              >
                {adminManagerList.map((user) => (
                  <MenuItem key={user.id} value={user.id}>{user.full_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Subject"
              value={newMessage.subject}
              onChange={(e) => setNewMessage({ ...newMessage, subject: e.target.value })}
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
            />
            <TextField
              fullWidth
              label="Message"
              value={newMessage.body}
              onChange={(e) => setNewMessage({ ...newMessage, body: e.target.value })}
              margin="normal"
              multiline
              rows={4}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
              />
              <GradientButton onClick={handleSendMessage}>
                Send
              </GradientButton>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={() => setMessageModalOpen(false)}>
            Close
          </GradientButton>
        </DialogActions>
      </GlassModal>

      {/* Selected Message Dialog */}
      <GlassModal open={!!selectedMessage} onClose={handleCloseMessage} fullWidth maxWidth="sm">
        <DialogTitle>{selectedMessage?.subject}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
            From: {selectedMessage?.sender.full_name}
          </Typography>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
            To: {selectedMessage?.recipient.full_name}
          </Typography>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
            Date: {new Date(selectedMessage?.created_at).toLocaleString()}
          </Typography>
          <Typography variant="body1" style={{ marginTop: '16px' }}>
            {selectedMessage?.body}
          </Typography>
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={handleCloseMessage}>
            Close
          </GradientButton>
        </DialogActions>
      </GlassModal>
      <GlassModal
  open={vocabModalOpen}
  onClose={() => setVocabModalOpen(false)}
  fullWidth
  maxWidth="lg"
>
  <DialogTitle>
    Vocabulary Progress
    {vocabData.loading && (
      <CircularProgress 
        size={20} 
        sx={{ ml: 2, color: 'white' }} 
      />
    )}
  </DialogTitle>
  <DialogContent>
    {vocabData.error ? (
      <Alert severity="error">{vocabData.error}</Alert>
    ) : (
      <>
        {/* Progress Stats */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={4}>
            <GlassCard>
              <Typography variant="h6" color="white" gutterBottom>
                Completion Rate
              </Typography>
              <Typography variant="h4" color="white">
                {vocabData.stats?.completion_percentage?.toFixed(1)}%
              </Typography>
            </GlassCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <GlassCard>
              <Typography variant="h6" color="white" gutterBottom>
                Words Learned
              </Typography>
              <Typography variant="h4" color="white">
                {vocabData.stats?.total_words_learned || 0}
              </Typography>
            </GlassCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <GlassCard>
              <Typography variant="h6" color="white" gutterBottom>
                Current Streak
              </Typography>
              <Typography variant="h4" color="white">
                {vocabData.stats?.current_streak || 0} days
              </Typography>
            </GlassCard>
          </Grid>
        </Grid>

        {/* Current Block */}
        {vocabData.currentBlock && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" color="white" gutterBottom>
              Current Block
            </Typography>
            <Grid container spacing={2}>
              {vocabData.currentBlock.block.words.map((word) => (
                <Grid item xs={12} sm={6} md={4} key={word.id}>
                  <GlassCard>
                    <Typography variant="h6" color="white">
                      {word.word}
                    </Typography>
                    <Chip 
                      label={word.part_of_speech} 
                      size="small" 
                      sx={{ mb: 1, bgcolor: 'rgba(255,255,255,0.1)', color: 'white' }}
                    />
                    <Typography color="rgba(255,255,255,0.7)" gutterBottom>
                      {word.hindi_definition}
                    </Typography>
                    <Typography color="rgba(255,255,255,0.7)">
                      {word.english_definition}
                    </Typography>
                  </GlassCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Completed Blocks */}
        <Typography variant="h6" color="white" gutterBottom>
          Completed Blocks
        </Typography>
        {vocabData.completedBlocks.map((block) => (
          <Accordion 
            key={block.id}
            sx={{
              bgcolor: 'rgba(255,255,255,0.05)',
              color: 'white',
              mb: 2
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
              <Typography>
                Block #{block.order} - Completed {dayjs(block.completed_at).format('MMMM D, YYYY')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {block.words.map((word) => (
                  <Grid item xs={12} sm={6} md={4} key={word.id}>
                   <GlassCard>
                      <Typography variant="h6" color="white">
                        {word.word}
                      </Typography>
                      <Chip 
                        label={word.part_of_speech} 
                        size="small" 
                        sx={{ mb: 1, bgcolor: 'rgba(255,255,255,0.1)', color: 'white' }}
                      />
                      <Typography color="rgba(255,255,255,0.7)" gutterBottom>
                        {word.hindi_definition}
                      </Typography>
                      <Typography color="rgba(255,255,255,0.7)">
                        {word.english_definition}
                      </Typography>
                      {/* Examples */}
                      <Box mt={2}>
                        <Typography color="rgba(255,255,255,0.9)" variant="subtitle2">
                          Examples:
                        </Typography>
                        <Typography color="rgba(255,255,255,0.7)" variant="body2">
                          1. {word.example1}
                        </Typography>
                        <Typography color="rgba(255,255,255,0.7)" variant="body2">
                          2. {word.example2}
                        </Typography>
                        <Typography color="rgba(255,255,255,0.7)" variant="body2">
                          3. {word.example3}
                        </Typography>
                      </Box>
                      {/* Media Links */}
                      {(word.image_url || word.video_url) && (
                        <Box mt={2} display="flex" gap={1}>
                          {word.image_url && (
                            <Tooltip title="View Image">
                              <IconButton 
                                size="small" 
                                onClick={() => window.open(word.image_url, '_blank')}
                                sx={{ color: 'white' }}
                              >
                                <ImageIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {word.video_url && (
                            <Tooltip title="View Video">
                              <IconButton 
                                size="small" 
                                onClick={() => window.open(word.video_url, '_blank')}
                                sx={{ color: 'white' }}
                              >
                                <VideocamIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      )}
                    </GlassCard>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    )}
  </DialogContent>
  <DialogActions>
    <GradientButton onClick={() => setVocabModalOpen(false)}>
      Close
    </GradientButton>
  </DialogActions>
</GlassModal>{assignmentModalOpen && (
  <AssignmentManagement
    studentCourse={assignmentModalOpen}
    onClose={() => setAssignmentModalOpen(null)}
  />
)}


      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ 
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          '& .MuiAlert-icon': {
            color: 'white'
          }
        }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {/* Message Popup */}
      <MessagePopupHandler user={trainerData.profile} />
    </>
  );
};

export default TrainerDashboard;