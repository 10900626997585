import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  useTheme,
  useMediaQuery,
  Fade,
  Zoom
} from '@mui/material';
import {
  Close as CloseIcon,
  ArrowForward as ArrowRightIcon,
  ArrowBack as ArrowLeftIcon,
  CheckCircle as CheckCircleIcon,
  LightbulbOutlined as TipIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledSpotlight = styled('div')({
  position: 'absolute',
  borderRadius: '8px',
  boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.75)',
  transition: 'all 0.3s ease-in-out',
  zIndex: 1301,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-4px',
    left: '-4px',
    right: '-4px',
    bottom: '-4px',
    border: '2px solid #3b82f6',
    borderRadius: '12px',
    animation: 'pulse 2s infinite'
  }
});

const TooltipCard = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  width: '400px',
  padding: theme.spacing(3),
  background: 'rgba(30, 41, 59, 0.98)',
  backdropFilter: 'blur(8px)',
  border: '1px solid rgba(59, 130, 246, 0.2)',
  borderRadius: '12px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.24)',
  zIndex: 1302
}));

const tutorialSteps = [
  {
    target: '.story-title-input',
    title: 'Story Title',
    content: 'Start by giving your story a compelling title. This will be the first thing readers see.',
    tip: 'Keep your title concise but descriptive - it should give readers a good idea of what your story is about.',
    position: 'bottom'
  },
  {
    target: '.ql-toolbar',
    title: 'Formatting Tools',
    content: 'Use these tools to format your story. You can create headings, add bold or italic text, create lists, and more.',
    tip: 'Try using headers to organize longer stories into sections for better readability.',
    position: 'bottom'
  },
  {
    target: '.ql-editor',
    title: 'Story Editor',
    content: 'Write your story here. The editor supports rich text formatting and automatically saves your progress.',
    tip: 'Click anywhere to start writing. You can paste text directly from other sources while maintaining basic formatting.',
    position: 'right'
  },
  {
    target: '.save-draft-button',
    title: 'Save as Draft',
    content: 'Save your work as a draft to keep editing later. This also enables AI suggestions!',
    tip: 'Save frequently to avoid losing your work. After saving, you\'ll get access to AI-powered writing suggestions.',
    position: 'bottom'
  },
  {
    target: '.ai-suggestions-panel',
    title: 'AI Writing Assistant',
    content: 'Once you save your draft, this panel will provide AI-powered suggestions to improve your story.',
    tip: 'The AI can help with vocabulary, plot development, and writing style. Remember to save your draft first!',
    position: 'left'
  },
  {
    target: '.publish-button',
    title: 'Publish Story',
    content: 'When you\'re ready to share your story with others, click here to publish it.',
    tip: 'You can always edit your story after publishing, but consider getting some AI suggestions first!',
    position: 'bottom'
  }
];

const calculateTooltipPosition = (elementPosition, tooltipWidth, tooltipHeight, stepIndex, totalSteps) => {
    const viewport = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    
    const padding = 20;
    const isLastTwoSteps = stepIndex >= totalSteps - 2;
    
    // Special positioning for last two tooltips
    if (isLastTwoSteps) {
      let position = {
        top: elementPosition.top + 500, // 500px down
        left: elementPosition.left - 200  // 200px to left
      };
      
      // Boundary checks for special cases
      if (position.left + tooltipWidth > viewport.width - padding) {
        position.left = viewport.width - tooltipWidth - padding;
      }
      if (position.left < padding) {
        position.left = padding;
      }
      if (position.top + tooltipHeight > viewport.height - padding) {
        position.top = viewport.height - tooltipHeight - padding;
      }
      
      return position;
    }
    
    // Default positioning for other tooltips
    let position = {
      top: elementPosition.top + elementPosition.height + padding,
      left: elementPosition.left + (elementPosition.width / 2) - (tooltipWidth / 2)
    };
    
    // Regular boundary checks
    if (position.top + tooltipHeight > viewport.height - padding) {
      position.top = elementPosition.top - tooltipHeight - padding;
    }
    if (position.left + tooltipWidth > viewport.width - padding) {
      position.left = viewport.width - tooltipWidth - padding;
    }
    if (position.left < padding) {
      position.left = padding;
    }
    
    return position;
  };
  
  const StoryEditorTutorial = ({ onClose }) => {
    const [step, setStep] = useState(0);
    const [elementPosition, setElementPosition] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const TOOLTIP_WIDTH = 400;
    const TOOLTIP_HEIGHT = 250;
  
    useEffect(() => {
      const updatePositions = () => {
        const element = document.querySelector(tutorialSteps[step].target);
        if (element) {
          const rect = element.getBoundingClientRect();
          const newElementPosition = {
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height
          };
          
          setElementPosition(newElementPosition);
          
          // Pass step index and total steps count to calculate position
          const newTooltipPosition = calculateTooltipPosition(
            newElementPosition,
            TOOLTIP_WIDTH,
            TOOLTIP_HEIGHT,
            step,
            tutorialSteps.length
          );
          setTooltipPosition(newTooltipPosition);
        }
      };
  
      updatePositions();
      window.addEventListener('resize', updatePositions);
      window.addEventListener('scroll', updatePositions);
      
      return () => {
        window.removeEventListener('resize', updatePositions);
        window.removeEventListener('scroll', updatePositions);
      };
    }, [step]);
  
    const handleNext = () => {
      if (step < tutorialSteps.length - 1) {
        setStep(step + 1);
      } else {
        localStorage.setItem('storyEditorTutorialComplete', 'true');
        onClose();
      }
    };
  
    const handlePrev = () => {
      if (step > 0) {
        setStep(step - 1);
      }
    };
  
    if (!elementPosition || !tooltipPosition) return null;
  
    return (
      <Box>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1300
          }}
        />
  
        <Zoom in={true}>
          <StyledSpotlight
            style={{
              top: elementPosition.top -50,
              left: elementPosition.left -100,
              width: elementPosition.width,
              height: elementPosition.height
            }}
          />
        </Zoom>
  
        <Fade in={true}>
          <TooltipCard
            style={{
              top: tooltipPosition.top,
              left: tooltipPosition.left,
              width: TOOLTIP_WIDTH
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: -12,
                  top: -12,
                  color: 'white'
                }}
              >
                <CloseIcon />
              </IconButton>
  
              <Typography variant="h6" gutterBottom sx={{ color: '#3b82f6' }}>
                {tutorialSteps[step].title}
              </Typography>
  
              <Typography variant="body1" sx={{ color: 'white', mb: 2 }}>
                {tutorialSteps[step].content}
              </Typography>
  
              <Box sx={{ display: 'flex', gap: 1, mt: 1, p: 2, bgcolor: 'rgba(59, 130, 246, 0.1)', borderRadius: 1 }}>
                <TipIcon sx={{ color: '#3b82f6' }} />
                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {tutorialSteps[step].tip}
                </Typography>
              </Box>
  
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button
                  onClick={handlePrev}
                  disabled={step === 0}
                  startIcon={<ArrowLeftIcon />}
                  sx={{ color: 'white' }}
                >
                  Previous
                </Button>
  
                <Button
                  onClick={handleNext}
                  variant="contained"
                  endIcon={step === tutorialSteps.length - 1 ? <CheckCircleIcon /> : <ArrowRightIcon />}
                  sx={{ bgcolor: '#3b82f6' }}
                >
                  {step === tutorialSteps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </Box>
          </TooltipCard>
        </Fade>
      </Box>
    );
  };
  
  export default StoryEditorTutorial;